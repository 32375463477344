import {React, ui} from 'lib';

import {useRemoteData, useWriterApi, Req, Res, Input, PasswordInput, RadioInput, CheckboxInput, FormItem, useForm, ApiSpinnerDialog, ApiCompletionDialog, ApiErrorDialog, InputProps} from 'shared';
import {Store} from 'types/elcstockbook/api/data.gen';

const listPath = 'admin/user/list';
const createPath = 'admin/user/create';
const updatePath = 'admin/user/update';

type User = ArrayItem<Res<typeof listPath>>;
type Role = User['role'];

export const UserIndex = (): React.ReactElement => {
    return <>
        <ui.Heading fontSize="lg" mb={10}>
            ユーザー管理
        </ui.Heading>

        <List />
    </>;
}

const List = () => {
    const {data} = useRemoteData({
        path: 'admin/get_data',
        request: {},
    });

    const {data: users, reload} = useRemoteData({
        path: listPath,
        request: {},
    });
    const [editing, setEditing] = React.useState<User>();
    const createDisclosure = ui.useDisclosure();
    const updateDisclosure = ui.useDisclosure({
        isOpen: editing !== undefined,
        onClose: () => setEditing(undefined),
    });

    if (!data || !users) {
        return <ui.Spinner />;
    }

    return <ui.Box>
        <ui.Button onClick={createDisclosure.onOpen}>
            Add
        </ui.Button>

        <ui.Table mt={10}>
            <ui.Thead>
                <ui.Tr>
                    <ui.Th>ID</ui.Th>
                    <ui.Th>Name</ui.Th>
                    <ui.Th>Email</ui.Th>
                    <ui.Th>Role</ui.Th>
                </ui.Tr>
            </ui.Thead>
            <ui.Tbody>
                {users.map((user) => <ui.Tr key={user.id}>
                    <ui.Td>
                        <ui.Link color="blue.500" onClick={() => setEditing(user)}>
                            {user.id}
                        </ui.Link>
                    </ui.Td>
                    <ui.Td>{user.name}</ui.Td>
                    <ui.Td>{user.email}</ui.Td>
                    <ui.Td>{user.role}</ui.Td>
                </ui.Tr>)}
            </ui.Tbody>
        </ui.Table>

        <CreateDialog
            stores={data.stores}
            onComplete={() => {
                createDisclosure.onClose();
                reload();
            }}
            {...createDisclosure}
        />
        <UpdateDialog
            stores={data.stores}
            user={editing}
            onComplete={() => {
                updateDisclosure.onClose();
                reload();
            }}
            {...updateDisclosure}
        />
    </ui.Box>
};



const CreateDialog = ({stores, onComplete, ...props}: {
    stores: Store[];
    onComplete(user: User): void;
} & ui.UseDisclosureReturn) => {
    const api = useWriterApi(createPath);
    const {binder, handleSubmit, reset} = useForm<Req<typeof createPath>, 'role'>(() => ({
        name: '',
        email: '',
        password: '',
        role: undefined,
        stores: [] as number[],
    }));

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, reset]);

    const submit = handleSubmit(data => api.call(data));

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">ユーザーの登録</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <FormItem
                        label="name"
                        keyPath="name"
                        error={api.error?.data}
                    >
                        <Input
                            {...binder.mapInputProps('name')}
                        />
                    </FormItem>

                    <FormItem
                        label="email"
                        keyPath="email"
                        error={api.error?.data}
                    >
                        <Input
                            type="email"
                            {...binder.mapInputProps('email')}
                        />
                    </FormItem>

                    <FormItem
                        label="password"
                        keyPath="password"
                        error={api.error?.data}
                    >
                        <PasswordInput
                            {...binder.mapInputProps('password')}
                        />
                    </FormItem>

                    <FormItem
                        label="role"
                        keyPath="role"
                        error={api.error?.data}
                    >
                        <RadioInput<Role>
                            choices={[
                                {value: 'customer', label: '顧客'},
                                {value: 'staff', label: 'スタッフ'},
                                {value: 'admin', label: '管理者'},
                            ]}
                            {...binder.mapInputProps('role')}
                        />
                    </FormItem>

                    <FormItem
                        label="stores"
                        keyPath="stores"
                        error={api.error?.data}
                    >
                        <CheckboxInput
                            choices={stores.map(s => ({
                                value: s.id,
                                label: s.name,
                            }))}
                            {...binder.mapInputProps('stores')}
                        />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={submit}
                >
                    作成
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiErrorDialog api={api} onOk={api.reset} />
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={(data) => {
                onComplete(data);
                reset();
                api.reset();
            }}
        />
    </ui.Modal>;
};



const UpdateDialog = ({stores, user, onComplete, ...props}: {
    stores: Store[];
    user: User | undefined;
    onComplete(user: User): void;
} & ui.UseDisclosureReturn) => {
    const api = useWriterApi(updatePath);
    const {binder, handleSubmit, reset} = useForm<Req<typeof updatePath>, keyof Req<typeof updatePath>>(() => ({
        id: user?.id,
        name: user?.name,
        email: user?.email,
        password: undefined,
        role: user?.role,
        stores: user?.stores.map(s => s.id),
    }));

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, user, reset]);

    const submit = handleSubmit(data => api.call(data));

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">ユーザーの編集</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <FormItem
                        label="name"
                        keyPath="name"
                        error={api.error?.data}
                    >
                        <Input
                            {...binder.mapInputProps('name')}
                        />
                    </FormItem>

                    <FormItem
                        label="email"
                        keyPath="email"
                        error={api.error?.data}
                    >
                        <Input
                            type="email"
                            {...binder.mapInputProps('email')}
                        />
                    </FormItem>

                    <FormItem
                        label="password"
                        keyPath="password"
                        error={api.error?.data}
                    >
                        <RadioInput
                            value={binder.value.password === undefined ? 'no' : 'yes'}
                            onChange={(_e, value) => {
                                binder.assign({password: value === 'yes' ? '' : undefined});
                            }}
                            choices={[
                                {value: 'no', label: '変更しない'},
                                {value: 'yes', label: '変更する'},
                            ]}
                        />
                        {typeof binder.value.password === 'string' && <PasswordInput
                            {...binder.mapInputProps('password') as InputProps<string, string>}
                        />}
                    </FormItem>

                    <FormItem
                        label="role"
                        keyPath="role"
                        error={api.error?.data}
                    >
                        <RadioInput<Role>
                            choices={[
                                {value: 'customer', label: '顧客'},
                                {value: 'staff', label: 'スタッフ'},
                                {value: 'admin', label: '管理者'},
                            ]}
                            {...binder.mapInputProps('role')}
                        />
                    </FormItem>

                    <FormItem
                        label="stores"
                        keyPath="stores"
                        error={api.error?.data}
                    >
                        <CheckboxInput
                            choices={stores.map(s => ({
                                value: s.id,
                                label: s.name,
                            }))}
                            {...binder.mapInputProps('stores')}
                        />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={submit}
                >
                    更新
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiErrorDialog api={api} onOk={api.reset} />
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={(data) => {
                onComplete(data);
                reset();
                api.reset();
            }}
        />
    </ui.Modal>;
};
