import {React, ui, moment} from 'lib';

import {useRemoteData, Req, useForm, FormItem, LayoutItem, OptionalDateInput, CheckboxInput, Paginator, usePage, useSingleQueryValueAsNumber, ActionType, actionTypeText, actionTypeChoices} from 'shared';

import {Action} from 'types/elcstockbook/api/data.gen';
import {StaffFrame, useStore, StaffSelect} from '../base';
import {ActionDetailDialog} from './detail';

const listPath = 'staff/action/list';


export function ActionHistory(): React.ReactElement {
    const id = useSingleQueryValueAsNumber('id');
    const detailDisclosure = ui.useDisclosure({
        isOpen: id.value !== null,
        onClose: () => id.replace(null),
    });

    const listRef = React.useRef<Ref<typeof List>>(null);

    return <StaffFrame
        title="操作履歴"
    >
        <List ref={listRef} />
        <ActionDetailDialog
            id={id.value}
            onUpdated={(action) => listRef.current?.updateAction(action)}
            {...detailDisclosure}
        />
    </StaffFrame>;
};

const List = React.forwardRef<{
    updateAction(action: Action): void;
}, {}>((_props, ref) => {
    const store = useStore();
    const id = useSingleQueryValueAsNumber('id');
    const staffId = useSingleQueryValueAsNumber('staff');

    const {binder} = useForm<Req<typeof listPath>, 'store_id'>(() => ({
        store_id: undefined,
        types: [],
        date_from: null,
        date_to: null,
    }));

    const {data: list, setData} = useRemoteData({
        path: listPath,
        request: {
            ...binder.value,
            store_id: store.id,
            staff_id: staffId.value,
            page: usePage(),
        },
    });

    React.useImperativeHandle(ref, () => ({
        updateAction: (action: Action) => {
            setData((old) => ({
                ...old,
                items: old.items.map(a => a.id === action.id ? action : a),
            }));
        },
    }));

    return <ui.Box my={10}>
        <FormItem>
            <ui.HStack>
                <OptionalDateInput
                    {...binder.mapInputProps('date_from')}
                    type="date"
                />

                <ui.Text>〜</ui.Text>

                <OptionalDateInput
                    {...binder.mapInputProps('date_to')}
                    type="date"
                />
            </ui.HStack>
        </FormItem>

        <FormItem>
            <StaffSelect
                value={staffId.value}
                onChange={(_, v) => staffId.push(v)}
            />
        </FormItem>

        <FormItem>
            <CheckboxInput<ActionType>
                {...binder.mapInputProps('types')}
                choices={actionTypeChoices}
            />
        </FormItem>

        <LayoutItem>
            {!list && <ui.Spinner />}

            {list && <ui.Table mt={10} size="sm">
                <ui.Thead>
                    <ui.Tr>
                        <ui.Th px={1}>Time/Type</ui.Th>
                        <ui.Th px={1}>Staff</ui.Th>
                        <ui.Th px={1}>Customer/Note</ui.Th>
                    </ui.Tr>
                </ui.Thead>
                <ui.Tbody>
                    {list?.items.map((action) => <ui.Tr key={action.id}>
                        <ui.Td px={1} fontSize="xs">
                            <ui.Button onClick={() => id.push(action.id)} variant="link" colorScheme="blue">
                                {moment(action.created_at).format('YYYY/MM/DD HH:mm')}
                            </ui.Button>
                            <ui.Text fontWeight="bold">
                                {actionTypeText[action.type]}
                            </ui.Text>
                        </ui.Td>
                        <ui.Td px={1} fontSize="xs">
                            {action.staff.name}
                        </ui.Td>
                        <ui.Td px={1} fontSize="xs">
                            <ui.Text fontWeight="bold">
                                {action.customer_name}
                            </ui.Text>
                            {action.note && <ui.Text whiteSpace="pre-line">
                                {action.note}
                            </ui.Text>}
                        </ui.Td>
                    </ui.Tr>)}
                </ui.Tbody>
            </ui.Table>}
        </LayoutItem>

        {list && <LayoutItem>
            <Paginator {...list} />
        </LayoutItem>}
    </ui.Box>
});
