import {React, Router, ui, moment} from 'lib';

import {S3Image, useRemoteData, useWriterApi, Req, Res, ApiErrorDialog, ApiSpinnerDialog, ApiCompletionDialog, LayoutItem, useForm, FormItem, Textarea, RadioInput, CheckboxInput, Paginator, usePage, useSingleQueryValueAsNumber} from 'shared';
import {TransactionDataInput} from 'admin/base';
import {Brand, Store, Action} from 'types/elcstockbook/api/data.gen';


const getData = 'admin/get_data';
const listStocks = 'admin/stock/list';
const listWarehouseStocks = 'admin/stock/list_warehouse';
const listFreeStocks = 'admin/stock/list_free_stocks';
const getRequestedItems = 'admin/stock/get_requested_items';
const importWarehouseStocks = 'admin/stock/import_warehouse_stocks';
const importFreeStocks = 'admin/stock/import_free_stocks';
const importStocks = 'admin/stock/import_stocks';
const orderShipment = 'admin/stock/order_shipment';
const listShipmentOrders = 'admin/stock/list_shipment_orders';
const getShipmentOrder = 'admin/stock/get_shipment_order';


export const StockIndex = (): React.ReactElement => {
    const location = Router.useLocation();
    const history = Router.useHistory();
    const {url} = Router.useRouteMatch();

    const list = `${url}`;
    const warehouseStocks = `${url}/warehouse`;
    const freeStocks = `${url}/free`;
    const orderShipment = `${url}/order_shipment`;
    const importStock = `${url}/import`;
    const importWarehouse = `${url}/import_warehouse`;
    const importFree = `${url}/import_free`;
    const tabs = [list, warehouseStocks, freeStocks, orderShipment, importWarehouse, importFree, importStock];
    const index = tabs.indexOf(location.pathname)

    const {data} = useRemoteData({
        path: getData,
        request: {},
    });

    return <ui.Tabs index={index} onChange={i => history.push(tabs[i])}>
        <ui.TabList>
            <ui.Tab>店舗在庫</ui.Tab>
            <ui.Tab>倉庫在庫</ui.Tab>
            <ui.Tab>フリー在庫</ui.Tab>
            <ui.Tab>出荷指示</ui.Tab>
            <ui.Tab>取置残集計</ui.Tab>
            <ui.Tab>フリー在庫取込</ui.Tab>
            <ui.Tab>店頭在庫インポート</ui.Tab>
        </ui.TabList>

        <ui.TabPanels>
            {!data && <ui.TabPanel>
                <ui.Spinner />
            </ui.TabPanel>}

            {data && tabs.map((_, i) => <ui.TabPanel key={i}>
                {i === index && <Router.Switch>
                    <Router.Route path={importWarehouse}>
                        <ImportWarehouse data={data} />
                    </Router.Route>
                    <Router.Route path={importFree}>
                        <ImportFreeStocks data={data} />
                    </Router.Route>
                    <Router.Route path={freeStocks}>
                        <FreeStocks data={data} />
                    </Router.Route>
                    <Router.Route path={importStock}>
                        <ImportStock />
                    </Router.Route>
                    <Router.Route path={orderShipment}>
                        <OrderShipment data={data} />
                    </Router.Route>
                    <Router.Route path={warehouseStocks}>
                        <WarehouseStocks data={data} />
                    </Router.Route>
                    <Router.Route path={list}>
                        <Stocks data={data} />
                    </Router.Route>
                </Router.Switch>}
            </ui.TabPanel>)}
        </ui.TabPanels>
    </ui.Tabs>;
}

const Stocks = ({data}: {data: Res<typeof getData>}) => {
    const {binder} = useForm<Req<typeof listStocks>>(() => ({
        brand: data.brands[0].value,
        store_id: null,
    }));

    const {data: stocks, loading} = useRemoteData({
        path: listStocks,
        request: {
            page: usePage(),
            ...binder.value,
        },
    });

    return <ui.Box>
        <FormItem>
            <RadioInput
                {...binder.mapInputProps('brand')}
                choices={data.brands.map(b => ({value: b.value, label: b.value}))}
            />
        </FormItem>

        <FormItem>
            <RadioInput
                {...binder.mapInputProps('store_id')}
                choices={[
                    {value: null, label: '店舗指定なし'},
                    ...data.stores
                    .filter(s => s.brand.value === binder.value.brand)
                    .map(s => ({value: s.id, label: s.name})),
                ]}
            />
        </FormItem>

        <LayoutItem>
            <ui.Table>
                <ui.Thead>
                    <ui.Tr>
                        <ui.Th px={1}>店舗</ui.Th>
                        <ui.Th px={1}>Image</ui.Th>
                        <ui.Th px={1}>Item</ui.Th>
                        <ui.Th px={1}>店頭</ui.Th>
                        <ui.Th px={1}>予約</ui.Th>
                        <ui.Th px={1}>倉庫</ui.Th>
                        <ui.Th px={1}>注文</ui.Th>
                        <ui.Th px={1}>リース</ui.Th>
                        <ui.Th px={1}>修理</ui.Th>
                    </ui.Tr>
                </ui.Thead>
                <ui.Tbody>
                    {stocks?.items.map((stock, i) => {
                        const {store, item} = stock;
                        return <ui.Tr key={i}>
                             <ui.Td px={1}>
                                 <ui.Text fontSize="xs">
                                    {store.code}
                                </ui.Text>
                                 <ui.Text fontSize="xs">
                                    {store.name}
                                </ui.Text>
                            </ui.Td>
                            <ui.Td px={1}>
                                {item.product.model.images[0] && <S3Image
                                    base={item.product.model.images[0].base_url}
                                    processor="sd256"
                                    maxWidth="50px"
                                />}
                            </ui.Td>
                            <ui.Td px={1}>
                                <ui.Text fontSize="xs">
                                    {item.barcode}
                                </ui.Text>
                                <ui.Text fontSize="xs">
                                    {item.product.model.code}
                                </ui.Text>
                                {item.product.model.code !== item.product.domestic_code && <ui.Text fontSize="xs">
                                    {item.product.domestic_code}
                                </ui.Text>}
                                <ui.Text fontSize="xs">
                                    {item.product.color} / {item.size}
                                </ui.Text>
                            </ui.Td>
                            <ui.Td px={1} isNumeric={true}>
                                {stock.stock_amount}
                            </ui.Td>
                            <ui.Td px={1} isNumeric={true}>
                                {stock.keep_amount}
                            </ui.Td>
                            <ui.Td px={1} isNumeric={true}>
                                {stock.warehouse_stock_amount + stock.warehouse_requested_amount}
                            </ui.Td>
                            <ui.Td px={1} isNumeric={true}>
                                {stock.order_amount}
                            </ui.Td>
                            <ui.Td px={1} isNumeric={true}>
                                {stock.lease_amount}
                            </ui.Td>
                            <ui.Td px={1} isNumeric={true}>
                                {stock.repair_amount}
                            </ui.Td>
                        </ui.Tr>;
                    })}
                </ui.Tbody>
            </ui.Table>
        </LayoutItem>

        {loading && <LayoutItem>
            <ui.HStack justifyContent="center">
                <ui.Spinner
                    speed="1.0s"
                />
            </ui.HStack>
        </LayoutItem>}

        {stocks && <LayoutItem>
            <Paginator {...stocks} />
        </LayoutItem>}
    </ui.Box>
}

const WarehouseStocks = ({data}: {data: Res<typeof getData>}) => {
    const {binder} = useForm<Req<typeof listWarehouseStocks>>(() => ({
        brand: data.brands[0].value,
        store_id: null,
    }));

    const {data: stocks, loading} = useRemoteData({
        path: listWarehouseStocks,
        request: {
            page: usePage(),
            ...binder.value,
        },
    });

    return <ui.Box>
        <FormItem>
            <RadioInput
                {...binder.mapInputProps('brand')}
                choices={data.brands.map(b => ({value: b.value, label: b.value}))}
            />
        </FormItem>

        <FormItem>
            <RadioInput
                {...binder.mapInputProps('store_id')}
                choices={[
                    {value: null, label: '店舗指定なし'},
                    ...data.stores
                    .filter(s => s.brand.value === binder.value.brand)
                    .map(s => ({value: s.id, label: s.name})),
                ]}
            />
        </FormItem>

        <LayoutItem>
            <ui.Table>
                <ui.Thead>
                    <ui.Tr>
                        <ui.Th px={1}>納品先</ui.Th>
                        <ui.Th px={1}>Image</ui.Th>
                        <ui.Th px={1}>Item</ui.Th>
                        <ui.Th px={1}>倉庫在庫</ui.Th>
                        <ui.Th px={1}>出荷依頼</ui.Th>
                        <ui.Th px={1}>出荷後残</ui.Th>
                    </ui.Tr>
                </ui.Thead>
                <ui.Tbody>
                    {stocks?.items.map((stock, i) => {
                        const {warehouse, item, amount, requested_amount} = stock;
                        return <ui.Tr key={i}>
                             <ui.Td px={1}>
                                 <ui.Text fontSize="xs">
                                    {warehouse.code}
                                </ui.Text>
                                 <ui.Text fontSize="xs">
                                    {warehouse.name}
                                </ui.Text>
                            </ui.Td>
                            <ui.Td px={1}>
                                {item.product.model.images[0] && <S3Image
                                    base={item.product.model.images[0].base_url}
                                    processor="sd256"
                                    maxWidth="50px"
                                />}
                            </ui.Td>
                            <ui.Td px={1}>
                                <ui.Text fontSize="xs">
                                    {item.barcode}
                                </ui.Text>
                                <ui.Text fontSize="xs">
                                    {item.product.model.code}
                                </ui.Text>
                                {item.product.model.code !== item.product.domestic_code && <ui.Text fontSize="xs">
                                    {item.product.domestic_code}
                                </ui.Text>}
                                <ui.Text fontSize="xs">
                                    {item.product.color} / {item.size}
                                </ui.Text>
                            </ui.Td>
                            <ui.Td px={1} isNumeric={true}>
                                {amount + requested_amount}
                            </ui.Td>
                            <ui.Td px={1} isNumeric={true} color="red">
                                {requested_amount}
                            </ui.Td>
                            <ui.Td px={1} isNumeric={true}>
                                {amount}
                            </ui.Td>
                        </ui.Tr>;
                    })}
                </ui.Tbody>
            </ui.Table>
        </LayoutItem>

        {loading && <LayoutItem>
            <ui.HStack justifyContent="center">
                <ui.Spinner
                    speed="1.0s"
                />
            </ui.HStack>
        </LayoutItem>}

        {stocks && <LayoutItem>
            <Paginator {...stocks} />
        </LayoutItem>}
    </ui.Box>
}


const FreeStocks = ({data}: {data: Res<typeof getData>}) => {
    const {binder} = useForm<Req<typeof listFreeStocks>>(() => ({
        brand: data.brands[0].value,
    }));

    const {data: stocks, loading} = useRemoteData({
        path: listFreeStocks,
        request: {
            page: usePage(),
            ...binder.value,
        },
    });

    return <ui.Box>
        <FormItem>
            <RadioInput
                {...binder.mapInputProps('brand')}
                choices={data.brands.map(b => ({value: b.value, label: b.value}))}
            />
        </FormItem>

        <LayoutItem>
            <ui.Table>
                <ui.Thead>
                    <ui.Tr>
                        <ui.Th px={1}>Image</ui.Th>
                        <ui.Th px={1}>Item</ui.Th>
                        <ui.Th px={1}>倉庫在庫</ui.Th>
                        <ui.Th px={1}>出荷依頼</ui.Th>
                        <ui.Th px={1}>出荷後残</ui.Th>
                    </ui.Tr>
                </ui.Thead>
                <ui.Tbody>
                    {stocks?.items.map((item, i) => {
                        const {free_stock_amount: amount, requested_amount} = item;
                        return <ui.Tr key={i}>
                            <ui.Td px={1}>
                                {item.product.model.images[0] && <S3Image
                                    base={item.product.model.images[0].base_url}
                                    processor="sd256"
                                    maxWidth="50px"
                                />}
                            </ui.Td>
                            <ui.Td px={1}>
                                <ui.Text fontSize="xs">
                                    {item.barcode}
                                </ui.Text>
                                <ui.Text fontSize="xs">
                                    {item.product.model.code}
                                </ui.Text>
                                {item.product.model.code !== item.product.domestic_code && <ui.Text fontSize="xs">
                                    {item.product.domestic_code}
                                </ui.Text>}
                                <ui.Text fontSize="xs">
                                    {item.product.color} / {item.size}
                                </ui.Text>
                            </ui.Td>
                            <ui.Td px={1} isNumeric={true}>
                                {amount + requested_amount}
                            </ui.Td>
                            <ui.Td px={1} isNumeric={true} color="red">
                                {requested_amount}
                            </ui.Td>
                            <ui.Td px={1} isNumeric={true}>
                                {amount}
                            </ui.Td>
                        </ui.Tr>;
                    })}
                </ui.Tbody>
            </ui.Table>
        </LayoutItem>

        {loading && <LayoutItem>
            <ui.HStack justifyContent="center">
                <ui.Spinner
                    speed="1.0s"
                />
            </ui.HStack>
        </LayoutItem>}

        {stocks && <LayoutItem>
            <Paginator {...stocks} />
        </LayoutItem>}
    </ui.Box>
}



const OrderShipment = ({data}: {data: Res<typeof getData>}) => {
    const [brand, setBrand] = React.useState(data.brands[0]);

    const {data: items, reload, loading} = useRemoteData({
        path: getRequestedItems,
        request: {
            brand: brand.value,
        },
    });

    const requrestedStoreIds = React.useMemo(() => {
        return items?.reduce((acc, item) => {
            if (!acc.includes(item.store.id)) {
                acc.push(item.store.id);
            }
            return acc;
        }, [] as number[]) ?? [];
    }, [items]);

    const disclosure = ui.useDisclosure();

    const id = useSingleQueryValueAsNumber('id');
    const detailDisclosure = ui.useDisclosure({
        isOpen: id.value !== null,
        onClose: () => id.replace(null),
    });

    const orderListRef = React.useRef<Ref<typeof OrderList>>(null);
    const [showDetail, setShowDetail] = React.useState(false);

    return <ui.Box>
        <LayoutItem>
            <RadioInput
                value={brand}
                onChange={(_, b) => setBrand(b)}
                choices={data.brands.map(b => ({value: b, label: b.value}))}
            />
        </LayoutItem>

        {items && !loading && <>
            <LayoutItem>
                <ui.HStack justifyContent="flex-end">
                    {items.length > 0 && <ui.Text mr={2} fontSize="sm">
                        {items.length}件の出荷依頼があります
                    </ui.Text>}
                    {items.length === 0 && <ui.Text mr={2} fontSize="sm">
                        出荷依頼がありません
                    </ui.Text>}
                    <ui.Button colorScheme="blue" onClick={disclosure.onOpen} isDisabled={items.length === 0}>
                        出荷指示
                    </ui.Button>
                </ui.HStack>
            </LayoutItem>
        </>}

        <LayoutItem>
            <ui.Table>
                <ui.Thead>
                    <ui.Tr>
                        <ui.Th px={1}>店舗</ui.Th>
                        <ui.Th px={1}>納品先</ui.Th>
                        <ui.Th px={1}>Image</ui.Th>
                        <ui.Th px={1}>Item</ui.Th>
                        <ui.Th px={1}>出荷依頼数</ui.Th>
                        <ui.Th px={1}>Note</ui.Th>
                    </ui.Tr>
                </ui.Thead>
                <ui.Tbody>
                    {items?.slice(0, showDetail ? undefined : 5).map((stock, i) => {
                        const {store, warehouse, item, requested_amount, request_note} = stock;
                        return <ui.Tr key={i}>
                             <ui.Td px={1}>
                                 <ui.Text fontSize="xs">
                                    {store.name}
                                </ui.Text>
                            </ui.Td>
                             <ui.Td px={1}>
                                 <ui.Text fontSize="xs">
                                     {warehouse?.code ?? 'フリー在庫'}
                                 </ui.Text>
                            </ui.Td>
                            <ui.Td px={1}>
                                {item.product.model.images[0] && <S3Image
                                    base={item.product.model.images[0].base_url}
                                    processor="sd256"
                                    maxWidth="50px"
                                />}
                            </ui.Td>
                            <ui.Td px={1}>
                                <ui.Text fontSize="xs">
                                    {item.barcode}
                                </ui.Text>
                                <ui.Text fontSize="xs">
                                    {item.product.model.code}
                                </ui.Text>
                                {item.product.model.code !== item.product.domestic_code && <ui.Text fontSize="xs">
                                    {item.product.domestic_code}
                                </ui.Text>}
                                <ui.Text fontSize="xs">
                                    {item.product.color} / {item.size}
                                </ui.Text>
                            </ui.Td>
                            <ui.Td px={1} isNumeric={true} color="red">
                                {requested_amount}
                            </ui.Td>
                            <ui.Td pl={2} pr={0} fontSize="xs" whiteSpace="pre-line">
                                {request_note}
                            </ui.Td>
                        </ui.Tr>;
                    })}
                </ui.Tbody>
            </ui.Table>
        </LayoutItem>

        {!showDetail && (items ?? []).length > 5 && <LayoutItem>
            <ui.Button onClick={() => setShowDetail(true)}>
                出荷依頼を全て表示する
            </ui.Button>
        </LayoutItem>}

        <OrderDialog
            brand={brand}
            stores={data.stores.filter(s => s.brand.value === brand.value && requrestedStoreIds.includes(s.id))}
            onComplete={(order) => {
                reload();
                orderListRef.current?.reload();
                id.push(order.id);
            }}
            {...disclosure}
        />

        <OrderDetailDialog
            id={id.value}
            {...detailDisclosure}
        />

        <LayoutItem>
            <ui.Heading fontSize="lg" mt={10}>
                出荷指示履歴
            </ui.Heading>
        </LayoutItem>

        <LayoutItem>
            <OrderList ref={orderListRef} data={data} />
        </LayoutItem>
    </ui.Box>
};


const OrderDialog = ({brand, stores, onComplete, ...props}: {
    brand: Brand;
    stores: Store[];
    onComplete(action: Res<typeof orderShipment>): void;
} & ui.UseDisclosureReturn) => {
    const api = useWriterApi(orderShipment);
    const {binder, handleSubmit, reset} = useForm<Req<typeof orderShipment>>(() => ({
        brand: brand.value,
        store_ids: stores.map(s => s.id),
        note: '',
    }));

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, reset]);

    const submit = handleSubmit(data => api.call({
        ...data,
    }));

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">出荷指示</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <FormItem label="Brand">
                    <ui.Text>
                        {brand.value}
                    </ui.Text>
                </FormItem>

                <FormItem label="Store">
                    <CheckboxInput
                        {...binder.mapInputProps('store_ids')}
                        choices={stores.map(s => ({value: s.id, label: s.name}))}
                    />
                </FormItem>

                <FormItem label="Note">
                    <Textarea {...binder.mapInputProps('note')} />
                </FormItem>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={submit}
                >
                    確定
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiErrorDialog api={api} onOk={api.reset} />
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={(action) => {
                props.onClose();
                onComplete(action);
                reset();
                api.reset();
            }}
        />
    </ui.Modal>;
};


const OrderList = React.forwardRef<{
    reload(): void;
}, {data: Res<typeof getData>}>(({data}, ref) => {
    const id = useSingleQueryValueAsNumber('id');

    const {binder} = useForm<Req<typeof listShipmentOrders>>(() => ({
        brand: null,
    }));

    const {data: orders, reload, loading} = useRemoteData({
        path: listShipmentOrders,
        request: {
            page: usePage(),
            ...binder.value,
        },
    });

    React.useImperativeHandle(ref, () => ({
        reload: reload,
    }));

    return <ui.Box>
        <FormItem>
            <RadioInput
                {...binder.mapInputProps('brand')}
                choices={[
                    {value: null, label: 'ブランド指定なし'},
                    ...data.brands.map(b => ({value: b.value, label: b.value})),
                ]}
            />
        </FormItem>

        <LayoutItem>
            <ui.Table>
                <ui.Thead>
                    <ui.Tr>
                        <ui.Th>Id</ui.Th>
                        <ui.Th>Time</ui.Th>
                        <ui.Th>Brand</ui.Th>
                    </ui.Tr>
                </ui.Thead>
                <ui.Tbody>
                    {orders?.items.map((order) => <ui.Tr key={order.id}>
                        <ui.Td>
                            <ui.Button onClick={() => id.push(order.id)} variant="link" colorScheme="blue">
                                {order.id}
                            </ui.Button>
                        </ui.Td>
                        <ui.Td fontSize="xs">
                            {moment(order.created_at).format('YYYY/MM/DD HH:mm')}
                        </ui.Td>
                        <ui.Td fontSize="xs">
                            {order.brand.value}
                        </ui.Td>
                    </ui.Tr>)}
                </ui.Tbody>
            </ui.Table>
        </LayoutItem>

        {loading && <LayoutItem>
            <ui.HStack justifyContent="center">
                <ui.Spinner
                    speed="1.0s"
                />
            </ui.HStack>
        </LayoutItem>}

        {orders && <LayoutItem>
            <Paginator {...orders} />
        </LayoutItem>}
    </ui.Box>
});


const OrderDetailDialog = ({id: orderId, ...props}: {
    id: number | null;
} & ui.UseDisclosureReturn) => {
    const {data, loading} = useRemoteData({
        path: getShipmentOrder,
        request: {order_id: orderId ?? 0},
        halt: !orderId,
    });
    const exporter = useShipmentOrderExporter(data);
    const [showDetail, setShowDetail] = React.useState(false);
    const [store, setStore] = React.useState(null as null | Store);
    const actionMap = (data?.actions ?? []).reduce(
        (acc, a) => ({...acc, [a.id]: a}),
        {} as Keyed<Action>);
    const details = data?.details
        .filter(d => !store || actionMap[d.action_id]?.store?.code === store.code) ?? [];

    return <ui.Modal size="4xl" {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">出荷指示詳細</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                {(!orderId || !data) && <ui.Box py={10}>
                    {loading && <ui.Spinner />}
                    {!loading && <ui.Text textAlign="center" color="gray.500" size="lg">
                        not found
                    </ui.Text>}
                </ui.Box>}

                {data && <ui.Box>
                    <LayoutItem>
                        <ui.Heading>
                            操作詳細
                        </ui.Heading>
                    </LayoutItem>

                    <LayoutItem>
                        <ui.Table>
                            <ui.Tbody>
                                <ui.Tr>
                                    <ui.Th>Id</ui.Th>
                                    <ui.Td>
                                        {data.order.id}
                                    </ui.Td>
                                </ui.Tr>

                                <ui.Tr>
                                    <ui.Th>Time</ui.Th>
                                    <ui.Td>
                                        {moment(data.order.created_at).format('YYYY/MM/DD HH:mm:ss')}
                                    </ui.Td>
                                </ui.Tr>

                                <ui.Tr>
                                    <ui.Th>Brand</ui.Th>
                                    <ui.Td>
                                        {data.order.brand.value}
                                    </ui.Td>
                                </ui.Tr>

                                <ui.Tr>
                                    <ui.Th>Staff</ui.Th>
                                    <ui.Td>
                                        {data.actions[0].staff.name}
                                    </ui.Td>
                                </ui.Tr>

                                <ui.Tr>
                                    <ui.Th>Note</ui.Th>
                                    <ui.Td whiteSpace="pre-line">
                                        {data.actions[0].note || '-'}
                                    </ui.Td>
                                </ui.Tr>
                            </ui.Tbody>
                        </ui.Table>
                    </LayoutItem>

                    <LayoutItem>
                        <ui.Heading>
                            明細とダウンロード
                        </ui.Heading>
                    </LayoutItem>

                    <LayoutItem>
                        <RadioInput
                            value={store}
                            onChange={(_, s) => setStore(s)}
                            choices={[
                                {value: null, label: '店舗指定なし'},
                                ...data.actions
                                .map(a => ({value: a.store, label: a.store?.name ?? '-'})),
                            ]}
                        />
                    </LayoutItem>

                    <LayoutItem>
                        <ui.Text>{details.length}件の明細</ui.Text>

                        {!showDetail && <ui.Button mt={4} onClick={() => setShowDetail(true)}>
                            明細を表示する
                        </ui.Button>}

                        {showDetail && <ui.Table mt={4}>
                            <ui.Thead>
                                <ui.Tr>
                                    <ui.Th px={1}>店舗</ui.Th>
                                    <ui.Th px={1}>納品先</ui.Th>
                                    <ui.Th>Image</ui.Th>
                                    <ui.Th>Item</ui.Th>
                                    <ui.Th>Amount</ui.Th>
                                    <ui.Th>Note</ui.Th>
                                </ui.Tr>
                            </ui.Thead>
                            <ui.Tbody>
                                {details.map((d) => <ui.Tr key={d.id}>
                                    <ui.Td px={1}>
                                        <ui.Text fontSize="xs">
                                            {actionMap[d.action_id].store?.name}
                                        </ui.Text>
                                    </ui.Td>
                                    <ui.Td px={1}>
                                        <ui.Text fontSize="xs">
                                            {d.warehouse?.code ?? 'free'}
                                        </ui.Text>
                                    </ui.Td>
                                    <ui.Td>
                                        {d.item.product.model.images[0] && <S3Image
                                            base={d.item.product.model.images[0].base_url}
                                            processor="sd128"
                                            maxWidth="60px"
                                        />}
                                    </ui.Td>
                                    <ui.Td>
                                        <ui.Text fontSize="xs">
                                            {d.item.barcode}
                                        </ui.Text>
                                        <ui.Text fontSize="xs">
                                            {d.item.product.model.code}
                                        </ui.Text>
                                        {d.item.product.model.code !== d.item.product.domestic_code && <ui.Text fontSize="xs">
                                            {d.item.product.domestic_code}
                                        </ui.Text>}
                                        <ui.Text fontSize="xs">
                                            {d.item.product.color} / {d.item.size}
                                        </ui.Text>
                                    </ui.Td>
                                    <ui.Td>
                                        {d.amount}
                                    </ui.Td>
                                    <ui.Td whiteSpace="pre-line" fontSize="xs">
                                        {d.note}
                                    </ui.Td>
                                </ui.Tr>)}
                            </ui.Tbody>
                        </ui.Table>}
                    </LayoutItem>

                    <LayoutItem>
                        {exporter && <ui.Button colorScheme="blue" onClick={() => exporter(store)}>
                            Download CSV
                        </ui.Button>}
                    </LayoutItem>
                </ui.Box>}
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>閉じる</ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>
    </ui.Modal>;
};


type ShipmentOrderDetail = Res<typeof getShipmentOrder>;
type ShipmentOrder = ShipmentOrderDetail['order'];
type ActionDetail = ArrayItem<ShipmentOrderDetail['details']>;
const useShipmentOrderExporter = (data: ShipmentOrderDetail | null): {(store?: Store | null): void} | null => {
    type Item = [
        string | {(a: ShipmentOrder): string},
        keyof ActionDetail | {(d: ActionDetail): string}
    ];

    return React.useMemo(() => {
        if (!data) {
            return null;
        }

        return (store?: Store | null) => {
            const {order, actions, details} = data;
            const actionIds = actions
                .filter(a => !store || a.store?.code === store.code)
                .map(a => a.id);
            const actionMap = actions.reduce(
                (acc, a) => ({...acc, [a.id]: a}),
                {} as Keyed<ArrayItem<typeof actions>>);

            const items: Item[] = [
                ['店舗コード', (d) => actionMap[d.action_id].store?.code ?? '-'],
                ['納品先コード', (d) => d.warehouse?.code ?? 'free'],
                ['商品コード', (d) => d.item.product.domestic_code],
                ['色名', (d) => d.item.product.color],
                ['サイズ名', (d) => d.item.size],
                ['バーコード', (d) => d.item.barcode],
                ['出荷数', (d) => `${d.amount}`],
                ['上代', (d) => `${d.item.product.model.price}`],
                ['上代合計', (d) => `${d.item.product.model.price * d.amount}`],
                ['メモ', (d) => d.note],
            ];

            const header = items.map(
                ([label, _]) => typeof label === 'string' ? label : label(order));
            const toRow = (d: ActionDetail): string => items
                .map(([_, item]) => typeof item === 'string' ? d[item] : item(d))
                .map((v: unknown) => `"${String(v).replace('"', '""')}"`).join(',');

            const csvData = [
                header,
                ...details.filter(d => actionIds.includes(d.action_id)).map(toRow)
            ].join('\n');

            const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
            const blob = new Blob([bom, csvData], {type: 'text/csv'});
            const link = document.createElement('a');
            link.download = `出荷指示-${order.id}${store ? `-${store.code}` : ''}.csv`;
            link.href = (window.URL || window.webkitURL).createObjectURL(blob);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }, [data]);
};


const ImportWarehouse = ({data}: {data: Res<typeof getData>}) => {
    const api = useWriterApi(importWarehouseStocks);
    const {binder, handleSubmit} = useForm<Req<typeof importWarehouseStocks>, 'brand' | 'data'>(() => ({
        data: undefined,
        brand: undefined,
        note: '',
    }));

    const submit = handleSubmit(data => api.call(data));
    const history = Router.useHistory();

    return <ui.Box>
        <FormItem
            label="data"
            keyPath="data"
            error={api.error?.data}
        >
            <TransactionDataInput
                {...binder.mapInputProps('data')}
                onParsed={(ret) => {
                    binder.assign({
                        brand: ret.brand,
                    });
                }}
            />
        </FormItem>

        <FormItem
            label="brand"
            keyPath="brand"
            error={api.error?.data}
        >
            {data.brands.find(b => b.value === binder.value.brand)?.value ?? '-'}
        </FormItem>

        <FormItem
            label="note"
            keyPath="note"
            error={api.error?.data}
        >
            <Textarea {...binder.mapInputProps('note')} />
        </FormItem>

        <LayoutItem>
            <ui.HStack>
                <ui.Spacer />
                <ui.Button colorScheme="blue" onClick={submit} isDisabled={(binder.value.data ?? []).length === 0}>
                    Submit
                </ui.Button>
            </ui.HStack>
        </LayoutItem>

        <ApiErrorDialog api={api} onOk={api.reset} />
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={() => {
                api.reset();
                history.push('/admin/stock');
            }}
        />
    </ui.Box>
};

const ImportFreeStocks = ({data}: {data: Res<typeof getData>}) => {
    const api = useWriterApi(importFreeStocks);
    const {binder, handleSubmit} = useForm<Req<typeof importFreeStocks>, 'brand' | 'data'>(() => ({
        data: undefined,
        brand: undefined,
        note: '',
    }));

    const submit = handleSubmit(data => api.call(data));
    const history = Router.useHistory();

    return <ui.Box>
        <FormItem
            label="data"
            keyPath="data"
            error={api.error?.data}
        >
            <TransactionDataInput
                {...binder.mapInputProps('data')}
                onParsed={(ret) => {
                    binder.assign({
                        brand: ret.brand,
                    });
                }}
            />
        </FormItem>

        <FormItem
            label="brand"
            keyPath="brand"
            error={api.error?.data}
        >
            {data.brands.find(b => b.value === binder.value.brand)?.value ?? '-'}
        </FormItem>

        <FormItem
            label="note"
            keyPath="note"
            error={api.error?.data}
        >
            <Textarea {...binder.mapInputProps('note')} />
        </FormItem>

        <LayoutItem>
            <ui.HStack>
                <ui.Spacer />
                <ui.Button colorScheme="blue" onClick={submit} isDisabled={(binder.value.data ?? []).length === 0}>
                    Submit
                </ui.Button>
            </ui.HStack>
        </LayoutItem>

        <ApiErrorDialog api={api} onOk={api.reset} />
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={() => {
                api.reset();
                history.push('/admin/stock');
            }}
        />
    </ui.Box>
};


const ImportStock = () => {
    const api = useWriterApi(importStocks);
    const {binder, handleSubmit} = useForm<Req<typeof importStocks>, 'data'>(() => ({
        data: undefined,
        note: '',
    }));

    const submit = handleSubmit(data => api.call(data));
    const history = Router.useHistory();

    return <ui.Box>
        <FormItem
            label="data"
            keyPath="data"
            error={api.error?.data}
        >
            <TransactionDataInput
                {...binder.mapInputProps('data')}
            />
        </FormItem>

        <FormItem
            label="note"
            keyPath="note"
            error={api.error?.data}
        >
            <Textarea {...binder.mapInputProps('note')} />
        </FormItem>

        <LayoutItem>
            <ui.HStack>
                <ui.Spacer />
                <ui.Button colorScheme="blue" onClick={submit} isDisabled={(binder.value.data ?? []).length === 0}>
                    Submit
                </ui.Button>
            </ui.HStack>
        </LayoutItem>

        <ApiErrorDialog api={api} onOk={api.reset} />
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={() => {
                api.reset();
                history.push('/admin/stock');
            }}
        />
    </ui.Box>
};
