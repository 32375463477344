import {React, ui, moment} from 'lib';

import {useWriterApi, useRemoteData, Req, Input, Textarea, FormItem, LayoutItem, useForm, ApiSpinnerDialog, ApiCompletionDialog, ApiErrorDialog, actionTypeText} from 'shared';
import {Action, ActionDetail} from 'types/elcstockbook/api/data.gen';

import {ActionDetailRow} from './preview';

const updatePath = 'staff/action/update';
const getTypes = 'staff/action/types';


export const ActionEditDialog = ({action, details = [], onComplete, ...props}: {
    action: Action;
    details?: ActionDetail[];
    onComplete(action: Action): void;
} & ui.UseDisclosureReturn) => {
    const api = useWriterApi(updatePath);
    const {binder, handleSubmit, reset} = useForm<Req<typeof updatePath>>(() => ({
        action_id: action.id,
        customer_name: action.customer_name,
        note: action.note,
    }));

    const {data: types} = useRemoteData({
        path: getTypes,
        request: {},
    });
    const optionData = React.useMemo(() => {
        return types?.find(a => a.action === action.type)?.data ?? [];
    }, [types, action]);

    const [limit, setLimit] = React.useState(50);

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
            setLimit(50);
        }
    }, [props.isOpen, action, reset, setLimit]);

    const submit = handleSubmit(data => api.call(data));

    return <ui.Modal size="3xl" {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">操作内容の編集</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <LayoutItem>
                    <form onSubmit={submit}>
                        <ui.Table size="sm">
                            <ui.Tbody>
                                <ui.Tr>
                                    <ui.Th px={0}>Id</ui.Th>
                                    <ui.Td>
                                        {action.id}
                                    </ui.Td>
                                </ui.Tr>

                                <ui.Tr>
                                    <ui.Th px={0}>Time</ui.Th>
                                    <ui.Td>
                                        {moment(action.created_at).format('YYYY/MM/DD HH:mm:ss')}
                                    </ui.Td>
                                </ui.Tr>

                                <ui.Tr>
                                    <ui.Th px={0}>Type</ui.Th>
                                    <ui.Td>
                                        {actionTypeText[action.type]}
                                    </ui.Td>
                                </ui.Tr>

                                <ui.Tr>
                                    <ui.Th px={0}>Store</ui.Th>
                                    <ui.Td>
                                        {action.store?.name ?? '-'}
                                    </ui.Td>
                                </ui.Tr>

                                <ui.Tr>
                                    <ui.Th px={0}>Staff</ui.Th>
                                    <ui.Td>
                                        {action.staff.name}
                                    </ui.Td>
                                </ui.Tr>

                                <ui.Tr>
                                    <ui.Th px={0}>Customer</ui.Th>
                                    <ui.Td>
                                        {optionData.includes('customer_name') ?
                                            <FormItem
                                                keyPath="customer_name"
                                                error={api.error?.data}
                                            >
                                                <Input
                                                    {...binder.mapInputProps('customer_name')}
                                                />
                                            </FormItem>
                                            : '-'}
                                    </ui.Td>
                                </ui.Tr>

                                <ui.Tr>
                                    <ui.Th px={0}>Note</ui.Th>
                                    <ui.Td whiteSpace="pre-line">
                                        <FormItem
                                            keyPath="note"
                                            error={api.error?.data}
                                        >
                                            <Textarea {...binder.mapInputProps('note')} />
                                        </FormItem>
                                    </ui.Td>
                                </ui.Tr>
                            </ui.Tbody>
                        </ui.Table>
                    </form>
                </LayoutItem>

                {details.length > 0 && <>
                    <LayoutItem>
                        <ui.Heading>
                            明細
                        </ui.Heading>
                    </LayoutItem>

                    <LayoutItem>
                        <ui.Table>
                            <ui.Tbody>
                                {details.slice(0, limit).map((d) => <ActionDetailRow
                                    key={d.id}
                                    detail={d}
                                />)}
                            </ui.Tbody>
                        </ui.Table>
                    </LayoutItem>

                    {limit < details.length && <LayoutItem>
                        <ui.Button onClick={() => setLimit(l => l + 50)}>
                            Read More
                        </ui.Button>
                    </LayoutItem>}
                </>}
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={submit}
                >
                    更新
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiErrorDialog api={api} onOk={api.reset} />
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={(data) => {
                onComplete(data);
                reset();
                api.reset();
            }}
        />
    </ui.Modal>;
};
