import {React, Router, ui} from 'lib';

import {useRemoteData, useWriterApi, Req, Res, AlertDialog, ApiErrorDialog, ApiSpinnerDialog, ApiCompletionDialog, LayoutItem, useForm, FormItem, Input, RadioInput, Paginator, usePage} from 'shared';


const getData = 'admin/get_data';
const createStore = 'admin/store/create';
const updateStore = 'admin/store/update';
const deleteStore = 'admin/store/delete';
const listWarehouses = 'admin/store/list_warehouses';
const createWarehouse = 'admin/store/create_warehouse';
const updateWarehouse = 'admin/store/update_warehouse';
const deleteWarehouse = 'admin/store/delete_warehouse';

type Brand = ArrayItem<Res<typeof getData>['brands']>;
type Store = ArrayItem<Res<typeof getData>['stores']>;
type Warehouse = ArrayItem<Res<typeof listWarehouses>['items']>;


export const StoreIndex = (): React.ReactElement => {
    const location = Router.useLocation();
    const history = Router.useHistory();
    const {url} = Router.useRouteMatch();

    const store = `${url}`;
    const warehouse = `${url}/warehouse`;
    const tabs = [store, warehouse];
    const index = tabs.indexOf(location.pathname)

    return <ui.Tabs index={index} onChange={i => history.push(tabs[i])}>
        <ui.TabList>
            <ui.Tab>店舗</ui.Tab>
            <ui.Tab>納品先</ui.Tab>
        </ui.TabList>

        <ui.TabPanels>
            {tabs.map((_, i) => <ui.TabPanel key={i}>
                {i === index && <Router.Switch>
                    <Router.Route path={warehouse}>
                        <WarehouseList />
                    </Router.Route>
                    <Router.Route path={store}>
                        <StoreList />
                    </Router.Route>
                </Router.Switch>}
            </ui.TabPanel>)}
        </ui.TabPanels>
    </ui.Tabs>;
}


const StoreList = () => {
    const {data, reload} = useRemoteData({
        path: getData,
        request: {},
    });
    const [editing, setEditing] = React.useState<Store>();
    const createDisclosure = ui.useDisclosure();
    const updateDisclosure = ui.useDisclosure({
        isOpen: editing !== undefined,
        onClose: () => setEditing(undefined),
    });

    if (!data) {
        return <ui.Spinner />;
    }

    return <ui.Box>
        <ui.Button onClick={createDisclosure.onOpen}>
            Add
        </ui.Button>

        <ui.Table mt={10}>
            <ui.Thead>
                <ui.Tr>
                    <ui.Th>Code</ui.Th>
                    <ui.Th>Name</ui.Th>
                    <ui.Th>Brand</ui.Th>
                    <ui.Th>Contact</ui.Th>
                </ui.Tr>
            </ui.Thead>
            <ui.Tbody>
                {data?.stores.map((store) => <ui.Tr key={store.id}>
                    <ui.Td>
                        <ui.Link color="blue.500" onClick={() => setEditing(store)}>
                            {store.code}
                        </ui.Link>
                    </ui.Td>
                    <ui.Td>{store.name}</ui.Td>
                    <ui.Td>{store.brand.value}</ui.Td>
                    <ui.Td>
                        {store.email && <ui.Text fontSize="xs">
                            {store.email}
                        </ui.Text>}
                        {store.tel && <ui.Text fontSize="xs">
                            {store.tel}
                        </ui.Text>}
                        {store.line && <ui.Text fontSize="xs">
                            {store.line}
                        </ui.Text>}
                    </ui.Td>
                </ui.Tr>)}
            </ui.Tbody>
        </ui.Table>

        <CreateStoreDialog
            brands={data.brands}
            onComplete={() => {
                createDisclosure.onClose();
                reload();
            }}
            {...createDisclosure}
        />
        <UpdateStoreDialog
            store={editing}
            onComplete={() => {
                updateDisclosure.onClose();
                reload();
            }}
            {...updateDisclosure}
        />
    </ui.Box>
};



const CreateStoreDialog = ({brands, onComplete, ...props}: {
    brands: Brand[];
    onComplete(store: Store): void;
} & ui.UseDisclosureReturn) => {
    const api = useWriterApi(createStore);
    const {binder, handleSubmit, reset} = useForm<Req<typeof createStore>, 'brand'>(() => ({
        code: '',
        name: '',
        brand: undefined,
        email: '',
        tel: '',
        line: '',
    }));

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, reset]);

    const submit = handleSubmit(data => api.call(data));

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">店舗の登録</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <FormItem
                        label="brand"
                        keyPath="brand"
                        error={api.error?.data}
                    >
                        <RadioInput<Brand['value']>
                            choices={brands.map((b) => ({value: b.value, label: b.value}))}
                            {...binder.mapInputProps('brand')}
                        />
                    </FormItem>

                    <FormItem
                        label="code"
                        keyPath="code"
                        error={api.error?.data}
                    >
                        <Input
                            {...binder.mapInputProps('code')}
                        />
                    </FormItem>

                    <FormItem
                        label="name"
                        keyPath="name"
                        error={api.error?.data}
                    >
                        <Input
                            {...binder.mapInputProps('name')}
                        />
                    </FormItem>

                    <FormItem
                        label="email"
                        keyPath="email"
                        error={api.error?.data}
                    >
                        <Input
                            {...binder.mapInputProps('email')}
                        />
                    </FormItem>

                    <FormItem
                        label="tel"
                        keyPath="tel"
                        error={api.error?.data}
                    >
                        <Input
                            {...binder.mapInputProps('tel')}
                        />
                    </FormItem>

                    <FormItem
                        label="line"
                        keyPath="line"
                        error={api.error?.data}
                    >
                        <Input
                            {...binder.mapInputProps('line')}
                        />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={submit}
                >
                    作成
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiErrorDialog api={api} onOk={api.reset} />
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={(data) => {
                onComplete(data);
                reset();
                api.reset();
            }}
        />
    </ui.Modal>;
};



const UpdateStoreDialog = ({store, onComplete, ...props}: {
    store: Store | undefined;
    onComplete(store: Store): void;
} & ui.UseDisclosureReturn) => {
    const api = useWriterApi(updateStore);
    const deleteApi = useWriterApi(deleteStore);
    const {binder, handleSubmit, reset} = useForm<Req<typeof updateStore>, keyof Req<typeof updateStore>>(() => ({
        id: store?.id,
        name: store?.name,
        email: store?.email,
        tel: store?.tel,
        line: store?.line,
    }));

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, store, reset]);

    const submit = handleSubmit(data => api.call(data));
    const confirmDisclosure = ui.useDisclosure();

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">店舗の編集</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <FormItem
                        label="name"
                        keyPath="name"
                        error={api.error?.data}
                    >
                        <Input
                            {...binder.mapInputProps('name')}
                        />
                    </FormItem>

                    <FormItem
                        label="email"
                        keyPath="email"
                        error={api.error?.data}
                    >
                        <Input
                            {...binder.mapInputProps('email')}
                        />
                    </FormItem>

                    <FormItem
                        label="tel"
                        keyPath="tel"
                        error={api.error?.data}
                    >
                        <Input
                            {...binder.mapInputProps('tel')}
                        />
                    </FormItem>

                    <FormItem
                        label="line"
                        keyPath="line"
                        error={api.error?.data}
                    >
                        <Input
                            {...binder.mapInputProps('line')}
                        />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button colorScheme="red" onClick={confirmDisclosure.onOpen}>削除</ui.Button>
                <ui.Spacer />
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={submit}
                >
                    更新
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        {store && <AlertDialog
            {...confirmDisclosure}
            title="削除の確認"
            confirm="削除する"
            onConfirm={async () => {
                await deleteApi.call({id: store.id});
                confirmDisclosure.onClose();
                onComplete(store);
            }}
            onCancel={confirmDisclosure.onClose}
        >
            <LayoutItem>
                <ui.Text>
                    削除してよろしいですか？
                </ui.Text>
            </LayoutItem>
        </AlertDialog>}
        <ApiSpinnerDialog api={deleteApi} />
        <ApiErrorDialog api={deleteApi} onOk={deleteApi.reset} />

        <ApiErrorDialog api={api} onOk={api.reset} />
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={(data) => {
                onComplete(data);
                reset();
                api.reset();
            }}
        />
    </ui.Modal>;
};


const WarehouseList = () => {
    const {data} = useRemoteData({
        path: getData,
        request: {},
    });

    if (!data) {
        return <ui.Spinner />;
    }
    return <WarehouseListImpl data={data} />
};


const WarehouseListImpl = ({data}: {data: Res<typeof getData>}) => {
    const [brand, setBrand] = React.useState(data.brands[0].value);
    const {data: warehouses, reload} = useRemoteData({
        path: listWarehouses,
        request: {
            brand,
            page: usePage(),
        },
    });
    const [editing, setEditing] = React.useState<Warehouse>();
    const createDisclosure = ui.useDisclosure();
    const updateDisclosure = ui.useDisclosure({
        isOpen: editing !== undefined,
        onClose: () => setEditing(undefined),
    });

    return <ui.Box>
        <LayoutItem>
            <ui.Button onClick={createDisclosure.onOpen}>
                Add
            </ui.Button>
        </LayoutItem>

        <LayoutItem>
            <RadioInput
                value={brand}
                onChange={(_, v) => setBrand(v)}
                choices={data.brands.map(b => ({value: b.value, label: b.value}))}
            />
        </LayoutItem>

        <LayoutItem>
            <ui.Table>
                <ui.Thead>
                    <ui.Tr>
                        <ui.Th>Code</ui.Th>
                        <ui.Th>Name</ui.Th>
                        <ui.Th>Store</ui.Th>
                    </ui.Tr>
                </ui.Thead>
                <ui.Tbody>
                    {warehouses?.items.map((wh) => <ui.Tr key={wh.id}>
                        <ui.Td>
                            <ui.Link color="blue.500" onClick={() => setEditing(wh)}>
                                {wh.code}
                            </ui.Link>
                        </ui.Td>
                        <ui.Td>{wh.name}</ui.Td>
                        <ui.Td>{wh.store.name}</ui.Td>
                    </ui.Tr>)}
                </ui.Tbody>
            </ui.Table>
        </LayoutItem>

        <LayoutItem>
            {warehouses && <LayoutItem>
                <Paginator {...warehouses} />
            </LayoutItem>}
        </LayoutItem>

        <CreateWarehouseDialog
            stores={data.stores.filter(s => s.brand.value === brand)}
            onComplete={() => {
                createDisclosure.onClose();
                reload();
            }}
            {...createDisclosure}
        />
        <UpdateWarehouseDialog
            warehouse={editing}
            onComplete={() => {
                updateDisclosure.onClose();
                reload();
            }}
            {...updateDisclosure}
        />
    </ui.Box>
};



const CreateWarehouseDialog = ({stores, onComplete, ...props}: {
    stores: Store[];
    onComplete(warehouse: Warehouse): void;
} & ui.UseDisclosureReturn) => {
    const api = useWriterApi(createWarehouse);
    const {binder, handleSubmit, reset} = useForm<Req<typeof createWarehouse>, 'store_id'>(() => ({
        code: '',
        name: '',
        store_id: undefined,
    }));

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, reset]);

    const submit = handleSubmit(data => api.call(data));

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">納品先の登録</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <FormItem
                        label="store_id"
                        keyPath="store_id"
                        error={api.error?.data}
                    >
                        <RadioInput
                            choices={stores.map((s) => ({value: s.id, label: s.name}))}
                            {...binder.mapInputProps('store_id')}
                        />
                    </FormItem>

                    <FormItem
                        label="code"
                        keyPath="code"
                        error={api.error?.data}
                    >
                        <Input
                            {...binder.mapInputProps('code')}
                        />
                    </FormItem>

                    <FormItem
                        label="name"
                        keyPath="name"
                        error={api.error?.data}
                    >
                        <Input
                            {...binder.mapInputProps('name')}
                        />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={submit}
                >
                    作成
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiErrorDialog api={api} onOk={api.reset} />
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={(data) => {
                onComplete(data);
                reset();
                api.reset();
            }}
        />
    </ui.Modal>;
};



const UpdateWarehouseDialog = ({warehouse, onComplete, ...props}: {
    warehouse: Warehouse | undefined;
    onComplete(store: Warehouse): void;
} & ui.UseDisclosureReturn) => {
    const api = useWriterApi(updateWarehouse);
    const deleteApi = useWriterApi(deleteWarehouse);
    const {binder, handleSubmit, reset} = useForm<Req<typeof updateWarehouse>, keyof Req<typeof updateWarehouse>>(() => ({
        id: warehouse?.id,
        name: warehouse?.name,
    }));

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, warehouse, reset]);

    const submit = handleSubmit(data => api.call(data));
    const confirmDisclosure = ui.useDisclosure();

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">納品先の編集</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <FormItem
                        label="name"
                        keyPath="name"
                        error={api.error?.data}
                    >
                        <Input
                            {...binder.mapInputProps('name')}
                        />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button colorScheme="red" onClick={confirmDisclosure.onOpen}>削除</ui.Button>
                <ui.Spacer />
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={submit}
                >
                    更新
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        {warehouse && <AlertDialog
            {...confirmDisclosure}
            title="削除の確認"
            confirm="削除する"
            onConfirm={async () => {
                await deleteApi.call({id: warehouse.id});
                confirmDisclosure.onClose();
                onComplete(warehouse);
            }}
            onCancel={confirmDisclosure.onClose}
        >
            <LayoutItem>
                <ui.Text>
                    削除してよろしいですか？
                </ui.Text>
            </LayoutItem>
        </AlertDialog>}
        <ApiSpinnerDialog api={deleteApi} />
        <ApiErrorDialog api={deleteApi} onOk={deleteApi.reset} />

        <ApiErrorDialog api={api} onOk={api.reset} />
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={(data) => {
                onComplete(data);
                reset();
                api.reset();
            }}
        />
    </ui.Modal>;
};
