import {Model} from 'types/elcstockbook/api/data.gen';

export type Gender = Model['gender'];


export const genderText: {[K in Gender]: string} = {
    M: 'Men',
    W: 'Women',
    U: 'Unisex',
    K: 'Kids',
};

export const genderChoices = Object.entries(genderText).map(([value, label]) => ({
    value: value as Gender,
    label: label,
}));
