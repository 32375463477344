import {React, Router, ui, xlsx} from 'lib';

import {SelectionStateProvider, FileInput, useChildUrl} from 'shared';

import {useStore} from './base';

import {Action} from './action';


export function Bulk() {
    const history = Router.useHistory();
    const backLink = useChildUrl('');

    const [amounts, setAmounts] = React.useState({} as Keyed<number>);

    const disclosure = ui.useDisclosure({
        isOpen: Object.keys(amounts).length === 0,
        onClose: () => history.replace(backLink),
    });

    const storage = React.useMemo(() => {
        return {
            getItem: () => JSON.stringify(amounts),
        };
    }, [amounts]);

    const store = useStore();

    return <>
        {!disclosure.isOpen && <SelectionStateProvider
            store={store}
            storage={storage}
        >
            <Action
                title="一括操作"
                onComplete={() => {
                    history.replace(backLink);
                    return true;
                }}
                checkMissing={true}
            />
        </SelectionStateProvider>}

        <ui.Modal
            {...disclosure}
            size="3xl"
            closeOnOverlayClick={false}
        >
            <ui.ModalOverlay />
            <ui.ModalContent>
                <ui.ModalHeader textAlign="center">
                    一括操作
                </ui.ModalHeader>
                <ui.ModalCloseButton />

                <ui.ModalBody>
                    <FileInput
                        value={undefined}
                        onChange={() => {/* do nothing */}}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        onReadDataAsArrayBuffer={(buffer) => {
                            const wb = xlsx.read(buffer, {type: 'array'});
                            const data = {} as Keyed<number>;
                            Object.values(wb.Sheets).forEach((sheet) => {
                                xlsx.utils.sheet_to_json(sheet).forEach((_row) => {
                                    const row = _row as {
                                        'バーコード'?: string;
                                        'barcode'?: string;
                                        '数量'?: string;
                                        '出荷数'?: string;
                                        'amount'?: string;
                                    };

                                    const barcode = row['バーコード'] ?? row['barcode'];
                                    if (barcode) {
                                        const amount = parseInt(row['数量'] ?? row['出荷数'] ?? row['amount'] ?? '');
                                        data[barcode] = (data[barcode] ?? 0) + (Number.isNaN(amount) ? 1 : amount);
                                    }
                                });
                            });
                            setAmounts(data);
                        }}
                    />
                </ui.ModalBody>

                <ui.ModalFooter>
                    <ui.Button onClick={disclosure.onClose}>
                        Close
                    </ui.Button>
                </ui.ModalFooter>
            </ui.ModalContent>
        </ui.Modal>
    </>;
}
