import {React, ui, RouterDom} from 'lib';

import {Urls as ApiUrls, WriterUrls, ReaderUrls, State as ApiState, WriterApi, ReaderApi, RemoteData, Res, ApiError, ValidationError} from './api';


export const Spinner = (props: Props<typeof ui.Spinner>): React.ReactElement => <ui.Spinner
    thickness="4px"
    speed="0.65s"
    emptyColor="gray.200"
    color="blue.500"
    {...props}
/>;


export const Loading = () => {
    return <ui.Center my={10}>
        <Spinner size="lg" />
    </ui.Center>
}


export const LoadingScreen = () => {
    return <ui.Center my={20}>
        <ui.HStack>
            <Spinner size="xl" mr={2} />

            <EmptyText>
                読み込み中...
            </EmptyText>
        </ui.HStack>
    </ui.Center>
}


export const SimpleCenteredContent = ({children, title}: {
    title?: string;
    children: React.ReactNode;
}): React.ReactElement => {
    return <ui.Container centerContent>
        <ui.Flex height="100%" alignItems="center" direction="row">
            <ui.VStack width="300px">
                {title && <ui.Box mb={5}>
                    <ui.Text fontSize="xl" fontWeight="bold">{title}</ui.Text>
                </ui.Box>}

                <ui.Box width="100%">
                    {children}
                </ui.Box>
            </ui.VStack>
        </ui.Flex>
    </ui.Container>
};


export const RouterLink = RouterDom.Link;


export const RouterLinkOverlay = (props: Props<typeof RouterDom.Link> & Props<typeof ui.LinkOverlay>): React.ReactElement => {
    return <ui.LinkOverlay
        as={RouterDom.Link}
        {...props}
    />;
};


export const RouterTextLink = ({
    color = 'blue.500',
    ...props
}: Props<typeof RouterDom.Link> & Props<typeof ui.Link>): React.ReactElement => {
    return <ui.Link
        as={RouterDom.Link}
        color={color}
        {...props}
    />;
};


export const EmptyText = ({
    color = 'gray.500',
    ...props
}: Props<typeof ui.Text>): React.ReactElement => {
    return <ui.Text color={color} {...props} />;
};


export const ErrorText = ({
    color = 'red.500',
    whiteSpace = 'pre-line',
    ...props
}: Props<typeof ui.Text>): React.ReactElement => {
    return <ui.Text color={color} whiteSpace={whiteSpace} {...props} />;
};



export const Section = ({
    title,
    titleSize = 'lg',
    titleRightAccessory,
    description,
    children,
    ...other
}: {
    title?: string;
    titleSize?: ui.HeadingProps['size'];
    titleRightAccessory?: React.ReactNode;
    description?: string;
} & Props<typeof ui.Box>): React.ReactElement => {
    const {space} = ui.useTheme();
    return <ui.Box
        {...other}
        width="100%"
        sx={{'& + &': {
            'marginTop': space[12],
        }}}
    >
        <LayoutItem.Group space={4}>
            {title && <LayoutItem.Group>
                <LayoutItem>
                    <ui.HStack>
                        <ui.Heading size="lg">
                            {title}
                        </ui.Heading>
                        <ui.Spacer />
                        {titleRightAccessory}
                    </ui.HStack>
                </LayoutItem>

                {description && <LayoutItem>
                    <ui.Text fontSize="sm" color="gray.500">
                        {description}
                    </ui.Text>
                </LayoutItem>}
            </LayoutItem.Group>}

            <LayoutItem>
                {children}
            </LayoutItem>
        </LayoutItem.Group>
    </ui.Box>;
};




type Space = NonNullable<Props<typeof ui.Box>['mt']>;
const defaultSpace = 6;
const LayoutItemSpacing = React.createContext<Space>(defaultSpace);

export const LayoutItem = ({children}: {
    children: React.ReactNode;
}): React.ReactElement => {
    const {space} = ui.useTheme();
    const size = React.useContext(LayoutItemSpacing) as keyof typeof space;
    const mt = size in space ? space[size] : size;
    return <LayoutItemSpacing.Provider value={defaultSpace}>
        <ui.Box
            className={`layout-item-${mt}`}
            width="100%"
            sx={{'& + &': {
                'marginTop': mt,
            }}}
            children={children}
        />
    </LayoutItemSpacing.Provider>;
};

LayoutItem.Group = ({space = 2, children}: {
    space?: Space;
    children: React.ReactNode;
}): React.ReactElement => {
    return <LayoutItem>
        <LayoutItemSpacing.Provider value={space}>
            {children}
        </LayoutItemSpacing.Provider>
    </LayoutItem>
};


const getError = (error: ValidationError | null | undefined, keyPath: string | null | undefined): ValidationError | null => {
    let vError = error;

    if (keyPath) {
        keyPath.split('.').forEach((key) => {
            vError = vError?.nested?.[key];
        });
    }

    return vError ?? null;
};


export const ApiErrorText = ({error}: {
    error: ApiError | null | undefined;
}) => {
    const messages: string[] = [];

    if (error?.data) {
        messages.push(...error.data.items.map(i => i.message));
    }

    if (error?.message) {
        messages.push(error.message);
    }

    if (messages.length === 0) {
        return null;
    }

    return <LayoutItem>
        <ErrorText>
            {messages.join('\n')}
        </ErrorText>
    </LayoutItem>;
};


export const ValidationErrorText = ({keyPath, error}: {
    keyPath?: string;
    error: ValidationError | null | undefined;
}): React.ReactElement | null => {
    const e = getError(error, keyPath);

    if (!e || e.items.length === 0) {
        return null;
    }

    return <ErrorText>
        {e.items.map(i => i.message).join('\n')}
    </ErrorText>;
};


export const FormItem = ({label, caption, keyPath, error, children}: {
    label?: string;
    caption?: string;
    keyPath?: string;
    error?: ValidationError | null;
    children?: React.ReactNode | null;
}): React.ReactElement | null => {
    if (!children) {
        return null;
    }

    const e = getError(error, keyPath);

    return <LayoutItem>
        <ui.FormControl isInvalid={(e?.items.length ?? 0) > 0}>
            {label && <ui.FormLabel fontWeight="bold">{label}</ui.FormLabel>}
            {caption && <ui.Text color="gray.600" fontSize="sm" my={1} whiteSpace="pre-line">{caption}</ui.Text>}
            {children}

            <ValidationErrorText error={e} />
        </ui.FormControl>
    </LayoutItem>
};


export const ApiErrorDialog = <U extends ApiUrls>({
    api,
    title = 'エラーが発生しました',
    ok = 'OK',
    onOk,
}: {
    api: ApiState<U>;
    title?: string;
    ok?: string;
    onOk(): void;
}): React.ReactElement => {
    const {error, state} = api;
    const disclosure = ui.useDisclosure({
        isOpen: state === 'failure' && error?.kind !== 'validation_failed',
    });
    return <ui.Modal
        {...disclosure}
        size="xs"
        isCentered
    >
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">{title}</ui.ModalHeader>
            <ui.ModalBody>
                <ui.Box mb={4}>
                    <ui.Text color="red" whiteSpace="pre-line">{error?.message}</ui.Text>
                </ui.Box>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button colorScheme="blue" mr={3} onClick={onOk}>
                    {ok}
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>
    </ui.Modal>;
};


export const SpinnerDialog = ({
    title = '処理中です',
    message = 'しばらくお待ちください',
    closable = false,
    ...props
}: {
    title?: string;
    message?: string;
    closable?: boolean;
} & Pick<Props<typeof ui.Modal>, 'onClose' | 'isOpen'>) => {
    return <ui.Modal
        {...props}
        closeOnOverlayClick={closable}
        size="xs"
        isCentered
    >
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">{title}</ui.ModalHeader>
            <ui.ModalBody>
                <ui.Center mb={5}>
                    <Spinner size="xl" />
                </ui.Center>

                <ui.Box mb={4}>
                    <ui.Text textAlign="center">{message}</ui.Text>
                </ui.Box>
            </ui.ModalBody>
        </ui.ModalContent>
    </ui.Modal>;
};


export const ApiSpinnerDialog = <U extends ApiUrls>({api, ...props}: {
    api: ApiState<U>;
} & Excluded<Props<typeof SpinnerDialog>, 'onClose' | 'isOpen'>): React.ReactElement => {
    const disclosure = ui.useDisclosure({
        isOpen: api.loading,
    });
    return <SpinnerDialog
        {...props}
        {...disclosure}
    />
};


export const CompletionDialog = ({
    title = '処理が完了しました',
    message = '',
    ok = 'OK',
    onOk,
    okAndThen = '続ける',
    onOkAndThen,
    closable = false,
    ...props
}: {
    title?: string;
    message?: string;
    ok?: string;
    onOk(): void;
    okAndThen?: string;
    onOkAndThen?(): void;
    closable?: boolean;
} & Pick<Props<typeof ui.Modal>, 'onClose' | 'isOpen'>): React.ReactElement => {
    return <ui.Modal
        {...props}
        closeOnOverlayClick={closable}
        size="xs"
        isCentered
    >
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">{title}</ui.ModalHeader>
            {message && <ui.ModalBody>
                <ui.Box mb={4}>
                    <ui.Text textAlign="center">{message}</ui.Text>
                </ui.Box>
            </ui.ModalBody>}

            <ui.ModalFooter>
                {onOkAndThen && <ui.Button mr={3} onClick={onOkAndThen}>
                    {okAndThen}
                </ui.Button>}
                <ui.Button colorScheme="blue" mr={3} onClick={onOk}>
                    {ok}
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>
    </ui.Modal>;
};


type ApiCompletionDialogProps<U extends ApiUrls, S extends ApiState<U>> = {
    api: S;
    onOk(res: Res<U>): void;
    onOkAndThen?(res: Res<U>): void;
} & Excluded<Props<typeof CompletionDialog>, 'onOk' | 'onOkAndThen' | 'onClose' | 'isOpen'>;

export function ApiCompletionDialog<U extends WriterUrls>(props: ApiCompletionDialogProps<U, WriterApi<U>>): React.ReactElement;
export function ApiCompletionDialog<U extends ReaderUrls>(props: ApiCompletionDialogProps<U, ReaderApi<U>>): React.ReactElement;
export function ApiCompletionDialog<U extends ReaderUrls>(props: ApiCompletionDialogProps<U, RemoteData<U>>): React.ReactElement;
export function ApiCompletionDialog<U extends ApiUrls>(props: ApiCompletionDialogProps<U, ApiState<U>>): React.ReactElement;
export function ApiCompletionDialog<U extends ApiUrls, S extends ApiState<U>>({api, onOk, onOkAndThen, ...props}: ApiCompletionDialogProps<U, S>): React.ReactElement {
    const disclosure = ui.useDisclosure({
        isOpen: api.state === 'success',
    });
    return <CompletionDialog
        onOk={() => api.state === 'success' && onOk(api.data)}
        onOkAndThen={onOkAndThen ? () => {
            api.state === 'success' && onOkAndThen(api.data);
        } : undefined}
        {...props}
        {...disclosure}
    />
};


export const ConfirmationDialog = ({
    title,
    message,
    confirm = 'OK',
    onConfirm,
    confirmDisabled,
    confirmTextDisabled,
    cancel = 'キャンセル',
    onCancel,
    children,
    danger,
    ...props
}: {
    title?: string;
    message?: string;
    confirm?: string;
    onConfirm(): void;
    confirmDisabled?: boolean;
    confirmTextDisabled?: string;
    cancel?: string;
    onCancel?(): void;
    danger?: boolean;
    children?: React.ReactNode;
} & Pick<Props<typeof ui.Modal>, 'onClose' | 'isOpen'>): React.ReactElement => {
    return <ui.Modal
        {...props}
        closeOnOverlayClick={false}
        size="xs"
        isCentered
    >
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">{title}</ui.ModalHeader>
            {(message || children) && <ui.ModalBody>
                <ui.Box mb={4}>
                    {message && <LayoutItem>
                        <ui.Text textAlign="center">{message}</ui.Text>
                    </LayoutItem>}
                    {children && <LayoutItem>
                        {children}
                    </LayoutItem>}
                </ui.Box>
            </ui.ModalBody>}

            <ui.ModalFooter>
                <ui.Button onClick={onCancel ?? props.onClose} mr={3}>{cancel}</ui.Button>
                <ui.Button colorScheme={danger ? 'red' : 'blue'} onClick={onConfirm} isDisabled={confirmDisabled}>
                    {confirmDisabled ? confirmTextDisabled : confirm}
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>
    </ui.Modal>;
};


export const AlertDialog = ({
    title,
    message,
    children,
    isDisabled = false,
    confirm = 'OK',
    onConfirm,
    cancel = 'キャンセル',
    onCancel,
    isCentered = true,
    ...props
}: {
    title: string;
    message?: string;
    children?: React.ReactNode;
    isDisabled?: boolean;
    confirm?: string;
    onConfirm(): void;
    cancel?: string;
    onCancel(): void;
    isCentered?: boolean;
} & Pick<ui.AlertDialogProps, 'onClose' | 'isOpen'>): React.ReactElement => {
    const cancelRef = React.useRef<HTMLButtonElement>(null)
    return <ui.AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        size="xs"
        {...props}
        isCentered={isCentered}
    >
        <ui.AlertDialogOverlay />

        <ui.AlertDialogContent>
            <ui.AlertDialogHeader>{title}</ui.AlertDialogHeader>
            <ui.AlertDialogCloseButton />
            {(message || children) && <ui.AlertDialogBody>
                <ui.Box mb={4}>
                    <LayoutItem>
                        <ui.Text textAlign="center" whiteSpace="pre-line">{message}</ui.Text>
                    </LayoutItem>
                    <LayoutItem>
                        {children}
                    </LayoutItem>
                </ui.Box>
            </ui.AlertDialogBody>}
            <ui.AlertDialogFooter>
                <ui.Button ref={cancelRef} onClick={onCancel}>
                    {cancel}
                </ui.Button>
                <ui.Button colorScheme="red" ml={3} onClick={onConfirm} isDisabled={isDisabled}>
                    {confirm}
                </ui.Button>
            </ui.AlertDialogFooter>
        </ui.AlertDialogContent>
    </ui.AlertDialog>;
};
