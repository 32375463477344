import {React, Router, RouterDom, ReactDOM, Recoil, ui} from 'lib';

import {SessionProvider, SessionRequired, LoginRequired} from 'shared';

import {Admin} from './admin';
import {Staff} from './staff';
import {Public} from './public';


const Index = (): React.ReactElement => {
    return <RouterDom.BrowserRouter>
        <Recoil.RecoilRoot>
            <Router.Switch>
                <Router.Route path="/admin">
                    <LoginRequired>
                        <Admin />
                    </LoginRequired>
                </Router.Route>

                <Router.Route path="/staff/:store?">
                    <LoginRequired>
                        <Staff />
                    </LoginRequired>
                </Router.Route>

                <Router.Route path="/">
                    <Public />
                </Router.Route>
            </Router.Switch>
        </Recoil.RecoilRoot>
    </RouterDom.BrowserRouter>;
}

ReactDOM.render(
    <React.StrictMode>
        <ui.ChakraProvider>
            <SessionProvider>
                <SessionRequired>
                    <Index />
                </SessionRequired>
            </SessionProvider>
        </ui.ChakraProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
