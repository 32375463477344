import {React, ui, moment} from 'lib';

import {useRemoteData, Req, Res, useWriterApi, useForm, FormItem, Textarea, ApiErrorDialog, ApiSpinnerDialog, ApiCompletionDialog, LayoutItem} from 'shared';

import {StaffFrame, useStore} from './base';
import {ActionDetailRow} from './action/preview';


const getLeasesPath = 'staff/get_leases';
const releasedPath = 'staff/released';
type Lease = ArrayItem<Res<typeof getLeasesPath>>;

const getRepairsPath = 'staff/get_repairs';
const fixedPath = 'staff/fixed';
const brokenPath = 'staff/broken';
type Repair = ArrayItem<Res<typeof getRepairsPath>>;

type LeaseOrRepair = Lease | Repair;


export function LeaseRepair(): React.ReactElement {
    return <StaffFrame
        title="リース・修理"
    >
        <Leases />
        <Repairs />
    </StaffFrame>;
}


const Leases = (): React.ReactElement => {
    const store = useStore();

    const {data, reload} = useRemoteData({
        path: getLeasesPath,
        request: {
            store_id: store.id,
        }
    });

    const [editing, setEditing] = React.useState<Lease>();
    const disclosure = ui.useDisclosure({
        isOpen: editing !== undefined,
        onClose: () => setEditing(undefined),
    });

    return <ui.Box my={10}>
        {data && <LayoutItem>
            <ui.Heading>
                リース
            </ui.Heading>
        </LayoutItem>}

        {data?.length === 0 && <LayoutItem>
            <ui.Text color="gray.500">
                リースはありません
            </ui.Text>
        </LayoutItem>}

        {data?.map((lease) => {
            const {action} = lease;
            return <LayoutItem key={lease.id}>
                <ui.Box border="1px solid #eee" borderRadius="4px" p={4}>
                    <ui.Flex justifyContent="space-between">
                        <ui.Box>
                            <ui.Text fontSize="xs">
                                {moment(action.created_at).format('YYYY/MM/DD')}
                            </ui.Text>

                            <ui.Flex alignItems="center" mt={2}>
                                <ui.Text fontWeight="bold">
                                    {action.customer_name}様
                                </ui.Text>

                                <ui.Text ml={2} fontSize="small">
                                    by {action.staff.name}
                                </ui.Text>
                            </ui.Flex>
                        </ui.Box>

                        <ui.Button onClick={() => setEditing(lease)} colorScheme="blue">
                            リース完了
                        </ui.Button>
                    </ui.Flex>

                    {action.note && <ui.Text mt={2} whiteSpace="pre-line">
                        {action.note}
                    </ui.Text>}

                    <ItemPreview item={lease} />

                </ui.Box>
            </LayoutItem>;
        })}

        <ReleaseActionDialog
            lease={editing}
            onComplete={() => {
                disclosure.onClose();
                reload();
            }}
            {...disclosure}
        />
    </ui.Box>
};


const Repairs = (): React.ReactElement => {

    const store = useStore();

    const {data, reload} = useRemoteData({
        path: getRepairsPath,
        request: {
            store_id: store.id,
        }
    });

    const [toFixed, setToFixed] = React.useState<Repair>();
    const fixedDisclosure = ui.useDisclosure({
        isOpen: toFixed !== undefined,
        onClose: () => setToFixed(undefined),
    });

    const [toBroken, setToBroken] = React.useState<Repair>();
    const brokenDisclosure = ui.useDisclosure({
        isOpen: toBroken !== undefined,
        onClose: () => setToBroken(undefined),
    });

    return <ui.Box my={10}>
        {data && <LayoutItem>
            <ui.Heading>
                修理
            </ui.Heading>
        </LayoutItem>}

        {data?.length === 0 && <LayoutItem>
            <ui.Text color="gray.500">
                修理はありません
            </ui.Text>
        </LayoutItem>}

        {data?.map((repair) => {
            const {action} = repair;
            return <LayoutItem key={repair.id}>
                <ui.Box border="1px solid #eee" borderRadius="4px" p={4}>
                    <ui.Flex justifyContent="space-between">
                        <ui.Text fontSize="xs">
                            {moment(action.created_at).format('YYYY/MM/DD')}
                        </ui.Text>
                        <ui.VStack>
                            <ui.Button onClick={() => setToFixed(repair)} colorScheme="blue">
                                修理完了
                            </ui.Button>
                            <ui.Button onClick={() => setToBroken(repair)} colorScheme="red">
                                修理不可
                            </ui.Button>
                        </ui.VStack>
                    </ui.Flex>

                    {action.note && <ui.Text mt={2} whiteSpace="pre-line">
                        {action.note}
                    </ui.Text>}

                    <ItemPreview item={repair} />

                </ui.Box>
            </LayoutItem>;
        })}

        <FixedActionDialog
            repair={toFixed}
            onComplete={() => {
                fixedDisclosure.onClose();
                reload();
            }}
            {...fixedDisclosure}
        />

        <BrokenActionDialog
            repair={toBroken}
            onComplete={() => {
                brokenDisclosure.onClose();
                reload();
            }}
            {...brokenDisclosure}
        />
    </ui.Box>
};


const ItemPreview = ({item}: {item: LeaseOrRepair}) => {
    return <ui.Table>
        <ui.Tbody>
            <ActionDetailRow detail={item.detail} />
        </ui.Tbody>
    </ui.Table>
};


const FixedActionDialog = ({repair, onComplete, ...props}: {
    repair: Repair | undefined;
    onComplete(): void;
} & ui.UseDisclosureReturn) => {
    const store = useStore();
    const api = useWriterApi(fixedPath);
    const {binder, handleSubmit, reset} = useForm<Req<typeof fixedPath>, 'repair_id'>(() => ({
        store_id: store.id,
        repair_id: repair?.id,
        note: '',
    }));

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, repair, reset]);

    const submit = handleSubmit(data => api.call(data));

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">修理完了</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <FormItem label="Item">
                    {repair && <ItemPreview item={repair} />}
                </FormItem>

                <FormItem
                    label="note"
                    keyPath="note"
                    error={api.error?.data}
                >
                    <Textarea
                        {...binder.mapInputProps('note')}
                    />
                </FormItem>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={submit}
                >
                    確定
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiErrorDialog api={api} onOk={api.reset} />
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={() => {
                onComplete();
                reset();
                api.reset();
            }}
        />
    </ui.Modal>;
};


const BrokenActionDialog = ({repair, onComplete, ...props}: {
    repair: Repair | undefined;
    onComplete(): void;
} & ui.UseDisclosureReturn) => {
    const store = useStore();
    const api = useWriterApi(brokenPath);
    const {binder, handleSubmit, reset} = useForm<Req<typeof brokenPath>, 'repair_id'>(() => ({
        store_id: store.id,
        repair_id: repair?.id,
        note: '',
    }));

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, repair, reset]);

    const submit = handleSubmit(data => api.call(data));

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">修理不可</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <FormItem label="Item">
                    {repair && <ItemPreview item={repair} />}
                </FormItem>

                <FormItem
                    label="note"
                    keyPath="note"
                    error={api.error?.data}
                >
                    <Textarea
                        {...binder.mapInputProps('note')}
                    />
                </FormItem>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={submit}
                >
                    確定
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiErrorDialog api={api} onOk={api.reset} />
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={() => {
                onComplete();
                reset();
                api.reset();
            }}
        />
    </ui.Modal>;
};


const ReleaseActionDialog = ({lease, onComplete, ...props}: {
    lease: Lease | undefined;
    onComplete(): void;
} & ui.UseDisclosureReturn) => {
    const store = useStore();
    const api = useWriterApi(releasedPath);
    const {binder, handleSubmit, reset} = useForm<Req<typeof releasedPath>, 'lease_id'>(() => ({
        store_id: store.id,
        lease_id: lease?.id,
        note: '',
    }));

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, lease, reset]);

    const submit = handleSubmit(data => api.call(data));

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">リース完了</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <FormItem label="Item">
                    {lease && <ItemPreview item={lease} />}
                </FormItem>

                <FormItem
                    label="note"
                    keyPath="note"
                    error={api.error?.data}
                >
                    <Textarea
                        {...binder.mapInputProps('note')}
                    />
                </FormItem>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={submit}
                >
                    確定
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiErrorDialog api={api} onOk={api.reset} />
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={() => {
                onComplete();
                reset();
                api.reset();
            }}
        />
    </ui.Modal>;
};
