import {React, ui, moment} from 'lib';

import {useRemoteData, Req, useForm, FormItem, LayoutItem, OptionalDateInput, RadioInput, CheckboxInput, useInputProps, Paginator, usePage, S3Image, useSingleQueryValueAsNumber, ActionType, actionTypeText, actionTypeChoices} from 'shared';

const listPath = 'admin/action/list';
const getPath = 'admin/action/get';


export const ActionIndex = (): React.ReactElement => {
    const id = useSingleQueryValueAsNumber('id');
    const detailDisclosure = ui.useDisclosure({
        isOpen: id.value !== null,
        onClose: () => id.replace(null),
    });

    return <>
        <ui.Heading fontSize="lg" mb={10}>
            操作履歴
        </ui.Heading>
        <List />
        <DetailDialog id={id.value} {...detailDisclosure} />
    </>;
}

const List = () => {
    const id = useSingleQueryValueAsNumber('id');

    const {data} = useRemoteData({
        path: 'admin/get_data',
        request: {},
    });

    const {binder} = useForm<Req<typeof listPath>>(() => ({
        store_id: null,
        brand: null,
        types: [],
        date_from: moment().format('YYYY-MM-01'),
        date_to: moment().format('YYYY-MM-DD'),
    }));

    const {data: list} = useRemoteData({
        path: listPath,
        request: {
            ...binder.value,
            page: usePage(),
        },
    });

    const brand = useInputProps(binder.bind('brand'), {
        sideEffect: () => ({store_id: null}),
    });

    if (!data) {
        return <ui.Spinner />
    }

    return <ui.Box>
        <FormItem>
            <RadioInput
                {...brand}
                choices={[
                    {value: null, label: 'ブランド指定なし'},
                    ...data.brands.map(b => ({value: b.value, label: b.value})),
                ]}
            />
        </FormItem>

        {brand.value && <FormItem>
            <RadioInput
                {...binder.mapInputProps('store_id')}
                choices={[
                    {value: null, label: '店舗指定なし'},
                    ...data.stores
                    .filter(s => s.brand.value === brand.value)
                    .map(s => ({value: s.id, label: s.name})),
                ]}
            />
        </FormItem>}

        <FormItem>
            <ui.HStack>
                <OptionalDateInput
                    {...binder.mapInputProps('date_from')}
                    type="date"
                />

                <OptionalDateInput
                    {...binder.mapInputProps('date_to')}
                    type="date"
                />
            </ui.HStack>
        </FormItem>

        <FormItem>
            <CheckboxInput<ActionType>
                {...binder.mapInputProps('types')}
                choices={actionTypeChoices}
            />
        </FormItem>

        <LayoutItem>
            {!list && <ui.Spinner />}

            {list && <ui.Table mt={10}>
                <ui.Thead>
                    <ui.Tr>
                        <ui.Th>Id</ui.Th>
                        <ui.Th>Time</ui.Th>
                        <ui.Th>Store/Brand</ui.Th>
                        <ui.Th>Type</ui.Th>
                        <ui.Th>Staff</ui.Th>
                        <ui.Th>Customer</ui.Th>
                        <ui.Th>Note</ui.Th>
                    </ui.Tr>
                </ui.Thead>
                <ui.Tbody>
                    {list?.items.map((action) => <ui.Tr key={action.id}>
                        <ui.Td>
                            <ui.Button onClick={() => id.push(action.id)} variant="link" colorScheme="blue">
                                {action.id}
                            </ui.Button>
                        </ui.Td>
                        <ui.Td fontSize="xs">
                            {moment(action.created_at).format('YYYY/MM/DD HH:mm')}
                        </ui.Td>
                        <ui.Td fontSize="xs">
                            {action.store?.name ?? action.brand}
                        </ui.Td>
                        <ui.Td fontSize="xs">
                            {actionTypeText[action.type]}
                        </ui.Td>
                        <ui.Td fontSize="xs">
                            {action.staff.name}
                        </ui.Td>
                        <ui.Td fontSize="xs">
                            {action.customer_name}
                        </ui.Td>
                        <ui.Td fontSize="xs" whiteSpace="pre-line">
                            {action.note}
                        </ui.Td>
                    </ui.Tr>)}
                </ui.Tbody>
            </ui.Table>}
        </LayoutItem>

        {list && <LayoutItem>
            <Paginator {...list} />
        </LayoutItem>}
    </ui.Box>
};


const DetailDialog = ({id: actionId, ...props}: {
    id: number | null;
} & ui.UseDisclosureReturn) => {
    const {data, loading} = useRemoteData({
        path: getPath,
        request: {action_id: actionId ?? 0},
        halt: !actionId,
    });

    const [limit, setLimit] = React.useState(50);

    React.useEffect(() => {
        if (props.isOpen) {
            setLimit(50);
        }
    }, [props.isOpen, setLimit]);

    return <ui.Modal size="3xl" {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">操作詳細</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                {(!actionId || !data) && <ui.Box py={10}>
                    {loading && <ui.Spinner />}
                    {!loading && <ui.Text textAlign="center" color="gray.500" size="lg">
                        not found
                    </ui.Text>}
                </ui.Box>}

                {data && <ui.Box>
                    <LayoutItem>
                        <ui.Table>
                            <ui.Tbody>
                                <ui.Tr>
                                    <ui.Th>Id</ui.Th>
                                    <ui.Td>
                                        {data.action.id}
                                    </ui.Td>
                                </ui.Tr>

                                <ui.Tr>
                                    <ui.Th>Time</ui.Th>
                                    <ui.Td>
                                        {moment(data.action.created_at).format('YYYY/MM/DD HH:mm:ss')}
                                    </ui.Td>
                                </ui.Tr>

                                <ui.Tr>
                                    <ui.Th>Type</ui.Th>
                                    <ui.Td>
                                        {actionTypeText[data.action.type]}
                                    </ui.Td>
                                </ui.Tr>

                                <ui.Tr>
                                    <ui.Th>Brand</ui.Th>
                                    <ui.Td>
                                        {data.action.brand}
                                    </ui.Td>
                                </ui.Tr>

                                <ui.Tr>
                                    <ui.Th>Store</ui.Th>
                                    <ui.Td>
                                        {data.action.store?.name ?? '-'}
                                    </ui.Td>
                                </ui.Tr>

                                <ui.Tr>
                                    <ui.Th>Staff</ui.Th>
                                    <ui.Td>
                                        {data.action.staff.name}
                                    </ui.Td>
                                </ui.Tr>

                                <ui.Tr>
                                    <ui.Th>Customer</ui.Th>
                                    <ui.Td>
                                        {data.action.customer_name ? `${data.action.customer_name}様` : '-'}
                                    </ui.Td>
                                </ui.Tr>

                                <ui.Tr>
                                    <ui.Th>Note</ui.Th>
                                    <ui.Td whiteSpace="pre-line">
                                        {data.action.note || '-'}
                                    </ui.Td>
                                </ui.Tr>
                            </ui.Tbody>
                        </ui.Table>
                    </LayoutItem>

                    <LayoutItem>
                        <ui.Heading>
                            明細
                        </ui.Heading>
                    </LayoutItem>

                    <LayoutItem>
                        <ui.Table mt={10}>
                            <ui.Thead>
                                <ui.Tr>
                                    <ui.Th>Image</ui.Th>
                                    <ui.Th>Item</ui.Th>
                                    <ui.Th>Amount</ui.Th>
                                </ui.Tr>
                            </ui.Thead>
                            <ui.Tbody>
                                {data.details.slice(0, limit).map((d) => <ui.Tr key={d.id}>
                                    <ui.Td>
                                        {d.item.product.model.images[0] && <S3Image
                                            base={d.item.product.model.images[0].base_url}
                                            processor="sd256"
                                            maxWidth="80px"
                                        />}
                                    </ui.Td>
                                    <ui.Td>
                                        <ui.Text>
                                            {d.item.product.model.code}
                                        </ui.Text>
                                        {d.item.product.model.code !== d.item.product.domestic_code && <ui.Text>
                                            {d.item.product.domestic_code}
                                        </ui.Text>}
                                        <ui.Text>
                                            {d.item.product.color}
                                        </ui.Text>
                                        <ui.Text>
                                            {d.item.size}
                                        </ui.Text>
                                    </ui.Td>
                                    <ui.Td>
                                        {d.amount}
                                    </ui.Td>
                                </ui.Tr>)}
                            </ui.Tbody>
                        </ui.Table>
                    </LayoutItem>

                    {limit < data.details.length && <LayoutItem>
                        <ui.Button onClick={() => setLimit(l => l + 50)}>
                            Read More
                        </ui.Button>
                    </LayoutItem>}
                </ui.Box>}
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>閉じる</ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>
    </ui.Modal>;
};
