import {React, Router} from 'lib';

export const useSingleQueryValue = (key: string): {
    value: string | null
    push(value: string | null, additional?: Keyed<string | null>): void;
    replace(value: string | null, additional?: Keyed<string | null>): void;
} => {
    const {search} = Router.useLocation();
    const history = Router.useHistory();

    const value = React.useMemo(() => {
        const params = new URLSearchParams(search);
        return params.get(key);
    }, [key, search]);

    return React.useMemo(() => {
        const newQuery = (newValue: string | null, additional?: Keyed<string | null>) => {
            if (value === newValue) {
                return null;
            }
            const params = new URLSearchParams(search);
            params.delete(key);
            if (newValue !== null) {
                params.set(key, newValue);
            }
            Object.entries(additional ?? {}).forEach(([k, v]) => {
                params.delete(k);
                if (v !== null && v !== undefined) {
                    params.set(k, v);
                }
            });
            return params.toString();
        };

        return {
            value,
            push: (newValue: string | null, additional?: Keyed<string | null>) => {
                const search = newQuery(newValue, additional);
                if (search === null) {
                    return
                }
                history.push({search});
            },
            replace: (newValue: string | null, additional?: Keyed<string | null>) => {
                const search = newQuery(newValue, additional);
                if (search === null) {
                    return
                }
                history.replace({search});
            },
        }
    }, [key, value, history, search]);
};


export const useSingleQueryValueAs = <T, >(key: string, cast: {
    to(str: string): T | null;
    from(value: T): string;
}): {
    value: T | null
    push(value: T | null, additional?: Keyed<string | null>): void;
    replace(value: T | null, additional?: Keyed<string | null>): void;
} => {
    const q = useSingleQueryValue(key);
    const ref = React.useRef(cast);
    ref.current = cast;

    return React.useMemo(() => {
        return {
            value: q.value !== null ? ref.current.to(q.value) : null,
            push: (v, a) => q.push(v === null ? null : ref.current.from(v), a),
            replace: (v, a) => q.replace(v === null ? null : ref.current.from(v), a),
        };
    }, [q]);
};


export const useSingleQueryValueAsNumber = (key: string): {
    value: number | null
    push(value: number | null, additional?: Keyed<string | null>): void;
    replace(value: number | null, additional?: Keyed<string | null>): void;
} => {
    return useSingleQueryValueAs<number>(key, {
        to: (v) => {
            const val = parseInt(v);
            return Number.isNaN(val) ? null : val;
        },
        from: String,
    });
};


export const useSingleQueryValueAsBoolean = (key: string): {
    value: boolean | null
    push(value: boolean | null, additional?: Keyed<string | null>): void;
    replace(value: boolean | null, additional?: Keyed<string | null>): void;
} => {
    return useSingleQueryValueAs<boolean>(key, {
        to: (v) => v === '1' ? true : v === '0' ? false : null,
        from: (v) => v ? '1' : '0',
    });
};


export const useSingleQueryValueAsTrue = (key: string): {
    value: true | null
    push(value: true | null, additional?: Keyed<string | null>): void;
    replace(value: true | null, additional?: Keyed<string | null>): void;
} => {
    return useSingleQueryValueAs<true>(key, {
        to: (v) => v === '1' ? true : null,
        from: () => '1',
    });
};


export const useSingleQueryValueAsFalse = (key: string): {
    value: false | null
    push(value: false | null, additional?: Keyed<string | null>): void;
    replace(value: false | null, additional?: Keyed<string | null>): void;
} => {
    return useSingleQueryValueAs<false>(key, {
        to: (v) => v === '0' ? false : null,
        from: () => '0',
    });
};
