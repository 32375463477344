import {Router} from 'lib';

import {AdminFrame} from './base';
import {ActionIndex} from './action';
import {ProductIndex} from './product';
import {UserIndex} from './user';
import {StockIndex} from './stock';
import {StoreIndex} from './store';


export const Admin = () => {
    const {url} = Router.useRouteMatch();
    return <AdminFrame>
        <Router.Switch>
            <Router.Route path={`${url}/stock`}>
                <StockIndex />
            </Router.Route>

            <Router.Route path={`${url}/action`}>
                <ActionIndex />
            </Router.Route>

            <Router.Route path={`${url}/product`}>
                <ProductIndex />
            </Router.Route>

            <Router.Route path={`${url}/store`}>
                <StoreIndex />
            </Router.Route>

            <Router.Route path={`${url}/user`}>
                <UserIndex />
            </Router.Route>
        </Router.Switch>
    </AdminFrame>;
};
