import {React} from 'lib';
import Quagga, {QuaggaJSResultObject, MediaTrackConstraintsWithDeprecated, QuaggaJSConfigObject, QuaggaJSReaderConfig} from '@ericblade/quagga2';


function getMedianOfCodeErrors(decodedCodes: QuaggaJSResultObject['codeResult']['decodedCodes']): number {
    const errors = decodedCodes.filter(x => x.error !== undefined).map(x => x.error as number);
    errors.sort((a, b) => a - b);
    const half = Math.floor(errors.length / 2);
    if (errors.length % 2 === 1) {
        return errors[half];
    }
    return (errors[half - 1] + errors[half]) / 2;
}

const defaultConstraints = {
    width: 640,
    height: 480,
};

const defaultLocatorSettings = {
    patchSize: 'medium',
    halfSample: true,
    debug: {
        showCanvas: false,
    },
};

const defaultDecoders = ['ean_reader', 'ean_8_reader'];


export const Scanner = ({
    onDetected,
    scannerRef,
    onScannerReady,
    constraints = defaultConstraints,
    locator = defaultLocatorSettings,
    numOfWorkers = navigator.hardwareConcurrency || 0,
    decoders = defaultDecoders,
    locate = false,
}: {
    onDetected(code: string): void;
    scannerRef: React.RefObject<HTMLDivElement | null>;
    onScannerReady?(): void;
    constraints?: MediaTrackConstraintsWithDeprecated;
    locator?: QuaggaJSConfigObject['locator'];
    numOfWorkers?: number;
    decoders?: (QuaggaJSReaderConfig | string)[];
    locate?: boolean;
}) => {
    const detected = React.useRef<{[key: string]: number}>({});

    const errorCheck = React.useCallback((result: QuaggaJSResultObject) => {
        const err = getMedianOfCodeErrors(result.codeResult.decodedCodes);
        const code = result.codeResult.code;
        if (err < 0.5 && code) {
            if (code in detected.current) {
                detected.current[code] += 1;
                if (detected.current[code] >= 3) {
                    detected.current = {};
                    onDetected(code);
                }
            } else {
                detected.current[code] = 1;
            }
        }
    }, [onDetected]);

    React.useEffect(() => {
        Quagga.init({
            inputStream: {
                type: 'LiveStream',
                constraints,
                target: scannerRef.current ?? undefined,
            },
            locator,
            numOfWorkers,
            decoder: { readers: decoders },
            locate,
        }, (err) => {
            if (err) {
                return console.log('Error starting Quagga:', err);
            }
            if (scannerRef?.current) {
                Quagga.start();
                if (onScannerReady) {
                    onScannerReady();
                }
            }
        });
        Quagga.onDetected(errorCheck);
        return () => {
            Quagga.offDetected(errorCheck);
            Quagga.stop();
        };
    }, [onDetected, onScannerReady, scannerRef, errorCheck, constraints, locator, numOfWorkers, decoders, locate]);
    return null;
}

