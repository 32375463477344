import {React, ui, moment} from 'lib';

import {useRemoteData, Req, Res, useWriterApi, useForm, FormItem, Textarea, useSingleQueryValueAsNumber, useSingleQueryValueAsTrue, BoolCheckboxInput, ApiErrorDialog, ApiSpinnerDialog, ApiCompletionDialog, LayoutItem} from 'shared';

import {Action} from 'types/elcstockbook/api/data.gen';
import {StaffFrame, useStore, StaffSelect} from './base';
import {ActionDetailRow} from './action/preview';
import {ActionDetailDialog} from './action/detail';
import {useModelDetailDialog, Model} from './model';
import {useQueryValue, ManagedFilter} from './model_filter';

const masterPath = 'staff/get_master_data';

const getWaitingsPath = 'staff/get_waitings';
const notifiedPath = 'staff/notified';
type Waiting = ArrayItem<Res<typeof getWaitingsPath>>;


export function WaitingPage(): React.ReactElement {
    const actionId = useSingleQueryValueAsNumber('action');
    const detailDisclosure = ui.useDisclosure({
        isOpen: actionId.value !== null,
        onClose: () => actionId.replace(null),
    });

    const waitingsRef = React.useRef<Ref<typeof Waitings>>(null);

    const modelDetailDialog = useModelDetailDialog({
        onUpdated: (model) => {
            waitingsRef.current?.updateModel(model);
        },
    });

    return <StaffFrame
        title="入荷連絡"
        hideFloatingContent={modelDetailDialog.isOpen}
    >
        <Waitings ref={waitingsRef} />
        <ActionDetailDialog
            id={actionId.value}
            onUpdated={(action) => {
                waitingsRef.current?.updateAction(action);
            }}
            {...detailDisclosure}
        />
        {modelDetailDialog.dialog}
    </StaffFrame>;
};



const Waitings = React.forwardRef<{
    updateAction(action: Action): void;
    updateModel(model: Model): void;
}, {}>((_props, ref) => {
    const store = useStore();
    const actionId = useSingleQueryValueAsNumber('action');
    const staffId = useSingleQueryValueAsNumber('staff');
    const desc = useSingleQueryValueAsTrue('desc');

    const {data, reload, setData} = useRemoteData({
        path: getWaitingsPath,
        request: {
            store_id: store.id,
            staff_id: staffId.value,
            ascending: !desc.value,
            ...useQueryValue(),
        }
    });

    const {data: master} = useRemoteData({
        path: masterPath,
        request: {
            store_id: store.id,
        },
    });

    React.useImperativeHandle(ref, () => ({
        updateAction: (action: Action) => {
            setData((old) => old.map(w => ({
                ...w,
                action: w.action.id === action.id ? action : w.action,
            })));
        },
        updateModel: (model: Model) => {
            setData((old) => old.map(w => {
                if (w.detail.item.product.model.id === model.id) {
                    const clone = {...w};
                    clone.detail.item.product.model = model;
                    return clone;
                }
                return w;
            }));
        },
    }));

    const [editing, setEditing] = React.useState<Waiting>();
    const disclosure = ui.useDisclosure({
        isOpen: editing !== undefined,
        onClose: () => setEditing(undefined),
    });

    return <ui.Box my={10}>
        <LayoutItem>
            <ui.Heading>
                入荷連絡
            </ui.Heading>
        </LayoutItem>

        {master && <LayoutItem>
            <ui.Stack spacing={5}>
                <ManagedFilter
                    master={master}
                    showGenderFilter={false}
                    showSeasonFilter={false}
                    showCategoryFilter={false}
                    showLineFilter={false}
                    showColorFilter={false}
                    showSizeFilter={false}
                    showSaleFilter={false}
                />

                <StaffSelect
                    value={staffId.value}
                    onChange={(_, v) => staffId.push(v)}
                />

                <BoolCheckboxInput
                    value={desc.value ?? false}
                    onChange={(_, v) => desc.push(v || null)}
                    label="新しい入荷連絡を先頭に表示"
                />
            </ui.Stack>
        </LayoutItem>}

        {data?.length === 0 && <LayoutItem>
            <ui.Text color="gray.500">
                条件に一致する入荷連絡はありません
            </ui.Text>
        </LayoutItem>}

        {data?.map((wait) => {
            const {action} = wait;
            return <LayoutItem key={wait.id}>
                <ui.Box border="1px solid #eee" borderRadius="4px" p={4}>
                    <ui.Flex justifyContent="space-between">
                        <ui.Text fontSize="sm" textDecoration="underline" cursor="pointer" onClick={() => actionId.push(action.id)}>
                            {moment(action.created_at).format('YYYY/MM/DD')}
                        </ui.Text>
                        <ui.Button onClick={() => setEditing(wait)} colorScheme="blue">
                            連絡完了
                        </ui.Button>
                    </ui.Flex>

                    <ui.Flex alignItems="center" mt={2}>
                        <ui.Text fontWeight="bold">
                            {action.customer_name}様
                        </ui.Text>

                        <ui.Text ml={2} fontSize="small">
                            by {action.staff.name}
                        </ui.Text>
                    </ui.Flex>

                    {action.note && <ui.Text mt={2} whiteSpace="pre-line">
                        {action.note}
                    </ui.Text>}

                    <ItemPreview item={wait} />
                </ui.Box>
            </LayoutItem>;
        })}

        <NotifiyActionDialog
            waiting={editing}
            onComplete={() => {
                disclosure.onClose();
                reload();
            }}
            {...disclosure}
        />
    </ui.Box>;
});


const ItemPreview = ({item}: {item: Waiting}) => {
    return <ui.Table>
        <ui.Tbody>
            <ActionDetailRow detail={item.detail} canOpenDetail={true} />
        </ui.Tbody>
    </ui.Table>
};


const NotifiyActionDialog = ({waiting, onComplete, ...props}: {
    waiting: Waiting | undefined;
    onComplete(): void;
} & ui.UseDisclosureReturn) => {
    const store = useStore();
    const api = useWriterApi(notifiedPath);
    const {binder, handleSubmit, reset} = useForm<Req<typeof notifiedPath>, 'waiting_id'>(() => ({
        store_id: store.id,
        waiting_id: waiting?.id,
        note: '',
    }));

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, waiting, reset]);

    const submit = handleSubmit(data => api.call(data));

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">入荷連絡完了</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <FormItem label="Item">
                    {waiting && <ItemPreview item={waiting} />}
                </FormItem>

                <FormItem
                    label="note"
                    keyPath="note"
                    error={api.error?.data}
                >
                    <Textarea
                        {...binder.mapInputProps('note')}
                    />
                </FormItem>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={submit}
                >
                    確定
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiErrorDialog api={api} onOk={api.reset} />
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={() => {
                onComplete();
                reset();
                api.reset();
            }}
        />
    </ui.Modal>;
};
