import {React, ui, icons} from 'lib';

import {useRemoteData, Res, S3Image, Paginator, useSingleQueryValueAsFalse, useSingleQueryValueAsTrue, BoolCheckboxInput} from 'shared';
import {unique} from 'utils';

import {StaffFrame, Menu, useStore, useModelCodeQuery} from './base';
import {useModelDetailDialog, ProductDetailTable, ModelPrices} from './model';
import {useQuery, useQueryValue, Filter, ManagedFilter, TextFilter} from './model_filter';


const masterPath = 'staff/get_master_data';
const modelPath = 'staff/list_models';
type Master = Res<typeof masterPath>;
type Model = ArrayItem<Res<typeof modelPath>['items']>;



export function Main() {
    const store = useStore();
    const query = useQueryValue();
    const filterItems = useSingleQueryValueAsFalse('fi');
    const reversed = useSingleQueryValueAsTrue('reversed');
    const [showDetails, setShowDetails] = React.useState(false);

    const {data: master} = useRemoteData({
        path: masterPath,
        request: {
            store_id: store.id,
        },
    });

    const {data, setData} = useRemoteData({
        path: modelPath,
        request: {
            store_id: store.id,
            filter_items: filterItems.value ?? true,
            reversed: reversed.value ?? false,
            ...query,
        },
    });
    const updateModel = React.useCallback((model: Model) => {
        setData?.((prev) => {
            const {items, ...other} = prev;
            return {
                ...other,
                items: items.map(p => p.id === model.id ? model : p),
            };
        });
    }, [setData]);

    const filterDisclosure = ui.useDisclosure()
    const modelDetailDialog = useModelDetailDialog({
        onUpdated: updateModel,
    });

    const isPc = ui.useBreakpointValue({base: false, xl: true})

    return <StaffFrame
        headerContent={<ui.Stack direction="row" alignItems="center" spacing={3}>
            <ui.Box>
                <Menu />
            </ui.Box>
            <ui.Box flexGrow={1}>
                {data && <MainTextFilter />}
            </ui.Box>
            {!isPc && <ui.Box>
                <ui.Button onClick={filterDisclosure.onOpen}>
                    <icons.SearchIcon />
                </ui.Button>
            </ui.Box>}
        </ui.Stack>}
        hideFloatingContent={modelDetailDialog.isOpen}
        maxW="container.xl"
        centerContent={true}
    >
        <ui.HStack spacing={10} py={10} alignItems="start">
            <ui.Stack width={isPc ? '600px' : undefined} spacing={10}>
                {master && !isPc && <>
                    <ui.Box maxWidth="600px">
                        <ManagedFilter
                            master={master}
                            showTextFilter={false}
                            showLineFilter={showDetails}
                            showColorFilter={showDetails}
                            showSizeFilter={showDetails}
                            showSaleFilter={showDetails}
                            showStockFilter={showDetails}
                            showResetButton={true}
                        />
                    </ui.Box>

                    <ui.Button cursor="pointer" onClick={() => setShowDetails(v => !v)}>
                        詳細な検索条件を{showDetails ? '隠す' : '表示する'}
                    </ui.Button>
                </>}

                {!data && <ui.HStack justifyContent="center">
                    <ui.Spinner
                        speed="1.0s"
                    />
                </ui.HStack>}

                <ui.Stack spacing={5}>
                    {data && <BoolCheckboxInput
                        value={filterItems.value ?? true}
                        onChange={(_, v) => filterItems.push(v && null)}
                        label="条件に一致しないカラー/サイズを隠す"
                    />}
                    {data && <BoolCheckboxInput
                        value={reversed.value ?? false}
                        onChange={(_, v) => reversed.push(v || null)}
                        label="逆順に並べる"
                    />}
                    {data?.items.map((p, i) => <React.Fragment key={i}>
                        <hr />
                        <ModelBox model={p} />
                    </React.Fragment>)}
                </ui.Stack>

                {data && <Paginator {...data} />}
            </ui.Stack>

            {isPc && <ui.Box maxW="400px">
                {master && <ManagedFilter master={master} showTextFilter={false} showResetButton={true} />}
            </ui.Box>}
        </ui.HStack>

        {!isPc && master && <FilterDrawer
            master={master}
            isOpen={filterDisclosure.isOpen}
            onClose={filterDisclosure.onClose}
        />}

        {modelDetailDialog.dialog}
    </StaffFrame>;
}


const MainTextFilter = React.memo((): React.ReactElement => {
    const [value, setValue] = React.useState('');
    const [{text}, setQuery] = useQuery();
    React.useEffect(() => {
        setValue(text);
    }, [text]);
    return <TextFilter
        value={value}
        onChange={setValue}
        onCommit={(text) => setQuery(q => ({...q, text, page: 1}))}
    />;
});


const ModelBox = React.memo(({model}: {
    model: Model;
}) => {
    const modelCode = useModelCodeQuery();
    const openDetail = () => modelCode.push(model.code);

    return <ui.HStack spacing={4} alignItems="start">
            <ui.Box width="120px">
                {model.images[0] && <S3Image
                    base={model.images[0].base_url}
                    processor="sd256"
                    onClick={openDetail}
                    cursor="pointer"
                />}
            </ui.Box>

            <ui.Box flexBasis="100%">
                <ui.Flex justifyContent="space-between" width="100%">
                    <ui.Text fontSize="lg" fontWeight="bold" onClick={openDetail} cursor="pointer">
                        {model.name}
                    </ui.Text>
                    <ui.Box textAlign="right" fontSize="s">
                        <ModelPrices model={model} />
                    </ui.Box>
                </ui.Flex>

                <ui.Flex alignItems="top" justifyContent="space-between" mt={1}>
                    <ui.Text fontSize="sm" onClick={openDetail} cursor="pointer">
                        {unique([model.code, ...model.products.map(p => p.domestic_code).sort()]).map((c, i) => <React.Fragment key={c}>
                            {i > 0 && <br />}{c}
                        </React.Fragment>)}
                    </ui.Text>
                    <ui.Text fontSize="xs">
                        {model.category}
                    </ui.Text>
                </ui.Flex>

                <ui.Stack mt={2}>
                    <ui.Box
                        py={3}
                        width="100%"
                    >
                        <ui.Stack spacing={2}>
                            <ui.Box>
                                <ui.SimpleGrid spacing={6}>
                                    {model.products.map((prod) => <ui.Box key={prod.id}>
                                        <ProductDetailTable
                                            size="sm"
                                            product={prod}
                                            selectable={true}
                                        />
                                    </ui.Box>)}
                                </ui.SimpleGrid>
                            </ui.Box>
                        </ui.Stack>
                    </ui.Box>

                </ui.Stack>
            </ui.Box>
        </ui.HStack>;
});




const FilterDrawer = React.memo(({master, isOpen, onClose}: {
    master: Master;
    isOpen: boolean;
    onClose(): void;
}) => {
    const [value, onChange] = useQuery();

    const [internal, setInternal] = React.useState(value);

    React.useEffect(() => {
        setInternal(value);
    }, [value, setInternal]);

    const change = React.useRef(() => {});
    change.current = () => onChange({...internal});

    React.useEffect(() => {
        const {genders, seasons, lines, categories, colors, sizes} = master;

        setInternal((p) => ({
            ...p,
            genders: genders.filter(l => p.genders.includes(l)),
            seasons: seasons.filter(l => p.seasons.includes(l)),
            categories: categories.filter(c => p.categories.includes(c)),
            lines: lines.filter(l => p.lines.includes(l)),
            colors: colors.filter(c => p.colors.includes(c)),
            sizes: sizes.filter(s => p.sizes.includes(s)),
        }));

        setTimeout(() => {
            change?.current();
        }, 50);
    }, [master, setInternal, change]);

    const commit = () => {
        change?.current();
        onClose();
    };

    return <ui.Drawer onClose={commit} isOpen={isOpen} size="sm" autoFocus={false}>
        <ui.DrawerOverlay>
            <ui.DrawerContent>
                <ui.DrawerCloseButton />
                <ui.DrawerHeader>Filter</ui.DrawerHeader>
                <ui.DrawerBody>
                    <ui.Stack spacing={8}>
                        <Filter
                            master={master}
                            query={internal}
                            setQuery={setInternal}
                        />
                        <ui.Button onClick={commit}>
                            OK
                        </ui.Button>
                    </ui.Stack>
                </ui.DrawerBody>
            </ui.DrawerContent>
        </ui.DrawerOverlay>
    </ui.Drawer>;
});
