import {React} from 'lib';
import {isFunction} from 'utils';

import {Store} from 'types/elcstockbook/api/data.gen'

const makeStorageKey = (store: Store) => `_selection_state_${store.code}`;

type State = {
    store: Store;
    items: Keyed<number>;
};

const SelectionStateContext = React.createContext<[
    State,
    React.Dispatch<Action>,
] | null>(null);

const useSelectionState = (): [State, React.Dispatch<Action>] => {
    return React.useContext(SelectionStateContext)!;
};

const removeZero = (items: State['items']): void => {
    Object.entries(items).forEach(([k, v]) => {
        if (v === 0) {
            delete items[k];
        }
    });
};


type Storage = {
    setItem?(key: string, value: string): void;
    getItem(key: string): string | null;
};


type Action = {
    type: 'reset',
    store: Store,
} | {
    type: 'toggle_item',
    barcode: string;
    selected?: number;
    keepZero: boolean;
} | {
    type: 'clear_zero',
} | {
    type: 'reset_items',
    barcodes: string[] | undefined;
};

const useReducer = (storage: Storage) => {
    const initState = useInitState(storage);
    return React.useCallback((state: State, action: Action): State => {
        let newState: State;
        if (action.type === 'reset') {
            return initState(action.store);
        } else if (action.type === 'toggle_item') {
            const items = {...state.items};
            const {barcode, selected, keepZero} = action;
            const newValue = selected === undefined
                ? (barcode in items ? 0 : 1)
                : selected;
            if (newValue === 0 && !keepZero) {
                delete items[barcode];
            } else {
                items[barcode] = newValue;
            }
            newState = {
                ...state,
                items,
            };
        } else if (action.type === 'clear_zero') {
            const items = {...state.items};
            removeZero(items);
            newState = {
                ...state,
                items,
            };
        } else if (action.type === 'reset_items') {
            if (action.barcodes === undefined) {
                newState = {
                    ...state,
                    items: {},
                };
            } else {
                const items = {...state.items};
                action.barcodes.forEach((k) => delete items[k]);
                newState = {
                    ...state,
                    items,
                };
            }
        } else {
            throw new Error();
        }

        storage.setItem?.(makeStorageKey(state.store), JSON.stringify(newState.items));
        return newState;
    }, [storage, initState]);
}

const useInitState = (storage: Storage) => {
    return React.useCallback((store: Store): State => {
        return {
            items: JSON.parse(storage.getItem(makeStorageKey(store)) || '{}'),
            store,
        } as State;
    }, [storage]);
};

export const SelectionStateProvider = ({children, store, storage = localStorage}: {
    children: React.ReactNode;
    store: Store;
    storage?: Storage;
}): React.ReactElement => {
    const reducer = useReducer(storage);
    const initState = useInitState(storage);
    const [state, dispatch] = React.useReducer(reducer, store, initState);
    React.useEffect(() => {
        dispatch({type: 'reset', store});
    }, [dispatch, store]);

    return <SelectionStateContext.Provider
        value={[state, dispatch]}
        children={children}
    />
};


export const useSelected = (barcode: string): [
    cur: number,
    toggle: {(selected?: number | {(cur: number): number}, keepZero?: boolean): void},
] => {
    const [{items}, dispatch] = useSelectionState();
    const selected = items[barcode] ?? 0;
    return React.useMemo(() => [
        selected,
        (newValue, keepZero) => dispatch({
            type: 'toggle_item',
            barcode,
            selected: isFunction(newValue) ? newValue(selected) : newValue,
            keepZero: keepZero ?? false,
        }),
    ], [selected, barcode, dispatch]);
};


export const useSelectedItems = (keepZero?: boolean): [barcode: string, amount: number][] => {
    const state = useSelectionState()[0].items;
    return Object.entries(state)
    .filter(([_, v]) => keepZero || v)
    .map(([k, v]) => [k, v]);
};


export const useClearZeroSelected = (): {(): void} => {
    const dispatch = useSelectionState()[1];
    return React.useCallback(() => dispatch({type: 'clear_zero'}), [dispatch]);
};


export const useResetSelectedItems = (): {(barcodes?: string[]): void} => {
    const dispatch = useSelectionState()[1];
    return React.useCallback((barcodes?: string[]) => dispatch({type: 'reset_items', barcodes}), [dispatch]);
};
