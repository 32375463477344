import {React, Router, ui, icons} from 'lib';

import {Req, Res, Gender, genderText, BoolCheckboxInput, RadioInput, Choice} from 'shared';
import {equals, isFunction, replaceQuery} from 'utils';



const masterPath = 'staff/get_master_data';
const modelPath = 'staff/list_models';
type ModelReq = Req<typeof modelPath>;

type QueryArrayKeys = 'text' | 'genders' | 'seasons' | 'categories' | 'lines' | 'colors' | 'sizes';
export type Query = Omit<ModelReq, 'store_id' | QueryArrayKeys> & {
    [K in QueryArrayKeys]-?: NonNullable<ModelReq[K]>;
};
export type SetQuery = {(query: Query | {(prev: Query): Query}, maintainPage?: boolean): void};

const toQuery = (search: string): Query => {
    const params = new URLSearchParams(search);
    const sale = params.get('sale');
    const toBool = (s: string | null): boolean | null => s === '1' ? true : s === '0' ? false : null;
    return {
        page: parseInt(params.get('page') ?? '') || 1,
        text: params.get('q') || '',
        genders: params.getAll('g').filter(g => g in genderText) as Gender[],
        seasons: params.getAll('s'),
        categories: params.getAll('ca'),
        lines: params.getAll('l'),
        colors: params.getAll('co'),
        sizes: params.getAll('sz'),
        sale: sale ? sale !== '0' : null,
        and_stock_filter: toBool(params.get('and')) ?? false,
        stock_amount: toBool(params.get('st')),
        warehouse_stock_amount: toBool(params.get('ws')),
        warehouse_requested_amount: toBool(params.get('wr')),
    };
};

export const useQueryValue = (): Query => {
    const {search} = Router.useLocation();
    return React.useMemo(() => toQuery(search), [search]);
};

export const useSetQuery = (): SetQuery => {
    const history = Router.useHistory();

    return React.useCallback((_q, maintainPage?) => {
        const prev = toQuery(window.location.search);
        const q = isFunction(_q) ? _q(prev) : _q;
        if (equals(prev, q)) {
            return;
        }
        const fromBool = (b: boolean | null | undefined): string | null => b ? '1' : b === false ? '0' : null;
        history.push({
            search: replaceQuery(window.location.search, {
                page: !maintainPage || q.page === 1 ? null : q.page,
                q: q.text || null,
                g: q.genders,
                s: q.seasons,
                ca: q.categories,
                l: q.lines,
                co: q.colors,
                sz: q.sizes,
                sale: q.sale,
                and: fromBool(q.and_stock_filter || null),
                st: fromBool(q.stock_amount),
                ws: fromBool(q.warehouse_stock_amount),
                wr: fromBool(q.warehouse_requested_amount),
            }),
        });
    }, [history]);
};


export const useQuery = (): [Query, SetQuery] => {
    return [useQueryValue(), useSetQuery()];
};


export const useResetQuery = (): {(): void} => {
    const setQuery = useSetQuery();
    return React.useCallback(() => {
        return setQuery(toQuery(''));
    }, [setQuery]);
};


export const ManagedFilter = (props: Excluded<Props<typeof Filter>, 'query' | 'setQuery'>) => {
    const [query, setQuery] = useQuery();
    return <Filter {...props} query={query} setQuery={setQuery} />
};

export const Filter = ({master, query, setQuery, ...filters}: {
    showTextFilter?: boolean;
    showGenderFilter?: boolean;
    showSeasonFilter?: boolean;
    showCategoryFilter?: boolean;
    showLineFilter?: boolean;
    showColorFilter?: boolean;
    showSizeFilter?: boolean;
    showSaleFilter?: boolean;
    showStockFilter?: boolean;
    showResetButton?: boolean;
    master: Res<typeof masterPath>;
    query: Query;
    setQuery: SetQuery;
}) => {
    const {
        showTextFilter = true,
        showGenderFilter = true,
        showSeasonFilter = true,
        showCategoryFilter = true,
        showLineFilter = true,
        showColorFilter = true,
        showSizeFilter = true,
        showSaleFilter = true,
        showStockFilter = true,
        showResetButton,
    } = filters;

    const [text, setText] = React.useState(query.text ?? '');

    const genderFilter = useFilters(
        query.genders ?? [],
        (genders: Gender[]) => setQuery(q => ({...q, genders})),
        (master?.genders ?? []).map((g) => ({value: g, label: genderText[g]})))

    const seasonFilter = useFilters(
        query.seasons ?? [],
        (seasons: string[]) => setQuery(q => ({...q, seasons})),
        (master?.seasons ?? []).map((s) => ({value: s, label: s})))

    const categoryFilter = useFilters(
        query.categories,
        (categories: string[]) => setQuery(q => ({...q, categories})),
        (master?.categories ?? []).map(c => ({value: c, label: c})));

    const lineFilter = useFilters(
        query.lines ?? [],
        (lines: string[]) => setQuery(q => ({...q, lines})),
        (master?.lines ?? []).map((s) => ({value: s, label: s})))

    const colorFilter = useFilters(
        query.colors ?? [],
        (colors: string[]) => setQuery(q => ({...q, colors})),
        (master?.colors ?? []).map((s) => ({value: s, label: s})))

    const sizeFilter = useFilters(
        query.sizes ?? [],
        (sizes: string[]) => setQuery(q => ({...q, sizes})),
        (master?.sizes ?? []).map((s) => ({value: s, label: s})))

    const makeStockChoices = (kind: string) => [
        {value: null, label: '指定なし'},
        {value: true, label: `${kind}あり`},
        {value: false, label: `${kind}なし`},
    ];

    return <ui.Stack spacing={6}>
        {showResetButton && <ui.Box>
            <ui.Button colorScheme="red" size="sm" onClick={() => setQuery(toQuery(''))}>
                検索条件をリセットする
            </ui.Button>
        </ui.Box>}

        {showTextFilter && <TextFilter value={text} onChange={setText} onCommit={(text) => setQuery(q => ({...q, text}))}  />}
        {showGenderFilter && <CheckboxFilter {...genderFilter} />}
        {showSeasonFilter && <CheckboxFilter title="Season" needsMultipleChoices={true} {...seasonFilter} />}
        {showCategoryFilter && <CheckboxFilter title="Category" {...categoryFilter} />}

        {showLineFilter && <CheckboxFilter title="Line" {...lineFilter} />}
        {showColorFilter && <CheckboxFilter title="Color" {...colorFilter} />}
        {showSizeFilter && <CheckboxFilter title="Size" {...sizeFilter} />}

        {showSaleFilter && master?.has_sale && <BoolCheckboxInput
            label="Sale"
            value={!!query.sale}
            onChange={(_, v) => setQuery((old) => ({
                ...old,
                sale: v || null,
            }))}
        />}

        {showStockFilter && <ui.Box>
            <RadioInput
                value={query.and_stock_filter}
                choices={[
                    {value: false, label: '下記のいずれかを満たす'},
                    {value: true, label: '下記のすべてを満たす'},
                ]}
                onChange={(_, v) => setQuery(q => ({...q, page: 1, and_stock_filter: v}))}
            />

            <RadioInput
                value={query.stock_amount ?? null}
                choices={makeStockChoices('店頭在庫')}
                onChange={(_, v) => setQuery(q => ({...q, page: 1, stock_amount: v}))}
            />

            <RadioInput
                value={query.warehouse_stock_amount ?? null}
                choices={makeStockChoices('倉庫在庫')}
                onChange={(_, v) => setQuery(q => ({...q, page: 1, warehouse_stock_amount: v}))}
            />

            <RadioInput
                value={query.warehouse_requested_amount ?? null}
                choices={makeStockChoices('出荷依頼')}
                onChange={(_, v) => setQuery(q => ({...q, page: 1, warehouse_requested_amount: v}))}
            />
        </ui.Box>}
    </ui.Stack>
};

export const useFilters = <V extends string | number>(selected: V[], setSelected: {(selected: V[]): void}, choices: Choice<V>[]): {
    choices: Choice<V>[];
    isSelected(value: V): boolean;
    toggle(value: V): void;
} => {
    const toggle = React.useCallback((v: V) => {
        setSelected(selected.includes(v)
            ? selected.filter(_v => _v !== v)
            : selected.concat([v]));
    }, [selected, setSelected]);

    const isSelected = React.useCallback((v: V) => selected.includes(v), [selected]);

    return React.useMemo(
        () => ({choices, selected, setSelected, isSelected, toggle}),
        [choices, selected, setSelected, isSelected, toggle]);
};

export const CheckboxFilter = ({title, needsMultipleChoices, choices, ...props}: {
    title?: string;
    needsMultipleChoices?: boolean;
} & ReturnType<typeof useFilters>) => {
    const [collapsed, setCollapsed] = React.useState(!!title);
    if (choices.length === 0 || (needsMultipleChoices && choices.length === 1)) {
        return null;
    }
    return <ui.Box>
        {title && <ui.Text cursor="pointer" onClick={() => setCollapsed(c => !c)} borderBottom="1px solid #ddd" pb={1} mb={1}>
            {title}
            <ui.Box display="inline" float="right">
                {collapsed ? <icons.ChevronUpIcon /> : <icons.ChevronDownIcon />}
            </ui.Box>
        </ui.Text>}
        {!collapsed && <ui.Wrap>
            {choices.map((c) => <ui.WrapItem key={c.value}>
                <ui.Checkbox
                    isChecked={props.isSelected(c.value)}
                    onChange={() => props.toggle(c.value)}
                    colorScheme="blackAlpha"
                >
                    {c.label}
                </ui.Checkbox>
            </ui.WrapItem>)}
        </ui.Wrap>}
    </ui.Box>
};

export const TextFilter = ({value, onChange, onCommit}: {
    value: string;
    onChange(value: string): void;
    onCommit?(value: string): void;
}) => {
    return <ui.InputGroup>
        <ui.InputLeftElement
            pointerEvents="none"
            children={<icons.SearchIcon color="gray.300" />}
        />
        <ui.Input
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onBlur={() => onCommit?.(value)}
            onKeyPress={(e) => {
                if (onCommit && e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    onCommit?.(value);
                }
            }}
        />
        {value.length > 0 && <ui.InputRightElement>
            <icons.CloseIcon
                onClick={() => {
                    onChange('');
                    onCommit?.('');
                }}
                cursor="pointer"
                color="gray.300"
            />
        </ui.InputRightElement>}
    </ui.InputGroup>
};
