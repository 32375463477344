import {React, ui, icons} from 'lib';
import {useRemoteData, Brand, S3ImagesCarousel, S3Image, useCarousel} from 'shared';
import {formatComma, addTax} from 'utils';


export function Detail({code, brand}: {
    brand: Brand;
    code: string;
}) {
    const {data} = useRemoteData({
        path: 'public/list_models',
        request: {code}
    });
    const p = data?.items[0];

    type Item = ArrayItem<NonNullable<typeof p>['items']>;
    type Color = Item['color'];
    type Store = Item['store'];

    const d = (p?.items ?? []).reduce((acc, item) => {
        if (!(item.store.id in acc.stores)) {
            acc.stores[item.store.id] = {
                store: item.store,
                colors: {},
            };
        }
        if (!(item.color.color in acc.stores[item.store.id].colors)) {
            acc.stores[item.store.id].colors[item.color.color] = item.color;
        }
        if (!(item.domestic_code in acc.dcodes)) {
            acc.dcodes[item.domestic_code] = item.domestic_code;
        }
        return acc;
    }, {
        stores: {} as Keyed<{
            store: Store;
            colors: Keyed<Color>;
        }>,
        dcodes: {} as Keyed<string>,
    });
    const {dcodes} = d;
    const stores = Object.values(d.stores).map(({store, colors}) => ({
        store,
        colors: Object.values(colors),
    }));

    const carousel = useCarousel();

    const contactDisclosure = ui.useDisclosure();
    const storesHasContact = stores.filter(({store: s}) => s.email || s.tel || s.line).map(({store}) => store);
    const hasLineContact = stores.some(s => !!s.store.line);
    const contactMessage = encodeURIComponent(`問い合わせ：${p?.code}`);

    const makeLineUrl = (line: string): string => {
        if (line.match(/https?:\/\/.*/)) {
            return line;
        }
        return `https://line.me/R/oaMessage/${line}/?${contactMessage}`;
    };

    return <>
        {data?.items.length === 0 && <ui.Box py={40}>
            <ui.Text fontSize="2xl" textAlign="center" fontWeight="bold" color="gray.700">
                NOT FOUND
            </ui.Text>
        </ui.Box>}

        {p && <>
            {p.images.length > 0 && <ui.Box
                mt={{base: 10, sm: 20}}
                mx="-16px"
            >
                <S3ImagesCarousel
                    images={p.images}
                    {...carousel}
                />
            </ui.Box>}

            <ui.Box maxWidth="500px" mx="auto" py={10}>
                <ui.Heading textAlign="center">
                    {p.name}
                </ui.Heading>
                <ui.Text fontSize="lg" mt={3} textAlign="center">
                    {p.code}

                    {brand.attrname !== 'rickowens' && Object.keys(dcodes).sort().map(c => c !== p.code && <React.Fragment key={c}>
                        <br />{c}
                    </React.Fragment>)}
                </ui.Text>

                <ui.Text mt={2} textAlign="center">
                    ¥{formatComma(addTax(p.price))}
                </ui.Text>

                <ui.VStack my={6} spacing={6}>
                    {stores.map(({store, colors}) => <ui.Box key={store.id}>
                        <ui.Text fontSize="xs" fontWeight="bold" textAlign="center">
                            {store.name}
                        </ui.Text>

                        <ui.HStack mt={2} justifyContent="center" alignItems="end" spacing={6}>
                            {colors.map((color) => <ui.Box key={color.color} align="center">
                                {color.image && <ui.AspectRatio ratio={1} maxWidth="100%" width="64px">
                                    <S3Image
                                        base={color.image?.base_url}
                                        placeholder=""
                                        processor="sd128"
                                        maxWidth="100%"
                                        width="64px"
                                    />
                                </ui.AspectRatio>}
                                <ui.Text size="sm">
                                    {color.color}
                                </ui.Text>
                            </ui.Box>)}
                        </ui.HStack>
                    </ui.Box>)}
                </ui.VStack>

                {storesHasContact.length > 0 && <ui.Text
                    mt={4}
                    textAlign="center"
                    cursor="pointer"
                    textDecoration="underline"
                    onClick={contactDisclosure.onOpen}
                >
                    <icons.EmailIcon /> Contact
                </ui.Text>}
            </ui.Box>
        </>}

        <ui.Modal {...contactDisclosure}>
            <ui.ModalOverlay />
            <ui.ModalContent>
                <ui.ModalHeader textAlign="center">
                    <icons.EmailIcon /> Contact
                </ui.ModalHeader>
                <ui.ModalCloseButton />

                <ui.ModalBody>
                    {storesHasContact.map((s, i) => <ui.Box key={s.id} mt={i === 0 ? 10 : 20}>
                        <ui.VStack spacing={5} align="left">
                            <ui.Heading fontWeight="normal" size="md" alignSelf="center">{s.name}</ui.Heading>

                            {s.email && <ui.Text>
                                <ui.chakra.span fontSize="xs" width="50px" display="inline-block">EMAIL</ui.chakra.span>
                                <ui.Link
                                    href={`mailto:${s.email}?subject=${contactMessage}`}
                                    target="_blank"
                                    fontWeight="bold"
                                >
                                    {s.email}
                                </ui.Link>
                            </ui.Text>}

                            {s.tel && <ui.Text>
                                <ui.chakra.span fontSize="xs" width="50px" display="inline-block">TEL</ui.chakra.span>
                                <ui.Link
                                    href={`tel:${s.tel}`}
                                    target="_blank"
                                    fontWeight="bold"
                                >
                                    {s.tel}
                                </ui.Link>
                            </ui.Text>}

                            {s.line && <ui.Text>
                                <ui.chakra.span fontSize="xs" width="50px" display="inline-block">LINE</ui.chakra.span>
                                <ui.Link
                                    href={makeLineUrl(s.line)}
                                    target="_blank"
                                    fontWeight="bold"
                                >
                                    {s.line.split('/').pop() ?? s.line}
                                </ui.Link>
                            </ui.Text>}
                        </ui.VStack>
                    </ui.Box>)}

                    {hasLineContact && <ui.Text mt={20} fontSize="sm" color="gray.600">
                        iPhoneまたはAndroidからのみLINEでのお問合せがご利用できます。
                    </ui.Text>}
                </ui.ModalBody>

                <ui.ModalFooter>
                    <ui.Button onClick={contactDisclosure.onClose}>
                        Close
                    </ui.Button>
                </ui.ModalFooter>
            </ui.ModalContent>
        </ui.Modal>
    </>;
}
