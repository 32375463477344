import {React, Router, ui} from 'lib';

import {Paging} from 'types/elcstockbook/models/dataclasses.gen'


export const usePage = (): number => {
    const {search} = Router.useLocation();
    return React.useMemo(() => {
        const params = new URLSearchParams(search);
        return parseInt(params.get('page') ?? '') || 1;
    }, [search]);
};


export const usePager = (): {
    page: number;
    set(page: number): void;
} => {
    const {search} = Router.useLocation();
    const history = Router.useHistory();
    const page = usePage();
    return React.useMemo(() => ({
        page,
        set: (newPage: number) => {
            if (page === newPage) {
                return;
            }
            const params = new URLSearchParams(search);
            params.delete('page');
            if (newPage > 1) {
                params.set('page', String(newPage));
            }

            history.push({
                search: params.toString(),
            });
        },
    }), [page, history, search]);
};


export const Paginator = (props: Paging<unknown>): React.ReactElement | null => {
    const {total_count: count, page_size: size} = props;
    const {page, set} = usePager();
    const max = Math.ceil(count / size);
    if (count === 0) {
        return null;
    }
    return <ui.Box>
        <ui.Center>
        <ui.HStack>
            <ui.Button
                isDisabled={page <= 1}
                onClick={() => set(1)}
            >
                First
            </ui.Button>

            <ui.Button
                isDisabled={page <= 1}
                onClick={() => set(page - 1)}
            >
                Prev
            </ui.Button>

            <ui.HStack alignItems="flex-end" spacing={1}>
                <ui.Text fontWeight="bold" fontSize="lg" lineHeight="20px">{page}</ui.Text>
                <ui.Text fontSize="xs">/</ui.Text>
                <ui.Text fontSize="xs">{max}</ui.Text>
            </ui.HStack>

            <ui.Button
                isDisabled={page >= max}
                onClick={() => set(page + 1)}
            >
                Next
            </ui.Button>

            <ui.Button
                isDisabled={page >= max}
                onClick={() => set(max)}
            >
                Last
            </ui.Button>
        </ui.HStack>
    </ui.Center>
    </ui.Box>
}
