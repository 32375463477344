import {React, ui} from 'lib';

import {S3Image} from 'shared';
import {useBarcodeQuery} from 'staff/base';
import {ActionDetail} from 'types/elcstockbook/api/data.gen';


export const ActionDetailRow = ({detail, canOpenDetail}: {
    detail: ActionDetail;
    canOpenDetail?: boolean;
}): React.ReactElement => {
    const barcodeQuery = useBarcodeQuery();
    return <ui.Tr key={detail.id}>
        <ui.Td px={0} width="60px">
            {detail.item.product.model.images[0] && <S3Image
                base={detail.item.product.model.images[0].base_url}
                processor="sd256"
                maxWidth="60px"
                {...(canOpenDetail ? {
                    onClick: () => barcodeQuery.push(detail.item.barcode),
                    cursor: 'pointer',
                } : {})}
            />}
        </ui.Td>
        <ui.Td pl={4} pr={0}>
            <ui.Text
                fontSize="sm"
                {...(canOpenDetail ? {
                    onClick: () => barcodeQuery.push(detail.item.barcode),
                    cursor: 'pointer',
                    decoration: 'underline',
                } : {})}
            >
                {detail.item.product.model.code}
            </ui.Text>
            {detail.item.product.model.code !== detail.item.product.domestic_code && <ui.Text fontSize="sm">
                {detail.item.product.domestic_code}
            </ui.Text>}
            <ui.Text fontSize="sm">
                {detail.item.product.color}
            </ui.Text>
            <ui.Text fontSize="sm">
                {detail.item.size}
            </ui.Text>

            <ui.Text mt={2} fontSize="sm">
                x {detail.amount}
            </ui.Text>
        </ui.Td>
    </ui.Tr>;
};
