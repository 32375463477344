import {Router} from 'lib';

import {SelectionStateProvider, BaseUrlProvider} from 'shared';

import {CurrentStoreRequired} from './base';
import {Main} from './main';
import {ActionIndex} from './action';
import {Bulk} from './bulk';
import {Sold} from './sold';
import {LeaseRepair} from './lease_repair';
import {KeepPage} from './keep';
import {WaitingPage} from './waiting';


export const Staff = () => {
    return <BaseUrlProvider>
        {url => <CurrentStoreRequired>
            {store => <SelectionStateProvider store={store}>
                <Router.Switch>
                    <Router.Route path={`${url}/action`}>
                        <ActionIndex />
                    </Router.Route>

                    <Router.Route path={`${url}/bulk`}>
                        <Bulk />
                    </Router.Route>

                    <Router.Route path={`${url}/lease_repair`}>
                        <LeaseRepair />
                    </Router.Route>

                    <Router.Route path={`${url}/keep`}>
                        <KeepPage />
                    </Router.Route>

                    <Router.Route path={`${url}/waiting`}>
                        <WaitingPage />
                    </Router.Route>

                    <Router.Route path={`${url}/sold`}>
                        <Sold />
                    </Router.Route>

                    <Router.Route path={url}>
                        <Main />
                    </Router.Route>
                </Router.Switch>
            </SelectionStateProvider>}
        </CurrentStoreRequired>}
    </BaseUrlProvider>;
};
