import {React, ui} from 'lib';

import {useSession} from './api';


export const useSignoutDialog = (): {
    dialog: React.ReactElement;
} & ui.UseDisclosureReturn => {
    const signout = ui.useDisclosure();
    return React.useMemo(() => ({
        ...signout,
        dialog: <SignoutDialog {...signout} />,
    }), [signout]);
};


const SignoutDialog = ({isOpen, onClose}: {
    isOpen: boolean;
    onClose(): void;
}) => {
    const {signout} = useSession();
    const cancelRef = React.useRef<HTMLButtonElement>(null)
    return <ui.AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
    >
        <ui.AlertDialogOverlay />

        <ui.AlertDialogContent>
            <ui.AlertDialogHeader>Do you want to sign out?</ui.AlertDialogHeader>
            <ui.AlertDialogCloseButton />
            <ui.AlertDialogFooter>
                <ui.Button ref={cancelRef} onClick={onClose}>
                    No
                </ui.Button>
                <ui.Button colorScheme="red" ml={3} onClick={signout}>
                    Yes
                </ui.Button>
            </ui.AlertDialogFooter>
        </ui.AlertDialogContent>
    </ui.AlertDialog>;
};
