import {React, ui, moment} from 'lib';

import {useRemoteData, LayoutItem, actionTypeText, useDownloadAction} from 'shared';

import {Action} from 'types/elcstockbook/api/data.gen';
import {useStore} from '../base';
import {ActionDetailRow} from './preview';
import {ActionEditDialog} from './edit';

const getPath = 'staff/action/get';


export const ActionDetailDialog = ({id: actionId, onUpdated, ...props}: {
    id: number | null;
    onUpdated(action: Action): void;
} & ui.UseDisclosureReturn) => {
    const store = useStore();
    const {data, loading, setData} = useRemoteData({
        path: getPath,
        request: {store_id: store.id, action_id: actionId ?? 0},
        halt: !actionId,
    });

    const [limit, setLimit] = React.useState(50);
    const download = useDownloadAction();

    React.useEffect(() => {
        if (props.isOpen) {
            setLimit(50);
        }
    }, [props.isOpen, setLimit]);

    const editDisclosure = ui.useDisclosure();

    return <ui.Modal size="3xl" {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">操作詳細</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                {(!actionId || !data) && <ui.Box py={10}>
                    {loading && <ui.Spinner />}
                    {!loading && <ui.Text textAlign="center" color="gray.500" size="lg">
                        not found
                    </ui.Text>}
                </ui.Box>}

                {data && <ui.Box>
                    <LayoutItem>
                        <ui.Table size="sm">
                            <ui.Tbody>
                                <ui.Tr>
                                    <ui.Th px={0}>Id</ui.Th>
                                    <ui.Td>
                                        {data.action.id}
                                    </ui.Td>
                                </ui.Tr>

                                <ui.Tr>
                                    <ui.Th px={0}>Time</ui.Th>
                                    <ui.Td>
                                        {moment(data.action.created_at).format('YYYY/MM/DD HH:mm:ss')}
                                    </ui.Td>
                                </ui.Tr>

                                <ui.Tr>
                                    <ui.Th px={0}>Type</ui.Th>
                                    <ui.Td>
                                        {actionTypeText[data.action.type]}
                                    </ui.Td>
                                </ui.Tr>

                                <ui.Tr>
                                    <ui.Th px={0}>Store</ui.Th>
                                    <ui.Td>
                                        {data.action.store?.name ?? '-'}
                                    </ui.Td>
                                </ui.Tr>

                                <ui.Tr>
                                    <ui.Th px={0}>Staff</ui.Th>
                                    <ui.Td>
                                        {data.action.staff.name}
                                    </ui.Td>
                                </ui.Tr>

                                <ui.Tr>
                                    <ui.Th px={0}>Customer</ui.Th>
                                    <ui.Td>
                                        {data.action.customer_name ? `${data.action.customer_name}様` : '-'}
                                    </ui.Td>
                                </ui.Tr>

                                <ui.Tr>
                                    <ui.Th px={0}>Note</ui.Th>
                                    <ui.Td whiteSpace="pre-line">
                                        {data.action.note || '-'}
                                    </ui.Td>
                                </ui.Tr>
                            </ui.Tbody>
                        </ui.Table>
                    </LayoutItem>

                    {data.action.store && <LayoutItem>
                        <ui.Button onClick={() => editDisclosure.onOpen()}>
                            編集
                        </ui.Button>
                    </LayoutItem>}

                    <LayoutItem>
                        <ui.Heading>
                            明細
                        </ui.Heading>
                    </LayoutItem>

                    <LayoutItem>
                        <ui.Table>
                            <ui.Tbody>
                                {data.details.slice(0, limit).map((d) => <ActionDetailRow
                                    key={d.id}
                                    detail={d}
                                />)}
                            </ui.Tbody>
                        </ui.Table>
                    </LayoutItem>

                    {limit < data.details.length && <LayoutItem>
                        <ui.HStack>
                            <ui.Text>
                                他{data.details.length - limit}件
                            </ui.Text>
                            <ui.Button onClick={() => setLimit(l => l + 50)}>
                                Read More
                            </ui.Button>
                        </ui.HStack>
                    </LayoutItem>}
                </ui.Box>}
            </ui.ModalBody>

            <ui.ModalFooter>
                {data && <ui.Button colorScheme="blue" onClick={() => download(data)} mr={3}>ダウンロード</ui.Button>}
                <ui.Button onClick={props.onClose}>閉じる</ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        {data && <ActionEditDialog
            action={data.action}
            onComplete={(action) => {
                setData({...data, action});
                onUpdated(action);
                editDisclosure.onClose();
            }}
            {...editDisclosure}
        />}
    </ui.Modal>;
};
