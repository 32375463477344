import {React, Router, ui, xlsx, moment} from 'lib';

import {unique, makeComparator} from 'utils';
import {S3Image, useRemoteData, useWriterApi, useReaderApi, Req, Res, ApiErrorDialog, ApiSpinnerDialog, ApiCompletionDialog, ConfirmationDialog, LayoutItem, useForm, FormItem, useInputProps, Input, RadioInput, CheckboxInput, ImageInput, imgurl, InputProps, Paginator, usePage, SearchTextInput, FileInput, Textarea, genderText, itemToMasterRow, AlertDialog, Brand, brands} from 'shared';
import {Store, NestedItem} from 'types/elcstockbook/api/data.gen';

import {TransactionDataInput} from 'admin/base';


const getData = 'admin/get_data';
const listModels = 'admin/product/list_models';
const listItems = 'admin/product/list_items';
const importMaster = 'admin/product/import_items';
const parseMaster = 'admin/product/parse_items';
const importOrder = 'admin/product/import_order';
const importImages = 'admin/product/import_images';
const listColors = 'admin/color/list';
const createColor = 'admin/color/create';
const updateColor = 'admin/color/update';
const deleteColor = 'admin/color/delete';

type Color = ArrayItem<Res<typeof listColors>['items']>;


export const ProductIndex = (): React.ReactElement => {
    const location = Router.useLocation();
    const history = Router.useHistory();
    const {url} = Router.useRouteMatch();

    const list = `${url}`;
    const imprt = `${url}/import`;
    const color = `${url}/color`;
    const tabs = [list, imprt, color];
    const index = tabs.indexOf(location.pathname)

    return <ui.Tabs index={index} onChange={i => history.push(tabs[i])}>
        <ui.TabList>
            <ui.Tab>商品一覧</ui.Tab>
            <ui.Tab>インポート</ui.Tab>
            <ui.Tab>色</ui.Tab>
        </ui.TabList>

        <ui.TabPanels>
            {tabs.map((_, i) => <ui.TabPanel key={i}>
                {i === index && <Router.Switch>
                    <Router.Route path={imprt}>
                        <ImportTab />
                    </Router.Route>
                    <Router.Route path={color}>
                        <ColorTab />
                    </Router.Route>
                    <Router.Route path={list}>
                        <ProductListTab />
                    </Router.Route>
                </Router.Switch>}
            </ui.TabPanel>)}
        </ui.TabPanels>
    </ui.Tabs>;
}


const ProductListTab = () => {
    const {data} = useRemoteData({
        path: getData,
        request: {},
    });

    if (!data) {
        return <ui.Spinner />;
    }

    return <ListImpl data={data} />;
}


const ListImpl = ({data}: {data: Res<typeof getData>}) => {
    const [itemsView, setItemsView] = React.useState(false);

    const {binder} = useForm<Req<typeof listModels>>(() => ({
        text: '',
        ongoing: true,
        'public': null,
        brand: null,
        store_id: null,
        seasons: [],
    }));

    const brand = useInputProps(binder.bind('brand'), {
        sideEffect: () => ({store_id: null}),
    });

    const ongoingChoices = React.useMemo(() => {
        return [
            {value: null, label: '指定なし'},
            {value: true, label: '非表示を除く'},
            {value: false, label: '非表示のみ'},
        ];
    }, []);

    const publicChoices = React.useMemo(() => {
        return [
            {value: null, label: '指定なし'},
            {value: true, label: '顧客に公開'},
            {value: false, label: '顧客に非公開'},
        ];
    }, []);

    const storeChoices = React.useMemo(() => {
        return [
            {value: null, label: '店舗指定なし'},
            ...data.stores
            .filter(s => s.brand.value === brand.value)
            .map(s => ({value: s.id, label: s.name})),
        ];
    }, [data, brand.value]);

    const {data: models, loading: modelsLoading} = useRemoteData({
        path: listModels,
        request: {
            ...binder.value,
            page: usePage(),
        },
        halt: itemsView,
    });

    const {data: items, loading: itemsLoading} = useRemoteData({
        path: listItems,
        request: {
            ...binder.value,
            page: usePage(),
        },
        halt: !itemsView,
    });

    const exporter = useExporter({req: binder.value});

    return <ui.Box>
        <LayoutItem>
            <SearchTextInput
                {...binder.mapInputProps('text')}
            />
        </LayoutItem>

        <LayoutItem>
            <RadioInput
                {...binder.mapInputProps('ongoing')}
                choices={ongoingChoices}
            />
        </LayoutItem>

        <LayoutItem>
            <RadioInput
                {...binder.mapInputProps('public')}
                choices={publicChoices}
            />
        </LayoutItem>

        <FormItem>
            <RadioInput
                {...brand}
                choices={[
                    {value: null, label: 'ブランド指定なし'},
                    ...data.brands.map(b => ({value: b.value, label: b.value})),
                ]}
            />
        </FormItem>

        {brand.value && <FormItem>
            <RadioInput
                {...binder.mapInputProps('store_id')}
                choices={storeChoices}
            />
        </FormItem>}

        <FormItem>
            <CheckboxInput
                {...binder.mapInputProps('seasons')}
                choices={data.seasons.map(s => ({value: s, label: s}))}
            />
        </FormItem>

        <LayoutItem>
            <ui.HStack>
                <RadioInput
                    value={itemsView}
                    onChange={(_, v) => setItemsView(v)}
                    choices={[
                        {value: false, label: '海外品番毎にまとめて表示'},
                        {value: true, label: '商品単位で表示'},
                    ]}
                />

                <ui.Spacer />
                <ui.Button
                    colorScheme="blue"
                    onClick={exporter.confirm}
                    isDisabled={!binder.value.brand}
                >
                    エクスポート
                </ui.Button>
            </ui.HStack>

            {!binder.value.brand && <ui.Text textAlign="right" mt={1} color="gray.700" fontSize="sm">
                エクスポートするにはブランドを指定してください
            </ui.Text>}

            {exporter.dialog}
        </LayoutItem>

        {!itemsView && <>
            <LayoutItem>
                <ui.Table>
                    <ui.Thead>
                        <ui.Tr>
                            <ui.Th px={1}>Image</ui.Th>
                            <ui.Th px={1}>商品</ui.Th>
                            <ui.Th px={1}>ブランド</ui.Th>
                            <ui.Th px={1}>詳細</ui.Th>
                            <ui.Th px={1}>金額</ui.Th>
                            <ui.Th px={1}>店舗</ui.Th>
                        </ui.Tr>
                    </ui.Thead>
                    <ui.Tbody>
                        {models?.items.map((model) => {
                            const {images} = model;
                            const colors = unique(model.products.map(p => p.color)).sort();
                            const sizes = unique(model.products.flatMap(p => p.items.map(i => i.size)));
                            const dcodes = unique(model.products.map(p => p.domestic_code)).sort();
                            const sprices = unique(model.products.map(p => p.on_sale ? (model.price * (100 - p.discount_rate) / 100) : model.price)).sort();
                            return <ui.Tr key={model.id}>
                                <ui.Td px={1}>
                                    {images[0] && <S3Image
                                        base={images[0].base_url}
                                        processor="sd256"
                                        maxWidth="50px"
                                    />}
                                </ui.Td>
                                <ui.Td px={1}>
                                    <ui.Text fontSize="xs" fontWeight="bold">
                                        {model.code}(海外)
                                    </ui.Text>
                                    {dcodes.map(c => <ui.Text fontSize="xs" key={c}>
                                        {c}(国内)
                                    </ui.Text>)}
                                    <ui.Text fontSize="xs">
                                        {model.name}
                                    </ui.Text>
                                </ui.Td>
                                <ui.Td px={1}>
                                    <ui.Text fontSize="xs">
                                        {model.season}
                                    </ui.Text>
                                    <ui.Text fontSize="xs">
                                        {model.line}
                                    </ui.Text>
                                </ui.Td>
                                <ui.Td px={1}>
                                    <ui.Text fontSize="xs">
                                        {genderText[model.gender]} {model.category}
                                    </ui.Text>
                                    <ui.Text fontSize="xs">
                                        {colors.join(' / ')}
                                    </ui.Text>
                                    <ui.Text fontSize="xs">
                                        {sizes.join(' / ')}
                                    </ui.Text>
                                </ui.Td>
                                <ui.Td px={1} isNumeric={true}>
                                    {model.price}
                                    {sprices.map(sprice => model.price !== sprice && <ui.Text color="red" key={sprice}>
                                        {sprice}
                                    </ui.Text>)}
                                </ui.Td>
                                <ui.Td px={1}>
                                    <ui.UnorderedList>
                                        {model.stores.map((s) => <ui.ListItem fontSize="xs" key={s.id}>
                                            {s.name}
                                        </ui.ListItem>)}
                                    </ui.UnorderedList>
                                </ui.Td>
                            </ui.Tr>;
                        })}
                    </ui.Tbody>
                </ui.Table>
            </LayoutItem>

            {modelsLoading && <LayoutItem>
                <ui.HStack justifyContent="center">
                    <ui.Spinner
                        speed="1.0s"
                    />
                </ui.HStack>
            </LayoutItem>}

            {models && <LayoutItem>
                <Paginator {...models} />
            </LayoutItem>}
        </>}

        {itemsView && <>
            <LayoutItem>
                <ui.Table>
                    <ui.Thead>
                        <ui.Tr>
                            <ui.Th px={1}>Image</ui.Th>
                            <ui.Th px={1}>商品</ui.Th>
                            <ui.Th px={1}>ブランド</ui.Th>
                            <ui.Th px={1}>詳細</ui.Th>
                            <ui.Th px={1}>金額</ui.Th>
                            <ui.Th px={1}>店舗</ui.Th>
                        </ui.Tr>
                    </ui.Thead>
                    <ui.Tbody>
                        {items?.items.map((item) => {
                            const {product} = item;
                            const {model} = product;
                            const {images} = model;
                            return <ui.Tr key={item.id}>
                                <ui.Td px={1}>
                                    {images[0] && <S3Image
                                        base={images[0].base_url}
                                        processor="sd256"
                                        maxWidth="50px"
                                    />}
                                </ui.Td>
                                <ui.Td px={1}>
                                    <ui.Text fontSize="xs" fontWeight="bold">
                                        {item.barcode}
                                    </ui.Text>
                                    <ui.Text fontSize="xs">
                                        {product.domestic_code}(国内)
                                    </ui.Text>
                                    <ui.Text fontSize="xs">
                                        {model.code}(海外)
                                    </ui.Text>
                                    <ui.Text fontSize="xs">
                                        {model.name}
                                    </ui.Text>
                                </ui.Td>
                                <ui.Td px={1}>
                                    <ui.Text fontSize="xs">
                                        {model.season}
                                    </ui.Text>
                                    <ui.Text fontSize="xs">
                                        {model.line}
                                    </ui.Text>
                                </ui.Td>
                                <ui.Td px={1}>
                                    <ui.Text fontSize="xs">
                                        {genderText[model.gender]} {model.category}
                                    </ui.Text>
                                    <ui.Text fontSize="xs">
                                        {product.color}
                                    </ui.Text>
                                    <ui.Text fontSize="xs">
                                        {item.size}
                                    </ui.Text>
                                </ui.Td>
                                <ui.Td px={1} isNumeric={true}>
                                    {model.price}
                                    {model.price !== product.sale_price && <ui.Text color="red">
                                        {product.sale_price}
                                    </ui.Text>}
                                </ui.Td>
                                <ui.Td px={1}>
                                    <ui.UnorderedList>
                                        {item.stores.map((s) => <ui.ListItem fontSize="xs" key={s.id}>
                                            {s.name}
                                        </ui.ListItem>)}
                                    </ui.UnorderedList>
                                </ui.Td>
                            </ui.Tr>;
                        })}
                    </ui.Tbody>
                </ui.Table>
            </LayoutItem>

            {itemsLoading && <LayoutItem>
                <ui.HStack justifyContent="center">
                    <ui.Spinner
                        speed="1.0s"
                    />
                </ui.HStack>
            </LayoutItem>}

            {items && <LayoutItem>
                <Paginator {...items} />
            </LayoutItem>}
        </>}
    </ui.Box>
};


const ImportTab = () => {
    return <ui.Box>
        <ui.Heading fontSize="2xl" my={4}>マスター</ui.Heading>
        <MasterImport />

        <ui.Heading fontSize="2xl" mt={10} mb={4}>オーダー</ui.Heading>
        <OrderImport />

        <ui.Heading fontSize="2xl" mt={10} mb={4}>画像</ui.Heading>
        <ImageImport />
    </ui.Box>
};


const MasterImport = () => {
    const api = useWriterApi(importMaster);
    const {binder, handleSubmit, reset} = useForm<Req<typeof importMaster>, 'data'>(() => ({
        data: undefined,
    }));

    const submit = handleSubmit(data => api.call(data));

    return <ui.Box>
        <FormItem
            keyPath="data"
            error={api.error?.data}
        >
            <ItemMasterInput
                {...binder.mapInputProps('data')}
            />
        </FormItem>

        <LayoutItem>
            <ui.HStack>
                <ui.Spacer />
                <ui.Button colorScheme="blue" onClick={submit} isDisabled={(binder.value.data?.items ?? []).length === 0}>
                    Submit
                </ui.Button>
            </ui.HStack>
        </LayoutItem>

        <ApiErrorDialog api={api} onOk={api.reset} />
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={() => {
                api.reset();
                reset();
            }}
        />
    </ui.Box>
};


type MasterParseResult = Res<typeof parseMaster>;

const ItemMasterInput = ({value: _, onChange, onParsed}: {
    onParsed?(ret: MasterParseResult): void;
} & InputProps<MasterParseResult | undefined, MasterParseResult>) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const parseApi = useReaderApi(parseMaster);
    const [reading, setReading] = React.useState(false);
    const [filename, setFilename] = React.useState<string>();

    return <ui.VStack spacing={2} alignItems="flex-start">
        <ui.HStack spacing={4}>
            <ui.Box width="120px" flexShrink={0} flexGrow={0}>
                <ui.Button onClick={() => inputRef.current?.click()}>
                    {filename ? 'Change' : 'Select'} File
                </ui.Button>
            </ui.Box>

            <ui.Box>
                {filename && <ui.Text mt={1}>
                    {filename}
                </ui.Text>}

                <form style={{display: 'none'}}>
                    <input
                        type='file'
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        ref={inputRef}
                        onChange={(e) => {
                            parseApi.reset();
                            setFilename(undefined);
                            const file = e.target.files?.[0];
                            if (!file) return;
                            e.target.form?.reset();
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            setReading(true);
                            setFilename(file.name);
                            reader.onload = () => {
                                parseApi.call({data: reader.result as string}).then((data) => {
                                    onParsed?.(data);
                                    onChange(null, data);
                                });
                                setReading(false);
                            };
                        }}
                    />
                </form>
            </ui.Box>
        </ui.HStack>

        {(reading || parseApi.loading) && <ui.Spinner />}

        {parseApi.data && <ui.Box p={4} bg="gray.50" borderRadius="8px" width="100%">
            <ui.Text>
                モデル（海外品番）：{parseApi.data.models.length}件<br />
                プロダクト（国内品番+色）：{parseApi.data.products.length}件<br />
                アイテム（バーコード）：{parseApi.data.items.length}件
            </ui.Text>
        </ui.Box>}


        {parseApi.error && <ui.Box>
            <ui.Text color="red" fontWeight="bold" whiteSpace="pre-line">
                ファイルの形式または内容が正しくありません<br /><br />
                {parseApi.error.message}
            </ui.Text>
        </ui.Box>}
    </ui.VStack>;
};




const OrderImport = () => {
    const api = useWriterApi(importOrder);
    const {binder, handleSubmit, reset} = useForm<Req<typeof importOrder>, 'data'>(() => ({
        data: undefined,
        note: '',
    }));

    const submit = handleSubmit(data => api.call(data));

    return <ui.Box>
        <FormItem
            keyPath="data"
            error={api.error?.data}
        >
            <TransactionDataInput
                {...binder.mapInputProps('data')}
                url="admin/product/parse_orders"
                extraInfo={(r) => <ParseOrderExtraInfo r={r} />}
            />
        </FormItem>

        <FormItem label="Note">
            <Textarea {...binder.mapInputProps('note')} />
        </FormItem>

        <LayoutItem>
            <ui.HStack>
                <ui.Spacer />
                <ui.Button colorScheme="blue" onClick={submit} isDisabled={(binder.value.data ?? []).length === 0}>
                    Submit
                </ui.Button>
            </ui.HStack>
        </LayoutItem>

        <ApiErrorDialog api={api} onOk={api.reset} />
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={() => {
                api.reset();
                reset();
            }}
        />
    </ui.Box>
};


const ParseOrderExtraInfo = ({r}: {r: Res<'admin/product/parse_orders'>}) => {
    const [showChanges, setShowChanges] = React.useState(true);
    const [showMissing, setShowMissing] = React.useState(false);
    const missing = React.useMemo(() => r.changes.filter(c => c.before === null), [r]);
    const changes = React.useMemo(() => r.changes.filter(c => c.before !== null), [r]);

    const stores = React.useMemo(() => r.stores.reduce((acc, s) => {
        acc[s.code] = s;
        return acc;
    }, {} as Keyed<Store>), [r]);

    return <>
        {r.changes.length === 0 && <>
            <ui.Spacer height={2} />
            <ui.Text fontWeight="bold">
                システムに登録されている注文データと一致しています
            </ui.Text>
        </>}

        {changes.length > 0 && <>
            <ui.Spacer height={2} />
            <ui.Text fontWeight="bold">
                {changes.length}件の注文の数量が変更されます
            </ui.Text>

            <ui.Text>
                <ui.Link color="blue" onClick={() => setShowChanges(v => !v)}>
                    {showChanges ? '▼隠す' : '▲表示する'}
                </ui.Link>
            </ui.Text>

            {showChanges && changes.map((c, i) => <ui.Text key={i}>
                <ui.chakra.span fontWeight="bold">{c.before}</ui.chakra.span> → <ui.chakra.span fontWeight="bold">{c.after}</ui.chakra.span> [{c.barcode}] {stores[c.store_code].name}
            </ui.Text>)}
        </>}

        {missing.length > 0 && <>
            <ui.Spacer height={2} />
            <ui.Text color="red" fontWeight="bold">
                {missing.length}件の注文を新規に取り込みます
            </ui.Text>

            <ui.Text>
                <ui.Link color="blue" onClick={() => setShowMissing(v => !v)}>
                    {showMissing ? '▼隠す' : '▲表示する'}
                </ui.Link>
            </ui.Text>

            {showMissing && missing.map((c, i) => <ui.Text key={i} fontWeight="bold">
                [{c.store_code}:{c.barcode}]: <ui.chakra.span fontWeight="bold">{c.after}</ui.chakra.span>
            </ui.Text>)}
        </>}
    </>;
};




const ImageImport = () => {
    const api = useWriterApi(importImages);
    const {binder, handleSubmit, reset} = useForm<Req<typeof importImages>, 'data'>(() => ({
        data: undefined,
    }));

    const submit = handleSubmit(data => api.call(data));

    const [file, setFile] = React.useState<File>();

    return <ui.Box>
        <FormItem
            keyPath="data"
            error={api.error?.data}
        >
            <FileInput
                value={file}
                onChange={(_, v) => setFile(v)}
                onReadData={(data) => binder.assign({data})}
                accept=".zip"
            />
        </FormItem>

        <LayoutItem>
            <ui.HStack>
                <ui.Spacer />
                <ui.Button colorScheme="blue" onClick={submit} isDisabled={!binder.value.data}>
                    Submit
                </ui.Button>
            </ui.HStack>
        </LayoutItem>

        <ApiErrorDialog api={api} onOk={api.reset} />
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={() => {
                api.reset();
                reset();
            }}
        />
    </ui.Box>
};


const useExporter = ({req}: {
    req: Req<typeof listItems>;
}): {
    confirm(): void;
    dialog: React.ReactNode;
} => {
    const api = useReaderApi(listItems);
    const confirmDisclosure = ui.useDisclosure();

    const run = async () => {
        confirmDisclosure.onClose();

        const rows = (await api.call({...req, page_size: 0}))
        .items
        .sort(makeComparator<NestedItem>([
            (item) => item.product.model.season.replace('SS', 'AA'),
            (item) => ({
                'M': 0,
                'W': 1,
                'U': 2,
                'K': 3,
            }[item.product.model.gender]),
            (item) => item.product.model.category,
        ]))
        .map((item) => itemToMasterRow(item));

        if (rows.length === 0) {
            alert('出力するデータがありません');
            return;
        }

        const wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(
            wb, xlsx.utils.json_to_sheet(rows), '商品');

        xlsx.writeFileXLSX(wb, `商品-${req.brand}-${moment().format('YYYYMMDDHHmm')}.xlsx`, {
            bookSST: true,
            compression: true,
        });
    };

    return {
        confirm: () => confirmDisclosure.onOpen(),
        dialog: <>
            <ConfirmationDialog
                title="エクスポートしますか？"
                message="この操作には時間がかかる場合があります"
                confirm="はい"
                {...confirmDisclosure}
                onConfirm={run}
            />
            <ApiSpinnerDialog api={api} />
        </>
    };
};




const ColorTab = () => {
    const [brand, setBrand] = React.useState<Brand['value'] | null>(null);
    const {data: colors, reload} = useRemoteData({
        path: listColors,
        request: {
            brand,
            page: usePage(),
        },
    });
    const [editing, setEditing] = React.useState<Color>();
    const createDisclosure = ui.useDisclosure();
    const updateDisclosure = ui.useDisclosure({
        isOpen: editing !== undefined,
        onClose: () => setEditing(undefined),
    });

    return <ui.Box>
        <LayoutItem>
            <ui.Button onClick={createDisclosure.onOpen}>
                Add
            </ui.Button>
        </LayoutItem>

        <LayoutItem>
            <RadioInput
                value={brand}
                onChange={(_, v) => setBrand(v)}
                choices={[
                    {value: null, label: 'ブランド指定なし'},
                    ...brands.map(b => ({value: b.value, label: b.value})),
                ]}
            />
        </LayoutItem>

        <LayoutItem>
            <ui.Table>
                <ui.Thead>
                    <ui.Tr>
                        <ui.Th>Id</ui.Th>
                        <ui.Th>Brand</ui.Th>
                        <ui.Th>Color</ui.Th>
                        <ui.Th>Image</ui.Th>
                    </ui.Tr>
                </ui.Thead>
                <ui.Tbody>
                    {colors?.items.map((c) => <ui.Tr key={c.id}>
                        <ui.Td>
                            <ui.Link color="blue.500" onClick={() => setEditing(c)}>
                                {c.id}
                            </ui.Link>
                        </ui.Td>
                        <ui.Td>{c.brand.value}</ui.Td>
                        <ui.Td>{c.color}</ui.Td>
                        <ui.Td px={1}>
                            {c.image && <S3Image
                                base={c.image.base_url}
                                processor="sd256"
                                maxWidth="50px"
                            />}
                        </ui.Td>
                    </ui.Tr>)}
                </ui.Tbody>
            </ui.Table>
        </LayoutItem>

        <LayoutItem>
            {colors && <LayoutItem>
                <Paginator {...colors} />
            </LayoutItem>}
        </LayoutItem>

        <CreateColorDialog
            onComplete={() => {
                createDisclosure.onClose();
                reload();
            }}
            {...createDisclosure}
        />
        <UpdateColorDialog
            color={editing}
            onComplete={() => {
                updateDisclosure.onClose();
                reload();
            }}
            {...updateDisclosure}
        />
    </ui.Box>
};



const CreateColorDialog = ({onComplete, ...props}: {
    onComplete(color: Color): void;
} & ui.UseDisclosureReturn) => {
    const api = useWriterApi(createColor);
    const {binder, handleSubmit, reset} = useForm<Req<typeof createColor>>(() => ({
        brand: brands[0].value,
        color: '',
        image: null,
    }));

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, reset]);

    const submit = handleSubmit(data => api.call(data));

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">色の登録</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <FormItem
                        label="brand"
                        keyPath="brand"
                        error={api.error?.data}
                    >
                        <RadioInput
                            {...binder.mapInputProps('brand')}
                            choices={brands.map(b => ({value: b.value, label: b.value}))}
                        />
                    </FormItem>

                    <FormItem
                        label="color"
                        keyPath="color"
                        error={api.error?.data}
                    >
                        <Input
                            {...binder.mapInputProps('color')}
                        />
                    </FormItem>

                    <FormItem
                        label="image"
                        keyPath="image"
                        error={api.error?.data}
                    >
                        <ImageInput
                            {...binder.mapInputProps('image')}
                        />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={submit}
                >
                    作成
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiErrorDialog api={api} onOk={api.reset} />
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={(data) => {
                onComplete(data);
                reset();
                api.reset();
            }}
        />
    </ui.Modal>;
};



const UpdateColorDialog = ({color, onComplete, ...props}: {
    color: Color | undefined;
    onComplete(store: Color): void;
} & ui.UseDisclosureReturn) => {
    const api = useWriterApi(updateColor);
    const deleteApi = useWriterApi(deleteColor);
    const {binder, handleSubmit, reset} = useForm<Req<typeof updateColor>, keyof Req<typeof updateColor>>(() => ({
        id: color?.id,
        brand: color?.brand?.value,
        color: color?.color,
        image: imgurl(color?.image?.base_url, 'sd128'),
    }));

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, color, reset]);

    const submit = handleSubmit(data => api.call(data));
    const confirmDisclosure = ui.useDisclosure();

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">色の編集</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <FormItem
                        label="brand"
                        keyPath="brand"
                        error={api.error?.data}
                    >
                        <RadioInput
                            {...binder.mapInputProps('brand')}
                            choices={brands.map(b => ({value: b.value, label: b.value}))}
                        />
                    </FormItem>

                    <FormItem
                        label="color"
                        keyPath="color"
                        error={api.error?.data}
                    >
                        <Input
                            {...binder.mapInputProps('color')}
                        />
                    </FormItem>

                    <FormItem
                        label="image"
                        keyPath="image"
                        error={api.error?.data}
                    >
                        <ImageInput
                            {...binder.mapInputProps('image')}
                        />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button colorScheme="red" onClick={confirmDisclosure.onOpen}>削除</ui.Button>
                <ui.Spacer />
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={submit}
                >
                    更新
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        {color && <AlertDialog
            {...confirmDisclosure}
            title="削除の確認"
            confirm="削除する"
            onConfirm={async () => {
                await deleteApi.call({id: color.id});
                confirmDisclosure.onClose();
                onComplete(color);
            }}
            onCancel={confirmDisclosure.onClose}
        >
            <LayoutItem>
                <ui.Text>
                    削除してよろしいですか？
                </ui.Text>
            </LayoutItem>
        </AlertDialog>}
        <ApiSpinnerDialog api={deleteApi} />
        <ApiErrorDialog api={deleteApi} onOk={deleteApi.reset} />

        <ApiErrorDialog api={api} onOk={api.reset} />
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={(data) => {
                onComplete(data);
                reset();
                api.reset();
            }}
        />
    </ui.Modal>;
};
