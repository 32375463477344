import {React, ui, icons} from 'lib';

const useWindowSize = (): {
    width: number;
    height: number;
} => {
    const getSize = () => ({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const [size, setSize] = React.useState(getSize());

    React.useEffect(() => {
        const onChange = () => setSize(getSize());
        window.addEventListener('resize', onChange);
        return () => window.removeEventListener('resize', onChange);
    }, [setSize]);

    return Object.assign({}, size);
};

const ease = (t: number): number => t * (2 - t);

export interface CarouselOptions {
    dots?: boolean;
    arrows?: boolean;
    arrowColor?: string;
    autoSlideInterval?: number;
    scrollDuration?: number;
    heightRatio?: number;
    createDummyElementForLoopback?: (place: DummyPlace) => React.ReactElement | undefined;
}


export interface CarouselProps extends CarouselOptions {
    index: number;
    onChangeIndex(index: number): void;
    slides: React.ReactElement[];
}


type InternalActions = null | 'swipe-to-right' | 'arrow-right' | 'swipe-to-left' | 'arrow-left';
export type DummyPlace = 'beforeFirst' | 'afterLast';


export const useCarousel = (state?: {
    index: number;
    onChangeIndex(index: number): void;
}): {
    index: number;
    onChangeIndex(index: number): void;
} => {
    const [index, onChangeIndex] = React.useState(0);
    return state ?? {
        index,
        onChangeIndex,
    };
};


export const Carousel = ({
    dots = true,
    arrows = true,
    autoSlideInterval = 0, // 0 means never slide automatically
    scrollDuration = 300,
    heightRatio = 1,
    ...other
}: CarouselProps): React.ReactElement => {
    const props = {dots, arrows, autoSlideInterval, scrollDuration, heightRatio, ...other};
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const slider = React.useRef<HTMLDivElement>(null);
    const lastAction = React.useRef<InternalActions>(null);
    const hasMultiple = props.slides.length > 1;

    const goToPrev = React.useCallback(() => {
        const {slides, onChangeIndex, index} = propsRef.current;
        const {length} = slides;
        onChangeIndex((index - 1 + length) % length);
    }, []);

    const goToNext = React.useCallback(() => {
        const {slides, onChangeIndex, index} = propsRef.current;
        onChangeIndex((index + 1) % slides.length);
    }, []);

    const _scrollTo = React.useCallback((index: number, animate: boolean, callback?: {(): void}) => {
        lastAction.current = null;

        const target = slider.current;
        if (!target) return;
        const {scrollLeft} = target;
        const dest = target.clientWidth * (index + 1);

        if (animate) {
            const distance = dest - scrollLeft;
            const duration = propsRef.current.scrollDuration;

            let start: null | number = null;
            const step = (ts: number) => {
                if (!start) start = ts;
                const elapsed = ts - start;
                if (elapsed > duration) target.scrollLeft = dest;
                if (Math.abs(target.scrollLeft - dest) <= 1) return callback && callback();
                target.scrollLeft = scrollLeft + (distance * ease(elapsed / duration));
                window.requestAnimationFrame(step);
            }

            window.requestAnimationFrame(step);
        } else {
            target.scrollLeft = dest;
            callback && callback();
        }
    }, []);

    const scrollTo = React.useCallback((index, prev) => {
        const len = propsRef.current.slides.length;
        const rightActions: InternalActions[] = ['swipe-to-right', 'arrow-right'];
        const leftActions: InternalActions[] = ['swipe-to-left', 'arrow-left'];
        if (rightActions.includes(lastAction.current) && prev === len - 1 && index === 0) {
            _scrollTo(len, true, () => _scrollTo(index, false));
        } else if (leftActions.includes(lastAction.current) && prev === 0 && index === len - 1) {
            _scrollTo(-1, true, () => _scrollTo(index, false));
        } else {
            _scrollTo(index, true);
        }
    }, [_scrollTo]);

    const hover = React.useRef(false);
    const touchClientX = React.useRef(0);

    const handleMouseEnter = React.useCallback(() => { hover.current = true; }, []);
    const handleMouseLeave = React.useCallback(() => { hover.current = false; }, []);

    const handleTouchStart = React.useCallback((e) => {
        hover.current = true;
        if (!hasMultiple) { return }
        touchClientX.current = e.touches[0].clientX;
    }, [hasMultiple]);

    const handleTouchMove = React.useCallback((e) => {
        if (!hasMultiple) { return }
        const x = e.touches[0].clientX;
        const current = slider.current;
        if (current) {
            current.scrollLeft += touchClientX.current - x;
        }
        touchClientX.current = x;
    }, [hasMultiple]);

    const handleTouchEnd = React.useCallback(() => {
        hover.current = false;
        const current = slider.current;
        if (!hasMultiple || !current) { return }
        const {scrollLeft, clientWidth: width} = current;
        const index = Math.floor((scrollLeft - width) / width);
        const remining = scrollLeft % width;

        if (props.index <= index) {
            if (remining > (width * 1 / 10)) {
                lastAction.current = 'swipe-to-right';
                goToNext();
                return;
            }
        } else {
            if (remining < (width * 9 / 10)) {
                lastAction.current = 'swipe-to-left';
                goToPrev();
                return;
            }
        }
        _scrollTo(props.index, true);
    }, [hasMultiple, props.index, goToNext, goToPrev, _scrollTo]);

    const onClickArrowLeft = React.useCallback((e) => {
        e.stopPropagation();
        lastAction.current = 'arrow-left';
        goToPrev();
    }, [goToPrev]);

    const onClickArrowRight = React.useCallback((e) => {
        e.stopPropagation();
        lastAction.current = 'arrow-right';
        goToNext();
    }, [goToNext]);

    const {width: windowWidth} = useWindowSize();
    const prevIndex = React.useRef<number | null>(null);
    React.useLayoutEffect(() => {
        const enqueue = (scrollTo: {(): void}) => {
            if (!slider.current) { return; }
            const {x, y, width, height} = slider.current.getBoundingClientRect();
            if (x === 0 && y === 0 && width === 0 && height === 0) {
                setTimeout(() => enqueue(scrollTo), 100);
            } else {
                scrollTo();
            }
        }

        if (prevIndex.current === null || props.index === prevIndex.current) {
            enqueue(() => _scrollTo(props.index, false));
        } else {
            enqueue(() => scrollTo(props.index, prevIndex.current));
        }
        prevIndex.current = props.index;

        if (props.autoSlideInterval > 0) {
            const _intervalID = setInterval(
                () => !hover.current && goToNext(),
                props.autoSlideInterval);
            return () => window.clearInterval(_intervalID);
        }
        return;
    }, [_scrollTo, scrollTo, goToNext, windowWidth, props.index, props.autoSlideInterval]);

    const dummyForLoopback = (place: DummyPlace) => {
        const index = place === 'beforeFirst' ? slides.length - 1 : 0;
        return props.createDummyElementForLoopback?.(place) ?? React.cloneElement(slides[index]);
    };

    const {slides, index, onChangeIndex, arrowColor} = props;

    return <ui.Box width="100%" overflow="hidden" position="relative">
        <div style={{paddingBottom: `${heightRatio * 100}%`}} />
        <ui.Box
            ref={slider}
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            whiteSpace="nowrap"
            overflow="hidden"
            sx={{
                '& > div': {
                    width: '100%',
                    height: '100%',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                },
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            {slides.length > 1 && <div>{dummyForLoopback('beforeFirst')}</div>}
            {slides.map((s, i) => <div key={i}>{s}</div>)}
            {slides.length > 1 && <div>{dummyForLoopback('afterLast')}</div>}
        </ui.Box>

        {slides.length > 1 && <div>
            {dots && <ui.Box position="absolute" left={0} right={0} bottom="4px">
                <Dots
                    length={slides.length}
                    activeIndex={index}
                    onChange={onChangeIndex}
                />
            </ui.Box>}
            {arrows && <Arrow left={true} onClick={onClickArrowLeft} color={arrowColor} />}
            {arrows && <Arrow left={false} onClick={onClickArrowRight} color={arrowColor} />}
        </div>}
    </ui.Box>
};



export const Dots = ({
    length,
    activeIndex,
    onChange,
    activeColor = '#fff',
    color = '#ebebeb',
}: {
    length: number;
    activeIndex: number;
    onChange(index: number): void;
    activeColor?: string;
    color?: string;
}): React.ReactElement => <ul
    style={{
        display: 'block',
        textAlign: 'center',
        zIndex: 100,
        color: '#fff',
        fontSize: 0,
    }}
>
    {Array(length).fill(null).map((_, i) => <ui.chakra.li
        onClick={i === activeIndex ? undefined : ((e) => {
            e.stopPropagation();
            onChange(i);
        })}
        key={i}
        display="inline-block"
        width="20px"
        height="20px"
        cursor={i !== activeIndex ? 'pointer' : undefined}
        filter="drop-shadow(0 0 0.1rem black)"
        sx={{
            '&:after': {
                content: '""',
                boxSizing: 'border-box',
                display: 'inline-block',
                borderRadius: '50%',
                border: `3px solid ${i === activeIndex ? activeColor : color}`,
                width: '6px',
                height: '6px',
                margin: '7px',
            }
        }}
    />)}
</ul>;


const Arrow = ({left, onClick, color = '#fff'}: {
    left: boolean,
    onClick(e: React.SyntheticEvent): void,
    color?: string;
}) => <ui.Box
    onClick={onClick}
    position="absolute"
    top="50%"
    transform="translateY(-50%)"
    filter="drop-shadow(0 0 0.1rem black)"
    zIndex={100}
    cursor="pointer"
    left={left ? '4px' : undefined}
    right={left ? undefined : '4px'}
>
    {left && <icons.ArrowLeftIcon
        fontSize={24}
        color={color}
    />}
    {!left && <icons.ArrowRightIcon
        fontSize={24}
        color={color}
    />}
</ui.Box>;
