import {React, ui, icons, RouterDom} from 'lib';

import {useSignoutDialog, Res, useReaderApi, InputProps} from 'shared';
import {makeBaseUrl} from 'staff/base';


export const AdminFrame = ({children}: {
    children: React.ReactNode;
}): React.ReactElement => {
    const [opened, setOpened] = React.useState(false);
    const wide = ui.useBreakpointValue({base: false, md: true})

    return <ui.Box>
        <ui.Box
            position="fixed" top={0} left={0} right={0} height="56px"
            p={2} bgColor="white" zIndex={1001}
            borderBottom="0.5px solid" borderBottomColor="gray.300"
        >
            {!wide && <ui.Stack direction="row" alignItems="center" spacing={3}>
                <ui.Box>
                    <ui.IconButton
                        aria-label="Options"
                        icon={<icons.HamburgerIcon />}
                        variant="outline"
                        onClick={() => setOpened(!opened)}
                    />
                </ui.Box>

                <ui.Drawer isOpen={opened} placement="left" onClose={() => setOpened(false)}>
                    <ui.DrawerOverlay>
                        <ui.DrawerContent>
                            <ui.DrawerCloseButton />
                            <ui.DrawerHeader>Menu</ui.DrawerHeader>
                            <ui.DrawerBody>
                                <Menu />
                            </ui.DrawerBody>
                        </ui.DrawerContent>
                    </ui.DrawerOverlay>
                </ui.Drawer>
            </ui.Stack>}

            <ui.Center position="absolute" width="100%" height="100%" left={0} top={0} pointerEvents="none">
                <ui.Text fontWeight="bold">Stock Book</ui.Text>
            </ui.Center>
        </ui.Box>

        <ui.Container maxW="container.xl" py={20}>
            <ui.Box position="relative">
                <ui.HStack alignItems="flex-start">
                    {wide && <ui.Box flexGrow={0} flexShrink={0} width="300px">
                        <Menu />
                    </ui.Box>}

                    <ui.Box flexGrow={1} flexShrink={1} minWidth={0}>
                        <ui.Box ml={wide ? 5 : 0} minHeight="200px">
                            {children}
                        </ui.Box>
                    </ui.Box>
                </ui.HStack>
            </ui.Box>
        </ui.Container>
    </ui.Box>;
};


const Menu = React.memo((): React.ReactElement => {
    const signout = useSignoutDialog();

    return <ui.Box>
        <MenuList>
            <MenuGroup title="ADMIN">
                <MenuItem to="/admin/product" text="商品" />
                <MenuItem to="/admin/stock" text="在庫" />
                <MenuItem to="/admin/action" text="操作履歴" />
                <MenuItem to="/admin/store" text="店舗/納品先" />
                <MenuItem to="/admin/user" text="ユーザー管理" />
            </MenuGroup>

            <MenuGroup title="SITES">
                <MenuItem to={makeBaseUrl('')} text="Staff" />
                <MenuItem to="/" text="Public" />
            </MenuGroup>

            <MenuGroup title="ACCOUNT">
                <MenuItem onClick={signout.onOpen} text="Sign Out" />
            </MenuGroup>
        </MenuList>
        {signout.dialog}
    </ui.Box>
});


const MenuList = ({children}: {children: React.ReactNode}) => {
    return <ui.Box sx={{'& > :not(div:first-of-type)': {marginTop: '30px'}}}>
        {children}
    </ui.Box>
};


const MenuGroup = ({title, children}: {
    title: String;
    children: React.ReactNode;
}) => {
    return <ui.Box>
        <ui.Text fontSize="xs">{title}</ui.Text>
        <ui.Box
            borderLeft="1px solid #eee"
            sx={{'& > *': {marginTop: '10px'}}}
        >
            {children}
        </ui.Box>
    </ui.Box>
};


const MenuItem = ({text, to, onClick}: {
    text: string;
    to?: RouterDom.LinkProps['to'];
    onClick?(): void;
}) => {
    const button = <ui.Button
        width="100%"
        onClick={onClick}
        children={text}
        textAlign="left"
        display="block"
        variant="ghost"
    />;
    return <ui.Box>
        {to && <RouterDom.Link to={to}>
            {button}
        </RouterDom.Link>}

        {!to && button}
    </ui.Box>
};


type ParseTransactionDataUrl = 'admin/parse_transaction_data' | 'admin/product/parse_orders';
const parseTransactionData = 'admin/parse_transaction_data';
type TransactionDataParseResult = Res<ParseTransactionDataUrl>;
type TransactionData = TransactionDataParseResult['rows'];


export function TransactionDataInput<U extends ParseTransactionDataUrl = typeof parseTransactionData>(props: {
    url?: U;
    onParsed?(ret: Res<U>): void;
    extraInfo?(ret: Res<U>): React.ReactNode;
} & InputProps<TransactionData | undefined, TransactionData>): React.ReactElement {
    const {url, onChange, onParsed, extraInfo} = props;

    const inputRef = React.useRef<HTMLInputElement>(null);
    const parseApi = useReaderApi(url ?? parseTransactionData);
    const [reading, setReading] = React.useState(false);
    const [filename, setFilename] = React.useState<string>();
    const [showMissingBarcodes, setShowMissingBarcodes] = React.useState(false);
    const [showMissingWarehouses, setShowMissingWarehouses] = React.useState(false);

    return <ui.VStack spacing={2} alignItems="flex-start">
        <ui.HStack spacing={4}>
            <ui.Box width="120px" flexShrink={0} flexGrow={0}>
                <ui.Button onClick={() => inputRef.current?.click()}>
                    {filename ? 'Change' : 'Select'} File
                </ui.Button>
            </ui.Box>

            <ui.Box>
                {filename && <ui.Text mt={1}>
                    {filename}
                </ui.Text>}

                <form style={{display: 'none'}}>
                    <input
                        type='file'
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        ref={inputRef}
                        onChange={(e) => {
                            parseApi.reset();
                            setFilename(undefined);
                            const file = e.target.files?.[0];
                            if (!file) return;
                            e.target.form?.reset();
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            setReading(true);
                            setFilename(file.name);
                            reader.onload = () => {
                                parseApi.call({data: reader.result as string}).then((data) => {
                                    setShowMissingBarcodes(false);
                                    setShowMissingWarehouses(false);
                                    onParsed?.(data);
                                    onChange(null, data.rows);
                                });
                                setReading(false);
                            };
                        }}
                    />
                </form>
            </ui.Box>
        </ui.HStack>

        {(reading || parseApi.loading) && <ui.Spinner />}

        {parseApi.data && <ui.Box p={4} bg="gray.50" borderRadius="8px" width="100%">
            <ui.Text>
                取り込み対象：{parseApi.data.rows.length}件
            </ui.Text>

            {parseApi.data.missing_barcodes.length > 0 && <>
                <ui.Spacer height={2} />
                <ui.Text color="red" fontWeight="bold">
                    システムに登録されていない商品が{parseApi.data.missing_barcodes.length}件含まれています
                </ui.Text>

                <ui.Text>
                    <ui.Link color="blue" onClick={() => setShowMissingBarcodes(v => !v)}>
                        {showMissingBarcodes ? '▼隠す' : '▲表示する'}
                    </ui.Link>
                </ui.Text>
            </>}

            {showMissingBarcodes && parseApi.data.missing_barcodes.map((c) => <ui.Text key={c} fontWeight="bold">
                {c}
            </ui.Text>)}

            {parseApi.data.missing_warehouse_codes.length > 0 && <>
                <ui.Spacer height={2} />
                <ui.Text color="red" fontWeight="bold">
                    システムに登録されていない納品先が{parseApi.data.missing_warehouse_codes.length}件含まれています
                </ui.Text>

                <ui.Text>
                    <ui.Link color="blue" onClick={() => setShowMissingWarehouses(v => !v)}>
                        {showMissingWarehouses ? '▼隠す' : '▲表示する'}
                    </ui.Link>
                </ui.Text>
            </>}

            {showMissingWarehouses && parseApi.data.missing_warehouse_codes.map((c) => <ui.Text key={c} fontWeight="bold">
                {c}
            </ui.Text>)}

            {extraInfo?.(parseApi.data)}
        </ui.Box>}

        {parseApi.error && <ui.Box>
            <ui.Text color="red" fontWeight="bold">
                ファイルの形式または内容が正しくありません<br />
                {parseApi.error.message}
            </ui.Text>
        </ui.Box>}
    </ui.VStack>;
}
