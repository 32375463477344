import {NestedItem} from 'types/elcstockbook/api/data.gen';


export const itemToMasterRow = (item: NestedItem): Keyed<unknown> => ({
    '海外モデル名': item.product.model.name,
    '海外品番': item.product.model.code,
    '国内品番': item.product.domestic_code,
    'M or W or U or K': item.product.model.gender,
    'ブランド名１': item.product.model.brand.value,
    'ブランド名2': item.product.model.line,
    'アイテム': item.product.model.category,
    'シーズン': item.product.model.season,
    '上代税抜': item.product.model.price,
    'カラー': item.product.color,
    'サイズ': item.size,
    'バーコード': item.barcode,
    'セール': item.product.on_sale ? 'S' : '',
    '%off': item.product.discount_rate,
    '非表示': item.product.ongoing ? '' : '非表示',
});
