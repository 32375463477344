import {React, ui, icons, moment} from 'lib';
import {unique, formatPrice} from 'utils';
import {S3Image, S3ImageDialog, S3ImagesCarousel, useCarousel, LayoutItem, useWriterApi, useRemoteData, Res, ImageInput, ApiSpinnerDialog, ApiCompletionDialog, ApiErrorDialog, ConfirmationDialog, useSelected, useStores} from 'shared';
import {Store, Image, StoreItemLog} from 'types/elcstockbook/api/data.gen'

import {useStore, useModelCodeQuery, useBarcodeQuery} from './base';

const modelPath = 'staff/list_models';
export type Model = ArrayItem<Res<typeof modelPath>['items']>;
type Product = ArrayItem<Model['products']>;
type Item = ArrayItem<Product['items']>;

export const useModelDetailDialog = ({onUpdated}: {
    onUpdated(model: Model): void;
}): {
    dialog: React.ReactElement;
} & ui.UseDisclosureReturn => {

    const modelCodeQuery = useModelCodeQuery();
    const barcodeQuery = useBarcodeQuery();
    const modelCode = modelCodeQuery.value;
    const barcode = barcodeQuery.value;
    const hasCode = Boolean(modelCode || barcode);

    const store = useStore();

    const {data, reload} = useRemoteData({
        path: modelPath,
        request: {
            store_id: store.id,
            codes: modelCode ? [modelCode] : [],
            barcodes: barcode ? [barcode] : [],
        },
        halt: !hasCode,
    });

    const model = data?.items[0];

    React.useEffect(() => {
        model && onUpdated(model);
    }, [onUpdated, model]);

    const disclosure = ui.useDisclosure({
        isOpen: hasCode,
        onClose: () => barcode ? barcodeQuery.replace(null) : modelCodeQuery.replace(null),
    });

    const imageEditDisclosure = ui.useDisclosure();
    const imageAddDisclosure = ui.useDisclosure();

    const [preview, setPreview] = React.useState<Image>();
    React.useLayoutEffect(() => setPreview(undefined), [model]);

    const carousel = useCarousel();

    return {
        ...disclosure,

        dialog: <ui.Modal {...disclosure} size="3xl" closeOnOverlayClick={false}>
            <ui.ModalOverlay />
            <ui.ModalContent>
                <ui.ModalHeader textAlign="center">
                    {model ? null : modelCode ?? barcode}
                </ui.ModalHeader>
                <ui.ModalCloseButton />

                <ui.ModalBody>
                    {model && <>
                        <LayoutItem>
                            <ui.Heading textAlign="center">{model.name}</ui.Heading>
                        </LayoutItem>

                        <LayoutItem>
                            <ui.Text fontSize="lg" mt={3} textAlign="center">
                                {unique([model.code, ...model.products.map(p => p.domestic_code)]).map((c, i) => <React.Fragment key={c}>
                                    {i > 0 && <br />}{c}
                                </React.Fragment>)}
                            </ui.Text>
                        </LayoutItem>

                        <LayoutItem>
                            <ui.Box fontSize="lg" textAlign="center">
                                <ModelPrices model={model} />
                            </ui.Box>
                        </LayoutItem>

                        {model.images.length > 0 && <LayoutItem>
                            <S3ImagesCarousel images={model.images} {...carousel} />
                        </LayoutItem>}

                        {model.products.map((prod) => <React.Fragment key={prod.id}>
                            <LayoutItem>
                                <ProductDetailTable 
                                    product={prod}
                                    selectable={true}
                                />

                                {model.price !== prod.sale_price && <ui.Text textAlign="right" fontWeight="bold" color="red" mt={1}>
                                    {formatPrice(prod.sale_price)}
                                </ui.Text>}
                            </LayoutItem>
                        </React.Fragment>)}

                        <LayoutItem>
                            <hr />
                        </LayoutItem>

                        <LayoutItem>
                            <ui.Box my={4}>
                                <ui.Text fontSize="lg" fontWeight="bold">Images</ui.Text>
                            </ui.Box>
                        </LayoutItem>

                        {model.images.length === 0 && <LayoutItem>
                            <ui.Text color="gray.500">No Image</ui.Text>
                        </LayoutItem>}

                        {model.images.length > 0 && <LayoutItem>
                            <ui.Wrap>
                                {model.images.map((img, i) => <ui.Box
                                    ratio={1}
                                    textAlign="center"
                                    width="100px"
                                    height="100px"
                                    onClick={() => setPreview(img)}
                                    cursor="pointer"
                                    key={i}
                                >
                                    <S3Image base={img.base_url} processor="sd128" />
                                </ui.Box>)}
                            </ui.Wrap>
                        </LayoutItem>}

                        <LayoutItem>
                            <ui.HStack>
                                <ui.Button onClick={imageEditDisclosure.onOpen} isDisabled={model.images.length === 0}>
                                    Edit Images
                                </ui.Button>

                                <ui.Button onClick={imageAddDisclosure.onOpen}>
                                    Add Image
                                </ui.Button>
                            </ui.HStack>
                        </LayoutItem>

                        <ImageEditDialog
                            model={model}
                            onUpdated={reload}
                            {...imageEditDisclosure}
                        />

                        <ImageAddDialog
                            model={model}
                            onAdded={reload}
                            {...imageAddDisclosure}
                        />

                        <S3ImageDialog
                            title={model.name}
                            base={preview?.base_url}
                            processor="sd1024"
                            isOpen={!!preview}
                            onClose={() => setPreview(undefined)}
                        />
                    </>}

                    {model && <OtherStoreInfo model={model} />}

                    {!model && <ui.Box py={10}>
                        {!data && <ui.Center><ui.Spinner /></ui.Center>}
                        {data && <ui.Text textAlign="center" color="gray.500" size="lg">
                            Product was not found
                        </ui.Text>}
                    </ui.Box>}
                </ui.ModalBody>

                <ui.ModalFooter>
                    <ui.Button onClick={disclosure.onClose}>
                        Close
                    </ui.Button>
                </ui.ModalFooter>
            </ui.ModalContent>
        </ui.Modal>,
    };
};


export const ModelPrices = React.memo(({model}: {
    model: Model;
}) => {
    const prices = {} as Keyed<Model['products']>;
    model.products.forEach((p) => {
        if (!(p.sale_price in prices)) {
            prices[p.sale_price] = [];
        }
        prices[p.sale_price].push(p);
    });

    const length = Object.keys(prices).length;
    if (length === 1) {
        if (model.price in prices) {
            return <ui.Text fontWeight="bold">
                {formatPrice(model.price)}
            </ui.Text>;
        } else {
            return <ui.Box>
                <ui.Text fontWeight="bold" textDecoration="line-through">
                    {formatPrice(model.price)}
                </ui.Text>
                <DiscountedPrice rate={model.products[0].discount_rate} price={model.products[0].sale_price} />
            </ui.Box>;
        }
    }

    return <>
        {model.price in prices && <ui.Box>
            <ui.Text fontWeight="bold">
                {formatPrice(model.price)}
            </ui.Text>
            <ui.Text fontSize="xs" fontStyle="italic" color="gray.600">
                ({prices[model.price].map((p) => p.color).join(', ')})
            </ui.Text>
        </ui.Box>}

        {!(model.price in prices) && <ui.Box>
            <ui.Text fontWeight="bold" textDecoration="line-through">
                {formatPrice(model.price)}
            </ui.Text>
        </ui.Box>}

        {Object.values(prices).map((products, i) => products[0].discount_rate > 0 && <ui.Box key={i}>
            <DiscountedPrice rate={products[0].discount_rate} price={products[0].sale_price} />
            <ui.Text fontSize="xs" fontStyle="italic" color="gray.600">
                ({products.map((p) => p.color).join(', ')})
            </ui.Text>
        </ui.Box>)}
    </>;
});


const DiscountedPrice = ({rate, price}: {
    rate: number;
    price: number;
}) => <ui.Text color="red" fontWeight="bold">
    <ui.Text as="span" fontSize="xs">
        {rate}%OFF
    </ui.Text>
    <ui.Text as="span" ml={1}>
        {formatPrice(price)}
    </ui.Text>
</ui.Text>;


export const ProductDetailTable = ({size, product, selectable}: {
    size?: Props<typeof ui.Table>['size'];
    product: Product;
    selectable: boolean;
}) => {
    return <ui.Table size={size}>
        <ui.Thead>
            <ItemDetailHeader
                color={product.color}
                selectable={selectable}
            />
        </ui.Thead>
        <ui.Tbody>
            {product.items.map((item) => <ItemDetail
                key={item.barcode}
                item={item}
                selectable={selectable}
            />)}
        </ui.Tbody>
    </ui.Table>;
};


const NUM_DETAIL_COLS = 12;

export const ItemDetailHeader = ({color, selectable, hideSizeCol}: {
    color?: string;
    hideSizeCol?: boolean;
    selectable: boolean;
}) => {
    return <ui.Tr>
        {selectable && <ui.Th px={0} py={1} />}
        {!hideSizeCol && <ui.Th px={0} py={1} />}
        <ui.Th px={0} py={1} textAlign="center" fontSize="xs">店</ui.Th>
        <ui.Th px={0} py={1} textAlign="center" fontSize="xs">予</ui.Th>
        <ui.Th px={0} py={1} textAlign="center" fontSize="xs">修</ui.Th>
        <ui.Th px={0} py={1} textAlign="center" fontSize="xs">貸</ui.Th>
        <ui.Th px={0} py={1} textAlign="center" fontSize="xs">倉</ui.Th>
        <ui.Th px={0} py={1} textAlign="center" fontSize="xs">自</ui.Th>
        <ui.Th px={0} py={1} textAlign="center" fontSize="xs">依</ui.Th>
        <ui.Th px={0} py={1} textAlign="center" fontSize="xs">注</ui.Th>
        <ui.Th px={0} py={1} textAlign="center" fontSize="xs">売</ui.Th>
        {color && <ui.Th px={0} py={1} textAlign="right" fontSize="xs">{color}</ui.Th>}
    </ui.Tr>
};


export const ItemDetail = React.memo(({item, selectable, hideSizeCol = false, details = true}: {
    item: Item;
    selectable: boolean;
    hideSizeCol?: boolean;
    details?: boolean;
}) => {
    const [selected, _t] = useSelected(item.barcode);
    const toggle = React.useCallback(() => _t(), [_t]);
    const decrementable = selected > 0;
    const decrement = React.useCallback(() => decrementable && _t((n) => n - 1), [decrementable, _t]);
    const increment = React.useCallback(() => _t((n) => n + 1), [_t]);
    const [showDetail, setShowDetail] = React.useState(false);
    return <>
        <ui.Tr>
            {selectable && <ui.Td p={1} pl={0}>
                <ui.Checkbox
                    isChecked={selected > 0}
                    onChange={toggle}
                />
            </ui.Td>}
            {!hideSizeCol && <ui.Td p={1} px={4} fontSize="xs">{item.size}</ui.Td>}
            <ui.Td p={1} fontSize="xs" fontWeight="bold">{item.stock_amount}</ui.Td>
            <ui.Td p={1} fontSize="xs">{item.keep_amount}</ui.Td>
            <ui.Td p={1} fontSize="xs">{item.repair_amount}</ui.Td>
            <ui.Td p={1} fontSize="xs">{item.lease_amount}</ui.Td>
            <ui.Td p={1} fontSize="xs">{item.warehouse_stock_amount}</ui.Td>
            <ui.Td p={1} fontSize="xs">{item.free_stock_amount}</ui.Td>
            <ui.Td p={1} fontSize="xs">{item.warehouse_requested_amount + item.free_stock_requested_amount}</ui.Td>
            <ui.Td p={1} fontSize="xs">{item.order_amount}</ui.Td>
            <ui.Td p={1} fontSize="xs">{item.sold_amount}</ui.Td>
            {details && <ui.Td p={1} pr={0} width="100%" textAlign="right">
                {item.store_item_id && <ui.IconButton
                    aria-label=""
                    size="xs"
                    onClick={() => setShowDetail(v => !v)}
                    icon={showDetail ? <icons.ArrowDownIcon /> : <icons.ArrowUpIcon />}
                />}
            </ui.Td>}
        </ui.Tr>

        {selectable && selected > 0 && <ui.Tr border="none">
            <ui.Td colSpan={NUM_DETAIL_COLS} p={0}>
                <ui.HStack spacing={2} py={1}>
                    <ui.Text
                        fontSize="xl"
                        fontWeight="bold"
                        px={2}
                    >
                        {selected}
                    </ui.Text>
                    <ui.Button
                        isDisabled={!decrementable}
                        onClick={decrement}
                        size="sm"
                    >
                        <icons.ArrowDownIcon />
                    </ui.Button>
                    <ui.Button
                        onClick={increment}
                        size="sm"
                    >
                        <icons.ArrowUpIcon />
                    </ui.Button>
                </ui.HStack>
            </ui.Td>
        </ui.Tr>}

        {showDetail && item.store_item_id && <ui.Tr>
            <ui.Td colSpan={NUM_DETAIL_COLS} p={0} borderBottom="none">
                <StoreItemLogs storeItemId={item.store_item_id} />
            </ui.Td>
        </ui.Tr>}
    </>
});


const StoreItemLogs = ({storeItemId}: {
    storeItemId: number;
}) => {
    const {data} = useRemoteData({
        path: 'staff/get_store_item_logs',
        request: {store_item_id: storeItemId},
    });

    if (!data) {
        return <ui.Box p={1} textAlign="center">
            <ui.Spinner />
        </ui.Box>;
    }

    return <ui.Table size="sm">
        <ui.Tbody>
            {data?.map((log) => {
                const hasNote = !!log.note;
                const tdProps = {
                    px: 1,
                    ...(hasNote ? {borderBottom: 'none'} : {}),
                };
                return <React.Fragment key={log.id}>
                    <ui.Tr>
                        <ui.Th {...tdProps}>
                            {moment(log.created_at).format('MM/DD')}
                        </ui.Th>
                        <ui.Th {...tdProps} whiteSpace="pre-line">
                            {actionLogTypeText(log)}
                        </ui.Th>
                        <ui.Td {...tdProps}>
                            {log.staff?.name ?? '-'}
                        </ui.Td>
                        <ui.Td {...tdProps}>
                            {log.customer_name}
                        </ui.Td>
                        <ui.Td isNumeric={true} {...tdProps}>
                            {amountText(log)}
                        </ui.Td>
                    </ui.Tr>
                    {hasNote && <ui.Tr>
                        <ui.Td />
                        <ui.Td colSpan={4} px={1}>
                            <ui.Text fontSize="xs" whiteSpace="pre-line">{log.note}</ui.Text>
                        </ui.Td>
                    </ui.Tr>}
                </React.Fragment>
            })}
        </ui.Tbody>
    </ui.Table>
};


const actionLogTypeText = (log: StoreItemLog): string => {
    return {
        regular_order: '注文取込',
        arrived: '入庫',
        sold: '売り消し',
        returned: '返品',
        transfer_to: `店舗移動\nto: ${log.transfer_store?.name}`,
        transfer_from: `店舗移動\nfrom: ${log.transfer_store?.name}`,
        shipment_request: '出荷依頼',
        shipment_order: '出荷指示',
        send_to_warehouse: '倉庫返送',
        import_stock: '店頭在庫取り込み',
        import_warehouse_stock: '取置残集計',
        lease: 'リース',
        release: 'リース返却',
        repair: '修理',
        fixed: '修理完了',
        broken: '修理不可',
        keep: '予約',
        free: '予約解消',
    }[log.type];
};

const amountText = (log: StoreItemLog): string => {
    if ([
        'sold',
        'transfer_to',
        'send_to_warehouse',
        'lease',
        'repair',
    ].includes(log.type)) {
        return `-${log.amount}`;
    }

    if ([
        'arrived',
        'returned',
        'release',
        'fixed',
    ].includes(log.type)) {
        return `+${log.amount}`;
    }

    if (log.type === 'import_warehouse_stock' && log.amount > 0) {
        return `+${log.amount}`;
    }

    return `${log.amount}`;
};


const ImageEditDialog = ({model, onUpdated, ...props}: {
    model: Model;
    onUpdated(): void;
} & Pick<ui.ModalProps, 'isOpen' | 'onClose'>) => {
    const [imageIds, setImageIds] = React.useState(() => model.images.map(i => i.id));
    const mapping = React.useMemo(() => model.images.reduce((acc, i) => ({...acc, [i.id]: i}), {} as Keyed<Image>), [model]);
    React.useEffect(() => setImageIds(model.images.map(i => i.id)), [model]);

    const canUp = (index: number): boolean => index > 0;
    const canDown = (index: number): boolean => index < imageIds.length - 1;
    const up = (index: number) => setImageIds((prev) => {
        const ids = [...prev];
        ids.splice(index - 1, 0, ids.splice(index, 1)[0]);
        return ids;
    });
    const down = (index: number) => setImageIds((prev) => {
        const ids = [...prev];
        ids.splice(index + 1, 0, ids.splice(index, 1)[0]);
        return ids;
    });

    const orderApi = useWriterApi('staff/model/order_images');
    const removeApi = useWriterApi('staff/model/remove_image');
    const [confirmToDelete, setConfirmToDelete] = React.useState<Image>();

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader>{model.name}</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <LayoutItem>
                    <ui.Text fontWeight="bold">Edit Images</ui.Text>
                </LayoutItem>

                {imageIds.filter((id) => id in mapping).map((id, i) => {
                    const image = mapping[id];
                    return <LayoutItem key={id}>
                        <ui.HStack>
                            <ui.VStack>
                                <ui.Button
                                    isDisabled={!canUp(i)}
                                    onClick={() => up(i)}
                                >
                                    <icons.ArrowUpIcon />
                                </ui.Button>
                                <ui.Button
                                    isDisabled={!canDown(i)}
                                    onClick={() => down(i)}
                                >
                                    <icons.ArrowDownIcon />
                                </ui.Button>
                            </ui.VStack>

                            <S3Image
                                base={image.base_url}
                                processor="sd128"
                                width="100px"
                                height="100px"
                                objectFit="contain"
                            />

                            <ui.Spacer />

                            <ui.Button
                                onClick={() => setConfirmToDelete(image)}
                                colorScheme="red"
                                size="sm"
                            >
                                Remove
                            </ui.Button>
                        </ui.HStack>
                    </LayoutItem>
                })}
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button
                    colorScheme="blue"
                    onClick={() => orderApi.call({
                        model_id: model.id,
                        image_ids: imageIds,
                    }).then(onUpdated)}
                    isDisabled={imageIds.length < 2}
                >
                    Save Order
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ConfirmationDialog
            title="Are you sure you want to delete this image?"
            confirm="Delete"
            isOpen={!!confirmToDelete}
            onClose={() => setConfirmToDelete(undefined)}
            onConfirm={() => confirmToDelete && removeApi.call({
                model_id: model.id,
                image_id: confirmToDelete.id,
            }).then(onUpdated)}
            danger={true}
        >
            {confirmToDelete && <S3Image
                base={confirmToDelete.base_url}
                processor="sd256"
                height="256px"
                width="256px"
                objectFit="contain"
                mx="auto"
            />}
        </ConfirmationDialog>
        <ApiErrorDialog api={removeApi} onOk={removeApi.reset} />
        <ApiSpinnerDialog api={removeApi} />
        <ApiCompletionDialog
            api={removeApi}
            onOk={() => {
                setConfirmToDelete(undefined);
                removeApi.reset();
            }}
        />

        <ApiErrorDialog api={orderApi} onOk={orderApi.reset} />
        <ApiSpinnerDialog api={orderApi} />
        <ApiCompletionDialog
            api={orderApi}
            onOk={() => {
                orderApi.reset();
                props.onClose();
            }}
        />
    </ui.Modal>
};


const ImageAddDialog = ({model, onAdded, ...props}: {
    model: Model;
    onAdded(): void;
} & Pick<ui.ModalProps, 'isOpen' | 'onClose'>) => {
    const [image, setImage] = React.useState<string>();
    const api = useWriterApi('staff/model/add_image');

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader>{model.name}</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <LayoutItem>
                    <ui.Text fontWeight="bold">Add New Image</ui.Text>
                </LayoutItem>

                <LayoutItem>
                    <ui.HStack>
                        <ImageInput
                            width="200px"
                            height="200px"
                            value={image}
                            onChange={(_, v) => setImage(v)}
                            objectFit="contain"
                        />
                    </ui.HStack>
                </LayoutItem>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button
                    colorScheme="blue"
                    onClick={() => image && api.call({
                        model_id: model.id,
                        image: image,
                    }).then(onAdded)}
                    isDisabled={!image}
                >
                    Upload
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiErrorDialog api={api} onOk={api.reset} />
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={() => {
                setImage(undefined);
                api.reset();
                props.onClose();
            }}
        />
    </ui.Modal>
};


const OtherStoreInfo = ({model}: {
    model: Model;
}) => {
    const {id, brand} = useStore();
    const stores = useStores().filter(s => s.id !== id && s.brand.value === brand.value);

    return <>
        {stores.map((s) => <OtherStoreInfoImpl
            key={s.id}
            store={s}
            model={model}
        />)}
    </>
};

const OtherStoreInfoImpl = ({store, model: _model}: {
    store: Store;
    model: Model;
}) => {
    const {data} = useRemoteData({
        path: modelPath,
        request: {
            store_id: store.id,
            codes: [_model.code],
        },
    });

    const model = data?.items[0];
    const [open, setOpen] = React.useState(false);

    return <LayoutItem>
        <ui.SimpleGrid spacing={4}>
            <ui.HStack>
                <ui.Heading size="sm">{store.name}</ui.Heading>
                <ui.Spacer />
                {model && <>
                    <ui.Text fontSize="sm">
                        {model.products.length} color{model.products.length > 1 ? 's' : ''}
                    </ui.Text>
                    <ui.IconButton
                        aria-label=""
                        size="sm"
                        onClick={() => setOpen(v => !v)}
                        icon={open ? <icons.ArrowDownIcon /> : <icons.ArrowUpIcon />}
                    />
                </>}
                {data && !model && <ui.Text color="gray.500" size="md">
                    取り扱いなし
                </ui.Text>}
            </ui.HStack>
            {open && model?.products.map((prod) => <ui.Box key={prod.id}>
                <ProductDetailTable 
                    product={prod}
                    selectable={false}
                />
                {model.price !== prod.sale_price && <ui.Text textAlign="right" fontWeight="bold" color="red" mt={1}>
                    {formatPrice(prod.sale_price)}
                </ui.Text>}
            </ui.Box>)}
        </ui.SimpleGrid>
    </LayoutItem>
};
