import {React, Router, ui, icons} from 'lib';

import {useStores, useUser, S3Image, useSelectedItems, useResetSelectedItems, useSelected, useRemoteData, Req, Res, useForm, useWriterApi, FormItem, LayoutItem, RadioInput, Input, BoolCheckboxInput, Select, Choice, Textarea, ApiSpinnerDialog, ApiCompletionDialog, ApiErrorDialog, useChildUrl} from 'shared';
import {ActionHistory} from './history';

import {StaffFrame, useStore} from '../base';
import {ItemDetailHeader, ItemDetail} from 'staff/model';

const getItems = 'staff/get_items';
const makeAction = 'staff/action/make';
const getTypes = 'staff/action/types';
const getStaffs = 'staff/get_staffs';
type ActionType = ArrayItem<Res<typeof getTypes>>['action'];
type NestedItem = ArrayItem<Res<typeof getItems>>;

const actions: Choice<ActionType>[] = [
    {
        label: '売り消し',
        value: 'sold',
    },
    {
        label: '予約',
        value: 'keep',
    },
    {
        label: '入荷連絡',
        value: 'waiting',
    },
    {
        label: '出荷依頼',
        value: 'shipment_request',
    },
    {
        label: '入庫',
        value: 'arrived',
    },
    {
        label: '店間移動',
        value: 'transfer',
    },
    {
        label: '倉庫に送る',
        value: 'send_to_warehouse',
    },
    {
        label: 'リース',
        value: 'lease',
    },
    {
        label: '修理',
        value: 'repair',
    },
];


export function ActionIndex() {
    const {url} = Router.useRouteMatch();

    return <Router.Switch>
        <Router.Route path={`${url}/history`}>
            <ActionHistory />
        </Router.Route>
        <Router.Route path={url}>
            <Action />
        </Router.Route>
    </Router.Switch>;
}


const itemsToBarcodeDict = (items: NestedItem[]): Keyed<true> => items.reduce((acc, i) => {
    acc[i.barcode] = true;
    return acc;
}, {} as Keyed<true>);


export function Action({title = '操作', onComplete, checkMissing = false}: {
    title?: string;
    onComplete?(): boolean;
    checkMissing?: boolean;
}): React.ReactElement {
    const history = Router.useHistory();

    const user = useUser();
    const store = useStore();
    const stores = useStores();

    const {data: types} = useRemoteData({
        path: getTypes,
        request: {},
    });
    const {data: staffs} = useRemoteData({
        path: getStaffs,
        request: {store_id: store.id},
    });
    const selectedItems = useSelectedItems(true);
    const resetSelectedItems = useResetSelectedItems();
    const [selected, setSelected] = React.useState({} as Keyed<true>);

    const api = useWriterApi(makeAction);
    const {binder, handleSubmit, reset} = useForm<Req<typeof makeAction>, 'amounts'>(() => ({
        store_id: store.id,
        type: actions[0].value,
        staff_id: user.id,
        note: '',
        customer_name: '',
        transfer_store_id: null,
        amounts: undefined,
    }));

    const actionType = binder.value.type;
    const optionData = React.useMemo(() => {
        return types?.find(a => a.action === actionType)?.data ?? [];
    }, [types, actionType]);

    const {data, reload: reloadData} = useRemoteData({
        path: getItems,
        request: {
            store_id: store.id,
            barcodes: selectedItems.map(([k, _]) => k),
        },
        onSuccess: (data) => setSelected(itemsToBarcodeDict(data)),
    });

    const items = React.useMemo(() => data ?? [], [data]);

    const submit = handleSubmit(data => api.call({
        ...data,
        amounts: selectedItems.reduce((acc, [barcode, amount]) => {
            if (selected[barcode]) {
                acc[barcode] = amount;
            }
            return acc;
        }, {} as Keyed<number>),
    }).then(reloadData));

    const missing = React.useMemo(() => {
        if (!checkMissing) {
            return [];
        }
        const exists = itemsToBarcodeDict(items);
        return selectedItems.filter(([k, _]) => !exists[k]);
    }, [checkMissing, selectedItems, items]);

    const backLink = useChildUrl('');

    return <StaffFrame
        title={title}
        hideFloatingContent={true}
    >
        {data && items.length === 0 && <ui.Box my={10}>
            <LayoutItem>
                <ui.Text textAlign="center" color="gray.500" size="lg">
                    No item selected
                </ui.Text>
            </LayoutItem>
        </ui.Box>}

        {items.length > 0  && <ui.Box my={10}>
            {missing.length > 0 && <LayoutItem>
                <ui.Box p={4} bg="gray.50" borderRadius="8px" width="100%">
                    <ui.Text color="red" fontWeight="bold">
                        システムに登録されていない商品が{missing.length}件含まれています
                    </ui.Text>

                    {missing.map(([barcode, amount]) => <ui.Text key={barcode} fontWeight="bold">
                        {barcode}: {amount}
                    </ui.Text>)}
                </ui.Box>
            </LayoutItem>}

            <FormItem label="Action">
                <Select {...binder.mapInputProps('type')} choices={actions} />
            </FormItem>

            <FormItem label="Staff">
                <Select
                    {...binder.mapInputProps('staff_id')}
                    choices={staffs?.map((s) => ({
                        value: s.id,
                        label: s.name,
                    })) ?? []}
                />
            </FormItem>

            {optionData.includes('customer_name') && <FormItem
                label="Customer Name"
                keyPath="customer_name"
                error={api.error?.data}
            >
                <Input {...binder.mapInputProps('customer_name')} />
            </FormItem>}

            {optionData.includes('transfer_store') && <FormItem
                label="Store to transfer"
                keyPath="transfer_store"
                error={api.error?.data}
            >
                <RadioInput
                    {...binder.mapInputProps('transfer_store_id')}
                    choices={stores.filter(s => s.brand.value === store.brand.value && s.code !== store.code).map((s) => ({
                        value: s.id,
                        label: s.name,
                    }))}
                />
            </FormItem>}

            {optionData.includes('wait_for_cancel') && <FormItem
                label="Wait For Cancel"
                keyPath="wait_for_cancel"
                error={api.error?.data}
            >
                <BoolCheckboxInput {...binder.mapInputProps('wait_for_cancel')} label="キャンセル待ち" />
            </FormItem>}

            <FormItem
                label="note"
                keyPath="note"
                error={api.error?.data}
            >
                <Textarea {...binder.mapInputProps('note')} />
            </FormItem>

            <LayoutItem>
                <ItemsTable
                    items={items}
                    selected={selected}
                    setSelected={setSelected}
                />
            </LayoutItem>

            <LayoutItem>
                <ui.HStack>
                    <ui.Spacer />
                    <ui.Button colorScheme="blue" onClick={submit}>
                        Submit
                    </ui.Button>
                </ui.HStack>
            </LayoutItem>

            <ApiErrorDialog api={api} onOk={api.reset} />
            <ApiSpinnerDialog api={api} />
            <ApiCompletionDialog
                api={api}
                onOk={(action) => {
                    api.reset();
                    reset();
                    if (onComplete?.()) {
                        return
                    }

                    if (action.type === 'sold' && items.some((i) => i.warehouse_stock_amount> 0)) {
                        binder.assign({type: 'shipment_request'});
                    } else {
                        const barcodes = Object.entries(selected).map(([k, _]) => k);
                        resetSelectedItems(barcodes);
                        history.replace(backLink);
                    }
                }}
            />
        </ui.Box>}
    </StaffFrame>;
};


const ItemsTable = React.memo(({items, selected, setSelected}: {
    items: NestedItem[];
    selected: Keyed<true>;
    setSelected: React.Dispatch<React.SetStateAction<Keyed<true>>>;
}) => {
    const [limit, setLimit] = React.useState(50);

    const onToggle = React.useCallback((item: NestedItem) => {
        setSelected((old) => {
            const clone = {...old};
            if (clone[item.barcode]) {
                delete clone[item.barcode];
            } else {
                clone[item.barcode] = true;
            }
            return clone;
        });
    }, [setSelected]);

    return <>
        <LayoutItem>
            <ui.Table size="sm">
                <ui.Thead>
                    <ui.Tr>
                        <ui.Th px={1}></ui.Th>
                        <ui.Th px={1}>Image</ui.Th>
                        <ui.Th px={1}>Item</ui.Th>
                        <ui.Th px={1}>Amount</ui.Th>
                    </ui.Tr>
                </ui.Thead>

                <ui.Tbody>
                    {items.slice(0, limit).map((item) => <ItemRow
                        key={item.barcode}
                        item={item}
                        selected={selected[item.barcode] ?? false}
                        onToggle={onToggle}
                    />)}
                </ui.Tbody>
            </ui.Table>
        </LayoutItem>

        {limit < items.length && <LayoutItem>
            <ui.HStack>
                <ui.Text>
                    他{items.length - limit}件
                </ui.Text>
                <ui.Button onClick={() => setLimit(l => l + 50)}>
                    Read More
                </ui.Button>
            </ui.HStack>
        </LayoutItem>}
    </>;
});


const ItemRow = React.memo(({item, selected, onToggle}: {
    item: NestedItem;
    selected: boolean;
    onToggle(item: NestedItem): void;
}) => {
    const opacity = selected ? 1 : 0.5;
    return <ui.Tr key={item.barcode}>
        <ui.Td px={0}>
            <ui.Checkbox
                isChecked={selected}
                onChange={() => onToggle(item)}
            />
        </ui.Td>
        <ui.Td opacity={opacity} px={1}>
            {item.product.model.images[0] && <S3Image
                base={item.product.model.images[0].base_url}
                processor="sd256"
                maxWidth="50px"
            />}
        </ui.Td>
        <ui.Td opacity={opacity} px={1}>
            <ui.Text>
                {item.product.model.code}
            </ui.Text>
            <ui.Text>
                {item.product.color}
            </ui.Text>
            <ui.Text>
                {item.size}
            </ui.Text>

            <ui.Table size="sm" width="auto" mt={2}>
                <ui.Thead>
                    <ItemDetailHeader
                        selectable={false}
                        hideSizeCol={true}
                    />
                </ui.Thead>
                <ui.Tbody>
                    <ItemDetail
                        item={item}
                        selectable={false}
                        hideSizeCol={true}
                        details={false}
                    />
                </ui.Tbody>
            </ui.Table>
        </ui.Td>
        <ui.Td opacity={opacity} px={1}>
            <Amount item={item} disabled={!selected} />
        </ui.Td>
    </ui.Tr>;
});


const Amount = ({item, disabled}: {
    item: NestedItem;
    disabled: boolean;
}) => {
    const [selected, _t] = useSelected(item.barcode);
    const decrementable = selected > 0;
    const decrement = React.useCallback(() => decrementable && _t((n) => n - 1, true), [decrementable, _t]);
    const increment = React.useCallback(() => _t((n) => n + 1), [_t]);
    return <ui.HStack spacing={1}>
        <ui.Text fontWeight="bold">
            {selected}
        </ui.Text>
        <ui.Button
            isDisabled={disabled || !decrementable}
            onClick={decrement}
            size="xs"
        >
            <icons.ArrowDownIcon />
        </ui.Button>
        <ui.Button
            isDisabled={disabled}
            onClick={increment}
            size="xs"
        >
            <icons.ArrowUpIcon />
        </ui.Button>
    </ui.HStack>
};
