import {React, ui} from 'lib'

import {Brand, Store} from 'types/elcstockbook/api/data.gen';

import {useSession} from './api';


export const StoreSelection = ({onSelect}: {
    onSelect(store: Store): void;
}): React.ReactElement => {
    const stores = useStores();

    return <ui.VStack spacing={2} alignItems="flex-start" maxWidth="400px" mx="auto">
        {stores.reduce((acc, s, i) => {
            if (s.brand.value !== acc.brand) {
                acc.brand = s.brand.value;
                acc.nodes.push(<ui.Text size="xs" key={s.brand.value} pt={i === 0 ? 0 : 5}>
                    {s.brand.value}
                </ui.Text>)
            }
            acc.nodes.push(<ui.Button
                width="100%"
                key={s.code}
                onClick={() => onSelect(s)}
            >
                {s.name}
            </ui.Button>);
            return acc;
        }, {
            brand: null as null | Brand['value'],
            nodes: [] as React.ReactNode[],
        }).nodes}
    </ui.VStack>;
};



export const useStores = (): Store[] => {
    return useSession().data.stores;
};


export const useStoreSelectionDialog = (props: {
    onSelect(store: Store): void;
}): {
    dialog: React.ReactElement;
} & ui.UseDisclosureReturn => {
    const disclosure = ui.useDisclosure();
    const onSelectRef = React.useRef(props?.onSelect);
    onSelectRef.current = props?.onSelect;
    return React.useMemo(() => ({
        ...disclosure,
        dialog: <StoreSelectionDialog onSelect={onSelectRef.current} {...disclosure} />,
    }), [disclosure]);
};


const StoreSelectionDialog = ({isOpen, onClose, onSelect}: {
    isOpen: boolean;
    onClose(): void;
    onSelect(store: Store): void;
}) => {
    return <ui.Modal
        motionPreset="slideInBottom"
        onClose={onClose}
        isOpen={isOpen}
    >
        <ui.ModalOverlay />

        <ui.ModalContent>
            <ui.ModalHeader>Store</ui.ModalHeader>
            <ui.ModalCloseButton />
            <ui.ModalBody>
                <StoreSelection
                    onSelect={(store) => {
                        onClose();
                        onSelect(store);
                    }}
                />
            </ui.ModalBody>
            <ui.ModalFooter>
                <ui.Button onClick={onClose}>
                    Cancel
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>
    </ui.Modal>;
};
