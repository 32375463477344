import {React} from 'lib';


export const isArray = (obj: unknown): obj is unknown[] => Object.prototype.toString.call(obj) === '[object Array]';
export const isObject = (obj: unknown): obj is {[k: string]: unknown} => Object.prototype.toString.call(obj) === '[object Object]';
export const isFunction = (obj: unknown): obj is Function => typeof obj === 'function' || Object.prototype.toString.call(obj) === '[object Function]';

export function unique<V extends string | number>(arr: V[]): V[] {
    const ret: V[] = [];
    const occurred: Keyed<number> = {};
    for (let i = 0, l = arr.length; i < l; i++) {
        const val = arr[i];
        if (!(val in occurred)) {
            ret.push(val);
            occurred[val] = 1;
        }
    }
    return ret;
}


export const equals = (a: unknown, b: unknown): boolean => {
    if (isArray(a)) {
        return isArray(b) && a.length === b.length && a.every((v, i) => (equals(v, b[i])));
    } else if (isObject(a)) {
        if (!isObject(b)) { return false; }
        const keysA = Object.keys(a);
        const keysB = Object.keys(b);
        return keysA.length === keysB.length && keysA.every((k) => (equals(a[k], b[k])));
    } else {
        return a === b;
    }
};


export const useChangeCount = <T extends any>(obj: T, compare?: {(a: T, b: T): boolean}): number => {
    const previous = React.useRef(obj);
    const counter = React.useRef(0);

    const prev = previous.current;
    if (obj !== prev && !(compare ?? equals)(obj, prev)) {
        counter.current += 1;
        previous.current = obj;
    }

    return counter.current;
};


export function addTax(price: number): number {
    return Math.floor(price * 1.1);
}


export function formatComma(num: number | null | undefined): string {
    if (num == null) { return ''; }
    if (num === 0) { return '0'; }
    var parts = num.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
}

export const formatPrice = (num: number): string => `¥${formatComma(num)}`;


export function buildQuery(data: {[key: string]: unknown}, keepOrder?: boolean): string {
    const sort = <T,>(arr: T[]): T[] => keepOrder ? arr : arr.sort();

    const query = (key: string, value: unknown): string => isArray(value)
        ? sort(value).map((v: unknown) => query(key, v)).join('&')
        : `${key}=${encodeURIComponent(value as string | number | boolean)}`;

    return sort(Object.keys(data))
        .filter((k) => (data[k] != null))
        .map((k) => query(k, data[k]))
        .filter((v) => v)
        .join('&');
}


export function makeComparator<T>(
    _fields: ((string & keyof T) | `-${string & keyof T}` | {(obj: T): string | number | boolean})[]) {
    const dir: number[] = [];
    const fields = _fields.map(function(o, i) {
        if (o instanceof Function) {
            dir[i] = 1;
            return o;
        } else if (o[0] === "-") {
            dir[i] = -1;
            return (obj: T) => obj[o.substring(1) as string & keyof T];
        } else {
            dir[i] = 1;
            return (obj: T) => obj[o as string & keyof T];
        }
    });
    let i = 0;
    const len = fields.length

    return function (a: T, b: T) {
        for (i = 0; i < len; i++) {
            const o = fields[i];
            if (o(a) > o(b)) return dir[i];
            if (o(a) < o(b)) return -(dir[i]);
        }
        return 0;
    };
}


export function replaceQuery(url: string, queryValues: {[key: string]: unknown} | string, keepOrder?: boolean): string {
    const params = Object.assign(
        parseQuery(url, true),
        typeof queryValues === 'string' ? parseQuery(queryValues, true) : queryValues);
    const query = buildQuery(params, keepOrder);
    const base = url.indexOf('?') !== -1 ? url.split('?')[0] : url;
    return base + (query ? '?' + query : '');
}

export function parseQuery(urlOrQuery: string): {[key: string]: string};
export function parseQuery(urlOrQuery: string, allowDuplication: true): {[key: string]: string | string[]};
export function parseQuery(urlOrQuery: string, allowDuplication: boolean, alwaysArray: true): {[key: string]: string[]};
export function parseQuery(urlOrQuery: string, allowDuplication?: boolean, alwaysArray?: boolean): {[key: string]: string | string[]};
export function parseQuery(urlOrQuery: string, allowDuplication?: boolean, alwaysArray?: boolean): {[key: string]: string | string[]} {
    const query = urlOrQuery.indexOf('?') !== -1
        ? urlOrQuery.split('?')[1] : urlOrQuery;
    return query ? query.split('&').reduce((prev, pair) => {
        const [k, v] = pair.split('=');
        const value = v && decodeURIComponent(v).replace(/\+/g, ' ');
        if (k in prev) {
            if (allowDuplication) {
                const p = prev[k];
                if (isArray(p)) {
                    p.push(value);
                } else {
                    prev[k] = [p, value];
                }
            }
        } else {
            prev[k] = alwaysArray ? [value] : value;
        }
        return prev;
    }, {} as {[key: string]: string | string[]}) : {};
}
