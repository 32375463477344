import {React, ui, icons, moment} from 'lib';

import {useRemoteData, S3Image, Req, Res, useWriterApi, useForm, FormItem, Textarea, ApiErrorDialog, ApiSpinnerDialog, ApiCompletionDialog, LayoutItem, InputProps, usePage, Paginator, useSingleQueryValueAsNumber} from 'shared';
import {formatComma} from 'utils';

import {StaffFrame, useStore, StaffSelect} from './base';
import {ActionDetailRow} from './action/preview';


const listSoldActions = 'staff/action/list_solds';
const returnedPath = 'staff/returned';
type SoldAction = ArrayItem<Res<typeof listSoldActions>['items']>;
type SoldActionDetail = ArrayItem<SoldAction['details']>;

const remaining = (detail: SoldActionDetail): number => {
    return detail.returns.reduce((acc, r) => acc - r.amount, detail.amount);
};

const canReturn = (detail: SoldActionDetail): boolean => {
    return 0 < remaining(detail);
};


export function Sold(): React.ReactElement {

    const store = useStore();
    const staffId = useSingleQueryValueAsNumber('staff');

    const {data, reload} = useRemoteData({
        path: listSoldActions,
        request: {
            store_id: store.id,
            staff_id: staffId.value,
            page: usePage(),
        }
    });

    const [editing, setEditing] = React.useState<SoldActionDetail>();
    const disclosure = ui.useDisclosure({
        isOpen: editing !== undefined,
        onClose: () => setEditing(undefined),
    });

    return <StaffFrame
        title="販売履歴"
    >
        <ui.Box my={10}>
            <StaffSelect
                value={staffId.value}
                onChange={(_, v) => staffId.push(v)}
            />

            {data?.items.map((action) => {
                return <LayoutItem key={action.id}>
                    <ui.Box borderTop="1px solid #eee" mt={10} py={4}>
                        <ui.Box>
                            <ui.Text fontSize="xs">
                                {moment(action.created_at).format('YYYY/MM/DD')}
                            </ui.Text>

                            <ui.Flex alignItems="center" mt={2}>
                                <ui.Text fontWeight="bold">
                                    {action.customer_name}様
                                </ui.Text>

                                <ui.Text ml={2} fontSize="small">
                                    by {action.staff.name}
                                </ui.Text>
                            </ui.Flex>
                        </ui.Box>

                        {action.note && <ui.Text mt={2} whiteSpace="pre-line">
                            {action.note}
                        </ui.Text>}

                        <ui.Table width="100%">
                            <ui.Tbody>
                                {action.details.map((d) => {
                                    const hasReturn = d.returns.length > 0;
                                    return <React.Fragment key={d.id}>
                                        <ui.Tr key={d.id}>
                                            <ui.Td pl={0} pr={4} width="70px" rowSpan={hasReturn ? 2 : 1}>
                                                {d.item.product.model.images[0] && <S3Image
                                                    base={d.item.product.model.images[0].base_url}
                                                    processor="sd256"
                                                />}
                                            </ui.Td>
                                            <ui.Td px={0} fontSize="xs" borderBottom={hasReturn ? 'none' : undefined}>
                                                <ui.Text>
                                                    {d.item.product.model.code}
                                                </ui.Text>
                                                {d.item.product.model.code !== d.item.product.domestic_code && <ui.Text>
                                                    {d.item.product.domestic_code}
                                                </ui.Text>}
                                                <ui.Text>
                                                    {d.item.product.color}
                                                </ui.Text>
                                                <ui.Text>
                                                    {d.item.size}
                                                </ui.Text>

                                                <ui.Box mt={2} fontSize="sm" fontWeight="bold">
                                                    {d.remaining_amount === 0 && <ui.Text textDecoration="line-through">
                                                        {formatComma(d.unit_price)} x {d.amount}
                                                    </ui.Text>}
                                                    {d.remaining_amount > 0 && <>
                                                        {d.remaining_amount !== d.amount && <ui.Text>
                                                            {formatComma(d.unit_price)} x <ui.chakra.span textDecoration="line-through">{d.amount}</ui.chakra.span> {d.remaining_amount}
                                                        </ui.Text>}
                                                        {d.remaining_amount === d.amount && <ui.Text>
                                                            {formatComma(d.unit_price)} x {d.amount}
                                                        </ui.Text>}
                                                    </>}
                                                </ui.Box>
                                            </ui.Td>
                                            <ui.Td pl={4} pr={0} textAlign="center" borderBottom={hasReturn ? 'none' : undefined}>
                                                <ui.Button
                                                    onClick={() => setEditing(d)}
                                                    isDisabled={!canReturn(d)}
                                                    colorScheme="red"
                                                    mt={4}
                                                >
                                                    返品
                                                </ui.Button>
                                            </ui.Td>
                                        </ui.Tr>

                                        {d.returns.length > 0 && <ui.Tr>
                                            <ui.Td colSpan={2} px={0} pt={0}>
                                                <ui.Box mt={4}>
                                                    <ui.Text fontWeight="bold">返品履歴</ui.Text>
                                                    {d.returns.map((r) => <ui.HStack key={r.id} mt={1}>
                                                        <ui.Text fontSize="xs">
                                                            {moment(r.created_at).format('YYYY/MM/DD')}
                                                        </ui.Text>
                                                        <ui.Text ml={4} fontSize="xs">
                                                            x{r.amount}
                                                        </ui.Text>
                                                        <ui.Text ml={4} fontSize="xs" whiteSpace="pre-line">
                                                            {r.note}
                                                        </ui.Text>
                                                    </ui.HStack>)}
                                                </ui.Box>
                                            </ui.Td>
                                        </ui.Tr>}
                                    </React.Fragment>;
                                })}
                            </ui.Tbody>
                        </ui.Table>
                    </ui.Box>
                </LayoutItem>;
            })}

            {data && <LayoutItem>
                <Paginator {...data} />
            </LayoutItem>}

            <ActionDialog
                detail={editing}
                onComplete={() => {
                    disclosure.onClose();
                    reload();
                }}
                {...disclosure}
            />
        </ui.Box>
    </StaffFrame>;
};


const ActionDialog = ({detail, onComplete, ...props}: {
    detail: SoldActionDetail | undefined;
    onComplete(): void;
} & ui.UseDisclosureReturn) => {
    const store = useStore();
    const api = useWriterApi(returnedPath);
    const {binder, handleSubmit, reset} = useForm<Req<typeof returnedPath>, 'purchase_id'>(() => ({
        store_id: store.id,
        purchase_id: detail?.purchase_id,
        amount: 1,
        note: '',
    }));

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, detail, reset]);

    const submit = handleSubmit(data => api.call(data));

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">返品</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <FormItem label="Item">
                    {detail && <ui.Table>
                        <ui.Tbody>
                            <ActionDetailRow detail={detail} />
                        </ui.Tbody>
                    </ui.Table>}
                </FormItem>

                <FormItem
                    label="amount"
                    keyPath="amount"
                    error={api.error?.data}
                >
                    {detail && <AmountInput
                        {...binder.mapInputProps('amount')}
                        min={1}
                        max={remaining(detail)}
                    />}
                </FormItem>

                <FormItem
                    label="note"
                    keyPath="note"
                    error={api.error?.data}
                >
                    <Textarea
                        {...binder.mapInputProps('note')}
                    />
                </FormItem>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={submit}
                >
                    確定
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiErrorDialog api={api} onOk={api.reset} />
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={() => {
                onComplete();
                reset();
                api.reset();
            }}
        />
    </ui.Modal>;
};


const AmountInput = ({value: v, onChange, min = 0, max}: {
    min?: number;
    max?: number;
} & InputProps<number, number>): React.ReactElement => {
    const decrementable = min < v;
    const incrementable = max === undefined || v < max;
    return <ui.HStack spacing={1}>
        <ui.Text fontWeight="bold">
            {v}
        </ui.Text>
        <ui.Button
            isDisabled={!decrementable}
            onClick={(e) => onChange(e, v - 1)}
            size="xs"
        >
            <icons.ArrowDownIcon />
        </ui.Button>
        <ui.Button
            isDisabled={!incrementable}
            onClick={(e) => onChange(e, v + 1)}
            size="xs"
        >
            <icons.ArrowUpIcon />
        </ui.Button>
    </ui.HStack>
};
