import {React, xlsx} from 'lib';

import {Action, ActionDetail} from 'types/elcstockbook/api/data.gen';


export type ActionType = Action['type'];


export const actionTypeText: {[K in ActionType]: string} = {
    arrived: '入庫',
    broken: '修理不可',
    fixed: '修理完了',
    free: '取置解消',
    import_stock: '店頭在庫取込',
    import_warehouse_stock: '取置残集計',
    import_free_stock: 'フリー在庫更新',
    keep: '予約',
    lease: 'リース',
    regular_order: '注文取込',
    release: 'リース返却',
    repair: '修理',
    returned: '返品',
    send_to_warehouse: '倉庫返送',
    shipment_order: '出荷指示',
    shipment_request: '出荷依頼',
    sold: '売り消し',
    transfer: '店舗間移動',
    waiting: '入荷連絡',
    notified: '入荷連絡完了',
};

export const actionTypeChoices = Object.entries(actionTypeText).map(([value, label]) => ({
    value: value as ActionType,
    label: label,
}));


export const useDownloadAction = (): (data: {
    action: Action;
    details: ActionDetail[];
}) => void => {
    return React.useCallback(async ({action: {id, brand, store, type}, details}) => {
        const rows: Keyed<unknown>[] = details.map((d) => ({
            '納品先コード': d.warehouse?.code ?? '',
            '商品コード': d.item.product.domestic_code,
            '色名': d.item.product.color,
            'サイズ名': d.item.size,
            'バーコード': d.item.barcode,
            '数量': `${d.amount}`,
            '上代': `${d.item.product.model.price}`,
            '上代合計': `${d.item.product.model.price * d.amount}`,
        }));

        const sheetName = actionTypeText[type];
        const wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(
            wb, xlsx.utils.json_to_sheet(rows), sheetName);

        xlsx.writeFileXLSX(wb, `${store?.code ?? brand}-${sheetName}-${id}.xlsx`, {
            bookSST: true,
            compression: true,
        });
    }, []);
};
