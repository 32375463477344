import {React, Router} from 'lib';
import {isFunction} from 'utils';


const BaseUrlContext = React.createContext('/');


export const BaseUrlProvider = ({children}: {
    children: React.ReactNode | {(url: string): React.ReactNode};
}): React.ReactElement => {
    const {url} = Router.useRouteMatch();
    return <BaseUrlContext.Provider
        value={url}
        children={isFunction(children) ? children(url) : children}
    />;
};


export const useBaseUrl = (): string => React.useContext(BaseUrlContext);

export const useChildUrl = (path: string, keepQuery?: boolean): string => {
    const url = useMakeChildUrl()(path);
    const location = Router.useLocation();
    const search = location.search.indexOf('?') === 0 ? location.search.slice(1) : location.search;
    return url + (keepQuery && search ? `?${search}` : '') ;
};

export const useMakeChildUrl = (): {(path: string): string} => {
    const base = useBaseUrl();
    return React.useCallback((path: string) => {
        if (!path) {
            return base;
        }
        return base + (base.endsWith('/') || path.startsWith('/') ? path : `/${path}`);
    }, [base]);
};
